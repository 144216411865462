import Heading from '../components/Banners/Heading';
import BannerBackground2 from 'assets/images/background2.png';
import { useTrustConfig } from '../services/config';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Tooltip, Typography, useTheme } from '@mui/material';
import { useMe } from '../services/users';
import CheckIcon from '@mui/icons-material/Check';
import Stack from '@mui/material/Stack';
import React from 'react';
import { useActivity } from '../services/activity';
import Activity from '../components/Activity';
import _ from 'lodash';
import MuiLink from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import TrustLevel from '../components/elements/TrustLevel';
import flattenPages from '../util/flattenPages';
import HeadTags from '../components/HeadTags';

const TrustLevels = () => {
  const theme = useTheme();
  const trustConfig = useTrustConfig().data?.data;
  const trustLevels = trustConfig?.trustLevels;
  const trustActivityTypes = trustConfig ? Object.keys(trustConfig.activityTrustEarns) : [];
  const me = useMe().data?.data;
  const activityTypes = _.without(
    trustActivityTypes,
    'evidence-rank-log',
    'proposed-theory-rank-log',
  );
  const trustActivityQuery = useActivity({
    idOrUsername: me?.id,
    types: activityTypes,
    enabled: !!me?.id && !!trustActivityTypes,
  });
  const trustActivities = flattenPages(trustActivityQuery.data?.pages);

  const nextLevel = me && trustLevels && trustLevels['' + (me.trustLevel + 1)]?.trustNeeded;

  const abilities = {
    answer: {
      title: 'Answer Debate Questions',
      description: 'You can answer the debate questions (no / unlikely / maybe / likely / yes)',
    },
    chooseUsername: {
      title: 'Choose a @username',
      description: 'You can choose a username (@username) which serves as a link to your profile',
    },
    postEvidence3: {
      title: 'Rebuttal Rebuttals',
      description: 'You can post rebuttals nested three levels deep (rebuttals to rebuttals)',
    },
    proposedTheoryComment: {
      title: 'Proposal Comments',
      description: 'You can post comments on proposals which notify the author',
    },
    changeLikelihood: {
      title: 'Change your answer',
      description:
        'You can change your answer to the debate question (no / unlikely / maybe / likely / yes)',
    },
    promote: {
      title: 'Rank content',
      description: 'You can promote or demote evidence and proposals',
    },
    initiateConversation: {
      title: 'Send messages',
      description: 'You can send messages to other users',
    },
    flag: {
      title: 'Flag Evidence',
      description:
        "Get an admin's immediate attention for disallowed content (not yet implemented)",
    },
    postEvidence2: {
      title: 'Rebuttals',
      description: 'You can post rebuttals anywhere',
    },
    proposeTheory: {
      title: 'Create Proposals',
      description: 'You can propose new debate questions',
    },
    subscribeToUser: {
      title: 'Subscribe to users',
      description: 'You can subscribe to users to receive updates when they post content',
    },
    uploadImage: {
      title: 'Upload images',
      description: 'You can upload images for your profile, evidence, and proposals',
    },
    postEvidence1: {
      title: 'Evidence',
      description: 'You can post evidence in the debate',
    },
    invite: {
      title: 'Invite',
      description:
        'You can invite new members by their email, granting them bonus Trust when they sign up',
    },
    evidenceComment: {
      title: 'Evidence Comments',
      description: 'You can post comments on evidence which notify the author',
    },
    copyPaste: {
      title: 'Copy/paste Evidence/Rebuttals',
      description: 'You can copy & paste evidence/rebuttals to quickly place it where needed',
    },
    showArchived: {
      title: 'Show Archived',
      description: 'You can view all archived Evidence and Rebuttals',
    },
    doublePromote: {
      title: 'Rank twice',
      description:
        'You have the option to promote/demote things a second time, giving you more influence on their rank',
    },
    changeUsername: {
      title: 'Change username',
      description: 'You can change your @username (not yet implemented)',
    },
    triplePromote: {
      title: 'Rank thrice',
      description:
        'You have the option to promote/demote things a third time, giving you more influence on their rank',
    },
    quadPromote: {
      title: 'Rank four times',
      description:
        'You have the option to promote/demote things a fourth time, giving you more influence on their rank',
    },
    quintPromote: {
      title: 'Rank five times',
      description:
        'You have the option to promote/demote things a fifth time, giving you more influence on their rank',
    },
  };

  const trustTableData =
    trustLevels &&
    Object.keys(trustLevels).map(level => ({
      title: 'Level ' + level,
      trustNeeded: trustLevels[level].trustNeeded,
      achieved: me && me.trustLevel >= level,
      abilities: Object.keys(trustLevels[level])
        .filter(ability => ability !== 'trustNeeded')
        .filter(ability => abilities[ability])
        .map(ability => abilities[ability]),
    }));

  return (
    <Container>
      <HeadTags title='Trust Levels' />

      <Heading text='Trust Levels' backgroundImg={BannerBackground2} />

      <Typography style={theme.typography.h1}>
        You are at Trust Level {me?.trustLevel} with {me?.trustEarned} Trust.{' '}
      </Typography>
      {nextLevel && (
        <Typography style={theme.typography.h1}>
          You will reach the next level after earning {nextLevel - (me?.trustEarned || 0)} more
          Trust.
        </Typography>
      )}
      <TrustLevel user={me} />
      <MuiLink to='/help?topic=trust-levels' variant='h4' component={RouterLink}>
        For more information, see this help page
      </MuiLink>

      <br />
      <br />

      {trustTableData && (
        <Grid container rowSpacing={2} m={3}>
          {trustTableData.map((level, i) => (
            <React.Fragment key={i}>
              <Grid item xs={6} md={4} lg={3}>
                <Stack direction='row' alignItems='center' spacing={2}>
                  <Tooltip
                    placement='top'
                    title={level.trustNeeded ? level.trustNeeded + ' trust' : ''}
                  >
                    <Typography variant='h2'>{level.title}</Typography>
                  </Tooltip>
                  {level.achieved && <CheckIcon />}
                </Stack>
              </Grid>
              <Grid item container xs={6} md={8} lg={9}>
                {level.abilities.length ? (
                  level.abilities.map((ability, j) => (
                    <Grid item key={j} xs={12}>
                      <Tooltip placement='left' title={ability?.description}>
                        <Typography mt={1} style={theme.typography.tableData}>
                          {ability?.title}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <Typography mt={1} style={theme.typography.tableData}>
                      -------------------
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      )}

      <Heading text='Trust Earned' backgroundImg={BannerBackground2} />

      <Activity activities={trustActivities} showTrust />
    </Container>
  );
};

export default TrustLevels;
