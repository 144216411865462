import Username from './Username';
import Stack from '@mui/material/Stack';
import CreatedAt from './CreatedAt';
import MKTypography from '../../mkcomponents/MKTypography';

const UsernameCreatedAt = ({ username, suffix, createdAt, compact }) => (
  <Stack
    direction='row'
    spacing={2}
    justifyContent={compact ? 'flex-start' : username ? 'space-between' : 'flex-end'}
  >
    {username && (
      <Stack sx={{ mr: 1 }} direction='row' spacing={0.5}>
        <Username username={username} />
        {!!suffix && <MKTypography variant='h6'>{suffix}</MKTypography>}
      </Stack>
    )}
    <CreatedAt createdAt={createdAt} />
  </Stack>
);
export default UsernameCreatedAt;
