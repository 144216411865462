import Slide from '@mui/material/Slide';
import MKBox from '../../mkcomponents/MKBox';
import MKTypography from '../../mkcomponents/MKTypography';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';

const SlideDownModal = ({ open, title, onClose, children }) => {
  if (!open)
    return null; // todo change this to use Dialog or Popover and properly render so that we can animate closing
  // https://mui.com/material-ui/react-dialog/#AlertDialogSlide.js
  // or https://mui.com/material-ui/react-dialog/#FormDialog.js
  else
    return (
      <Modal
        open={open}
        onClose={onClose}
        sx={{ margin: 3, display: 'grid', placeItems: 'center' }}
      >
        <Slide direction='down' in={open} timeout={500}>
          <MKBox
            position='relative'
            sx={{ width: { xs: 'auto', md: '500px' } }}
            display='flex'
            flexDirection='column'
            borderRadius='xl'
            bgColor='white'
            shadow='xl'
          >
            <MKBox display='flex' justifyContent='space-between' p={2}>
              <MKTypography variant='h5'>{title}</MKTypography>
              <CloseIcon fontSize='medium' sx={{ cursor: 'pointer' }} onClick={onClose} />
            </MKBox>

            {children}
          </MKBox>
        </Slide>
      </Modal>
    );
};

export default SlideDownModal;
