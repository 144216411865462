import Grid from '@mui/material/Grid';
import TheoryCard from '../components/Cards/TheoryCard';
import EvidenceCard from '../components/Cards/EvidenceCard';
import Stack from '@mui/material/Stack';
import { useContext } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { useTheories } from '../services/theories';
import UiContext from '../state/UiContext';
import { useAllEvidences } from '../services/evidence';
import Explainer from '../components/Banners/Explainer';
import BannerImg0 from 'assets/images/home.png';
import { useProposedTheories } from '../services/proposed-theories';
import useLocalStorageState from 'use-local-storage-state';
import SearchForm from '../components/Search/SearchForm';

export default function Home() {
  const theme = useTheme();
  const { mdPlus, lgPlus, xxlPlus } = useContext(UiContext);
  const theoriesQuery = useTheories(undefined);
  const pTheoriesQuery = useProposedTheories();
  const evidencesQuery = useAllEvidences();
  const [explainersOpen, setExplainersOpen] = useLocalStorageState('explainers', {
    defaultValue: [true, true, true, true],
  });

  function closeExplainerFn(i) {
    return () => setExplainersOpen(open => open.map((o, _i) => (_i === i ? false : o)));
  }

  const evidences = evidencesQuery.data?.pages[0]?.data?.slice(0, 4);
  const theories = (
    theoriesQuery.isLoading ? [null, null, null] : theoriesQuery.data?.pages[0]?.data
  )?.slice(0, xxlPlus ? 4 : lgPlus ? 3 : 2);
  const pTheories = (
    pTheoriesQuery.isLoading ? [null, null, null] : pTheoriesQuery.data?.pages[0]?.data
  )?.slice(0, !lgPlus ? 2 : 3);

  const HomeSection = ({ title, children }) => (
    <Box padding={mdPlus ? 4 : 1}>
      <Box
        fontSize={30}
        fontWeight={600}
        lineHeight={'48.41px'}
        color={theme.palette.text.regular}
        sx={{ opacity: 0.9 }}
      >
        {title}
      </Box>
      {children}
    </Box>
  );

  return (
    <Stack spacing={2}>
      {explainersOpen[0] ? (
        <Explainer
          onClose={closeExplainerFn(0)}
          backgroundImg={BannerImg0}
          title={true}
          texts={[
            "Welcome and thank you for participating in EVINCER, the new and necessary public arena for honest individuals like you to be the true 'fact checkers'.",
          ]}
        />
      ) : (
        <SearchForm BannerImg={BannerImg0} />
      )}
      {theories && (
        <HomeSection title='Latest Debates'>
          <Grid container spacing={0}>
            {theories?.map((theory, i) => {
              return (
                <Grid item key={i} xs={12} md={6} lg={4} xxl={3}>
                  <TheoryCard theory={theory} user={theory?.user} date={theory?.createdAt} />
                </Grid>
              );
            })}
          </Grid>
        </HomeSection>
      )}
      {explainersOpen[1] && (
        <Explainer
          onClose={closeExplainerFn(1)}
          texts={[
            "There is no greater current societal problem than the population's outsourcing of thought to others, which tightly coincides with this recent age of censorship.  That's why you're here. ",
            "Together let's investigate evidence, analyze premises, deduce finest arguments, know the best leading hypotheses. Here we exercise the mind through curiosity resulting in an increased confidence in all that you do.",
          ]}
        />
      )}
      {evidences && (
        <HomeSection title='Latest Evidence'>
          <Grid container spacing={0}>
            {evidences.map((evidence, i) => {
              return (
                <Grid item key={i} xs={12} lg={6} md={6}>
                  <EvidenceCard
                    evidence={evidence}
                    theory={evidence.theory}
                    user={evidence.user}
                    date={evidence.createdAt}
                  />
                </Grid>
              );
            })}
          </Grid>
        </HomeSection>
      )}
      {explainersOpen[2] && (
        <Explainer
          onClose={closeExplainerFn(2)}
          texts={[
            'Easy to use with an intuitive design, EVINCER has five unique features to accelerate investigation:',
          ]}
          listItems={[
            'Organizes best arguments on all sides of each issue, sorting by strength.',
            'Promotes hypotheses and also the quantity of hypotheses based on user activity.',
            'Moderates arguments for relevance, productivity, civility.',
            'Records your assessments to let you see your past assessments, help decide which evidence to show first, award Sways.',
            "Notifies you of updates to arguments in which you've taken part and topics you're interested in.",
          ]}
        />
      )}
      {theories && (
        <HomeSection title='Latest Proposals'>
          <Grid container spacing={0}>
            {pTheories?.map((theory, i) => {
              return (
                <Grid item key={i} xs={12} md={6} lg={4}>
                  <TheoryCard theory={theory} user={theory?.user} date={theory?.createdAt} />
                </Grid>
              );
            })}
          </Grid>
        </HomeSection>
      )}
      {explainersOpen[3] && (
        <Explainer
          onClose={closeExplainerFn(3)}
          texts={['We are excited for your activity in the pursuit of knowledge. Thank you!']}
        />
      )}
    </Stack>
  );
}
