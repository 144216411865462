import { useContext, useEffect, useState } from 'react';
import UiContext from 'state/UiContext';
import { useGetToken, useVerifyToken } from 'services/auth';
import useDetectSignIn from 'hooks/use-detect-sign-in';
import { validateEmail } from 'services/validators';
import ValidationError from 'components/ValidationError';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Logo from 'assets/images/logo.png';
import Button from '@mui/material/Button';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import colors from 'assets/theme/base/colors';
import { useMe } from 'services/users';
import Stack from '@mui/material/Stack';
import AuthContext from 'state/AuthContext';

const useStyles = makeStyles({
  root: {
    padding: '30px',
    background: colors.white.main,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    boxShadow: '10px 7px 41px rgba(158, 165, 180, 0.1)',
    '& .emailAddress': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '22px',
      marginTop: '30px',
      paddingLeft: 2,
      color: colors.grey.main,
      backgroundColor: '#F2F2F2',
      width: '100%',
      height: '55px',
      border: '1px solid ' + colors.white.main,
      display: 'flex',
      alignSelf: 'center',
      alignItems: 'center',
      borderRadius: '4px',
    },
    '& a': {
      textDecoration: 'none',
      color: colors.brand.default,
      fontSize: '16px',
      fontWeight: 500,
    },
    '& p': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '24px',
      lineHeight: '160%',
      marginTop: 0,
      marginBottom: 0,
    },
    '& .MuiTextField-root': {
      width: '100%',
    },
    '& button': {
      marginTop: '20px',
      width: '100%',
    },
    '& .logo': {
      '& img': {
        width: '50px',
      },
      '& span': {
        color: '#114573',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '22.3276px',
        lineHeight: '27px',
        alignSelf: 'center',
        marginLeft: '5px',
      },
      marginBottom: '30px',
    },
  },
});

export default function SignInForm({ newVisitor, compact, prompt, sx, redirect }) {
  const { setSigningIn, tokenSentTo, setTokenSentTo } = useContext(AuthContext);
  const [email, setEmail] = useState(tokenSentTo || '');
  const [token, setToken] = useState('');
  const [promptToSwitchEmail, setPromptToSwitchEmail] = useState(false);
  const me = useMe().data?.data;
  const [validationError, setValidationError] = useState('');
  const navigate = useNavigate();
  const createToken = useGetToken();
  const verifyToken = useVerifyToken();
  const { toastSuccess, toastError } = useContext(UiContext);
  const [didYouMean, setDidYouMean] = useState('');

  const _prompt = didYouMean
    ? 'Did you mean ' + didYouMean + '?'
    : !tokenSentTo
    ? prompt || 'Please enter your email'
    : 'Please paste emailed token below';

  useDetectSignIn({
    onSignIn: () => {
      toastSuccess((newVisitor ? 'Sign-up' : 'Sign-in') + ' successful!');
      setSigningIn(false);
      setTokenSentTo(false);
      redirect && navigate(redirect);
    },
  });

  useEffect(() => {
    const userEmail = me?.email;
    if (typeof userEmail === 'string') setEmail(userEmail); // update form with result of /me query
    setToken('');
  }, [me?.email]);

  function onChangeToken(e) {
    setToken(e.target.value.trim());
  }

  function onChangeEmail(e) {
    const value = e.target.value.trim();
    if (value && validationError) setValidationError(validateEmail(value));
    setEmail(value);
    setDidYouMean('');
  }

  function _createToken(email) {
    createToken.mutate(
      { email },
      {
        onSuccess: () => {
          setTokenSentTo(email);
          setDidYouMean('');
          toastSuccess('Token sent to ' + email);
        },
        onError: res => {
          toastError(res.message);
          setDidYouMean(res.data?.didYouMean);
        },
      },
    );
  }

  function onSubmitEmail() {
    if (!email) return;
    const error = validateEmail(email);
    setValidationError(error);
    if (error) {
      toastError(error);
      return;
    }
    _createToken(email);
  }

  function onClickYesImeantThat() {
    _createToken(didYouMean);
  }

  function onSubmitToken() {
    if (validationError) {
      toastError(validationError);
      return;
    }
    if (token) {
      verifyToken.mutate(
        { token: token },
        {
          //onSuccess: handled via useDetectSignIn above
          onError: res => toastError(res.message),
        },
      );
    }
  }

  const classes = useStyles();
  return (
    <Box className={classes.root} sx={sx} alignSelf={'center'} width='100%'>
      {!compact && (
        <>
          <Box display='flex' className='logo'>
            <img src={Logo} alt='Logo' />
            <span>Evincer</span>
          </Box>
          <p data-cy='auth-card-title' style={{ color: '#9F9F9F' }}>
            {newVisitor ? 'Welcome to Evincer...' : 'Welcome again...'}
          </p>
          <p style={{ color: '#002534' }}>{_prompt}</p>
        </>
      )}

      {compact && !tokenSentTo && <p style={{ fontSize: '16px' }}>{_prompt}</p>}
      <Stack direction='row'>
        <TextField
          name='email'
          type='email'
          disabled={!!tokenSentTo}
          onClick={() => !!tokenSentTo && setPromptToSwitchEmail(true)}
          autoFocus
          placeholder={'Email' + (newVisitor ? ' (+500 Trust)' : '')}
          value={email}
          onKeyUp={e => e.key === 'Enter' && onSubmitEmail()}
          onChange={onChangeEmail}
          sx={{ marginTop: compact ? '8px' : '30px' }}
        />
        {promptToSwitchEmail && (
          <Button
            size='small'
            onClick={() => {
              setTokenSentTo(false);
              setPromptToSwitchEmail(false);
            }}
          >
            Change email?
          </Button>
        )}
      </Stack>
      {compact && !!tokenSentTo && <p style={{ fontSize: '16px' }}>{_prompt}</p>}
      {!!tokenSentTo && (
        <TextField
          name='token'
          placeholder={'Token'}
          value={token}
          onKeyUp={e => e.key === 'Enter' && onSubmitToken()}
          onChange={onChangeToken}
        />
      )}
      <ValidationError data-cy='auth-validation-error' error={validationError} />

      {didYouMean ? (
        <Button
          variant='contained'
          size='medium'
          onClick={onClickYesImeantThat}
          disabled={createToken.isLoading}
        >
          Use {didYouMean}
        </Button>
      ) : (
        <Button
          data-cy='auth-btn'
          variant='contained'
          size='medium'
          onClick={!tokenSentTo ? onSubmitEmail : onSubmitToken}
          disabled={createToken.isLoading || verifyToken.isLoading}
        >
          {!tokenSentTo ? 'Next' : !newVisitor ? 'Sign In' : 'Sign Up'}
        </Button>
      )}

      {!compact && !didYouMean && !tokenSentTo && (
        <Box display='flex' marginTop={2}>
          <Box fontSize={16} fontWeight={500} color='#52575E' marginRight={1}>
            {newVisitor ? 'Already have an account?' : 'No account? '}{' '}
          </Box>
          <RouterLink to={newVisitor ? '/sign-in' : '/sign-up'}>
            <span>{newVisitor ? 'Sign In' : 'Sign Up'}</span>
          </RouterLink>
        </Box>
      )}
    </Box>
  );
}
