import validator from 'validator';

export const validateUsername = name => {
  if (name.match(/[^A-Za-z0-9-_]/))
    return 'Username must contain only letters, numbers, "-" and "_".';
  else return '';
};

export const validateEmail = email => {
  if (!validator.isEmail(email)) return 'Invalid email';
  else return '';
};
