import { useGetTheory, useGetTheoryImage } from '../services/theories';
import MKBox from '../mkcomponents/MKBox';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useReducer, useState } from 'react';
import UiContext from '../state/UiContext';
import Stack from '@mui/material/Stack';
import SubscriptionButton from 'components/SubscriptionButton';
import TheorySection from '../components/Theory/TheorySection/';
import Breadcrumbs from '../components/Breadcrumbs';
import EvidenceColumn from 'components/Theory/EvidenceColumn';
import HeadTags from '../components/HeadTags';

const Theory = () => {
  const params = useParams();
  const theoryQuery = useGetTheory(params?.slug);
  const theory = theoryQuery.data?.data;
  const { isXs, isSm, isMd, isLg, isXl } = useContext(UiContext);
  const [forExpansions, dispatchForExpansions] = useReducer(updateTree, {});
  const [againstExpansions, dispatchAgainstExpansions] = useReducer(updateTree, {});
  const [maximize, setMaximize] = useState();
  const [collapseEvents, setCollapseEvents] = useState(0); // how many times has the user pressed "collapse all"
  const getTheoryImage = useGetTheoryImage();

  const breadcrumbItems = [
    {
      link: '/',
      text: 'Home',
    },
    {
      link: '/debates',
      text: 'Debates',
    },
    {
      text: theory?.title,
    },
  ];
  const threshold = ((isXs || isSm) && 1) || (isMd && 2) || (isLg && 3) || (isXl && 4) || 5;

  // update our representation of which evidences/editors are expanded and at what level
  // Right now this is just to set 'maximize' and is overkill but could be useful for other features.
  function updateTree(prevTree, newBranch) {
    if (newBranch === {}) return {};

    for (const b of Object.keys(newBranch)) {
      if (!prevTree[b]) prevTree[b] = newBranch[b];
      if (newBranch[b].level === 0) delete prevTree[b];
      else if (b !== 'level') updateTree(prevTree[b], newBranch[b]);
    }
    return { ...prevTree };
  }

  // has any child met our threshold for maximizing the width of that side?
  function hasMetThreshold(tree, bias = 0) {
    if (tree.level !== undefined && tree.level + bias >= threshold) return true;
    else {
      for (const b of Object.keys(tree))
        if (b !== 'level' && hasMetThreshold(tree[b], b === 'editor' ? 5 : 0))
          // editor adds 2 to help meet threshold
          return true;
    }
    return false;
  }

  // see if anything has expanded past our threshold for expansion
  useEffect(() => {
    if (hasMetThreshold(forExpansions)) {
      setMaximize('for');
    }
    if (hasMetThreshold(againstExpansions)) {
      setMaximize('against');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forExpansions, againstExpansions, threshold]);

  function onClickRestoreColumn() {
    setCollapseEvents(collapseEvents + 1);
    setMaximize(undefined);
  }

  return (
    <div>
      <HeadTags title={theory?.title} description={theory?.body} image={getTheoryImage(theory)} />

      <Breadcrumbs items={breadcrumbItems} />

      <Stack spacing={1} paddingX={1}>
        <TheorySection theory={theory} clamped />

        {theory && (
          <MKBox my={5}>
            <Grid container spacing={{ xs: 1, md: 2, lg: 3, xl: 5 }}>
              {/* ---- AGAINST EVIDENCE ----- */}
              <Grid
                item
                xs={maximize === 'against' ? 11 : maximize === 'for' ? 1 : 6}
                sx={{ transition: 'all 0.5s ease-in-out', position: 'relative' }}
              >
                <EvidenceColumn
                  theory={theory}
                  collapsed={maximize === 'for'}
                  side='against'
                  onClickRestore={onClickRestoreColumn}
                  onChildExpand={dispatchAgainstExpansions}
                  collapseEvents={collapseEvents}
                />
              </Grid>

              {/* ---- FOR EVIDENCE ----- */}
              <Grid
                item
                xs={maximize === 'for' ? 11 : maximize === 'against' ? 1 : 6}
                sx={{ transition: 'all 0.5s ease-in-out' }}
              >
                <EvidenceColumn
                  theory={theory}
                  collapsed={maximize === 'against'}
                  side='for'
                  onClickRestore={onClickRestoreColumn}
                  onChildExpand={dispatchForExpansions}
                  collapseEvents={collapseEvents}
                />
              </Grid>
            </Grid>
          </MKBox>
        )}

        {theory && (
          <SubscriptionButton
            subscription={theory.subscription}
            dataId={theory.id}
            dataKey='theory'
            path='/theory-subscription'
            tooltip='Get notified about new evidence'
          />
        )}
      </Stack>
    </div>
  );
};

export default Theory;
