import Box from '@mui/material/Box';
import DefaultProfileImage from 'assets/images/UserProfile/defaultProfileImage.png';
import DefaultTheoryImage from 'assets/images/Theory/2.png';
import { ChatIcon, CalendarIcon } from '../icons';
import Grid from '@mui/material/Grid';
import CreatedAt from '../elements/CreatedAt';
import { Link as RouterLink } from 'react-router-dom';
import Body from '../Body';
import Username from '../elements/Username';
import { useGetTheoryImage } from '../../services/theories';
import { useContext } from 'react';
import UiContext from '../../state/UiContext';
import { Skeleton } from '@mui/material';
import styles from './TheoryCard.module.scss';
import RankPanel from '../RankPanel';
import PsychologyIcon from '@mui/icons-material/Psychology';

const TheoryCard = ({ theory, user, date, mutator }) => {
  const getTheoryImage = useGetTheoryImage();
  const { smPlus } = useContext(UiContext);

  const href = (theory?.status === 'published' ? '/debate/' : '/proposal/') + theory?.slug;

  return (
    <Box className={styles.root}>
      {theory ? (
        <RouterLink to={href}>
          <img
            className='object-cover rounded w-full h-24 sm:h-48'
            src={getTheoryImage(theory) || DefaultTheoryImage}
            alt='Theory Img'
          />
        </RouterLink>
      ) : (
        <Skeleton variant='rounded' height={smPlus ? 200 : 100} />
      )}
      {theory ? (
        <RouterLink to={href}>
          <p className={styles.title}>{theory.title}</p>
        </RouterLink>
      ) : (
        <Skeleton height={80} />
      )}
      {theory ? (
        <Box className={styles.body}>
          <Body body={theory.body} />
        </Box>
      ) : (
        [1, 2, 3, 4].map(i => <Skeleton key={i} />)
      )}

      <Box display='flex' className={styles.user}>
        {theory ? (
          <img
            src={user.profileImage?.thumbnail?.url || DefaultProfileImage}
            width='30px'
            height='30px'
            alt='User Avatar'
          />
        ) : (
          <Skeleton variant='circular' className='mr-3 w-8 h-8' />
        )}
        {theory ? <Username username={user.username} /> : <Skeleton className='w-20 mr-2' />}
        {theory ? (
          <Box display='flex' className={styles.date}>
            <img src={CalendarIcon} alt='Calendar Icon' />
            <CreatedAt createdAt={date} />
          </Box>
        ) : (
          <Skeleton className='w-5 ml-auto w-32' />
        )}
      </Box>
      {theory && mutator && (
        <RankPanel
          subject={theory}
          expanded={true}
          direction='horizontal'
          subjectKey='proposedTheory'
          mutator={mutator}
        />
      )}
      {theory?.status === 'published' && !mutator && (
        <Box className={styles.counterNumber}>
          <Grid container spacing={1}>
            <Grid item xs={6} className='text-center'>
              <Box display='flex' justifyContent='center'>
                <PsychologyIcon sx={{ height: 'unset' }} />
                <span className='ml-1'>{theory.swayCount}</span>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display='flex' justifyContent='center'>
                <img src={ChatIcon} alt='Evidence Icon' />
                <span>{theory.evidenceCount}</span>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default TheoryCard;
