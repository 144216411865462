import { createContext, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

const UiContext = createContext({});

export const UiContextProvider = ({ children }) => {
  const [toastQueue, setToastQueue] = useState([]);
  const [clipboard, setClipboard] = useState();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), { noSsr: true });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), { noSsr: true });
  const isMd = useMediaQuery(theme.breakpoints.only('md'), { noSsr: true });
  const isLg = useMediaQuery(theme.breakpoints.only('lg'), { noSsr: true });
  const isXl = useMediaQuery(theme.breakpoints.only('xl'), { noSsr: true });
  const isXxl = useMediaQuery(theme.breakpoints.only('xxl'), { noSsr: true });
  const xsPlus = true;
  const smPlus = !isXs;
  const mdPlus = smPlus && !isSm;
  const lgPlus = mdPlus && !isMd;
  const xlPlus = lgPlus && !isLg;
  const xxlPlus = xlPlus && !isXl;
  const breakpoint =
    (isXs && 'xs') ||
    (isSm && 'sm') ||
    (isMd && 'md') ||
    (isLg && 'lg') ||
    (isXl && 'xl') ||
    (isXxl && 'xxl');

  const queue = (type, message) => setToastQueue(p => [...p, { type, message }]);

  const toastError = message => queue('error', message || 'An unknown error has occurred');
  const toastWarning = message => queue('warning', message);
  const toastInfo = message => queue('info', message);
  const toastSuccess = message => queue('success', message);

  function copy(data, dataType) {
    setClipboard({ data, type: dataType });
  }

  function paste() {
    const data = clipboard?.data;
    setClipboard(undefined); // unconventional, but otherwise they see paste icons everywhere forever
    return data;
  }

  return (
    // prettier-ignore
    <UiContext.Provider
      value={{
        isXs, isSm, isMd, isLg, isXl, isXxl, xsPlus, smPlus, mdPlus, lgPlus, xlPlus, xxlPlus, breakpoint,
        toastQueue, setToastQueue, toastSuccess, toastInfo, toastWarning, toastError,
        copy, paste, clipboardType: clipboard?.type,
      }}
    >
      {children}
    </UiContext.Provider>
  );
};

export default UiContext;
