import { fetchClient } from 'services/api';
import { staleTimes } from './query-settings';
import { useMutation, useQuery, useQueryClient, useInfiniteQuery } from 'react-query';
import { useJwt } from './auth';

const apiClient = fetchClient();

const keys = {
  allLists: ['proposed-theories'],
  allOnes: 'proposed-theory',
  one: slug => ['proposed-theory', { slug: slug }],
  admin: ['proposed-theories', { admin: true }],
  me: ['proposed-theories', { me: true }],
  revisions: id => ['proposedTheoryRevisons', id],
};

const getProposedTheory = async slug =>
  apiClient.get('/proposed-theory/' + slug).then(res => res.data);

export const useProposedTheory = slug =>
  useQuery(keys.one(slug), () => getProposedTheory(slug), {
    enabled: !!slug,
    staleTime: staleTimes.long,
  });

const getProposedTheories = async (page = 0) => {
  return apiClient.get('/proposed-theory?page=' + page + '&perPage=12', {}).then(res => res.data);
};

export const useProposedTheories = () =>
  useInfiniteQuery(
    keys.allLists,
    ({ pageParam }) => getProposedTheories(pageParam),
    {
      getNextPageParam: lastPage => {
        if (lastPage.meta.pageCount <= lastPage.meta.page) {
          return null;
        }
        return lastPage.meta.page + 1;
      },
    },
    {
      staleTime: staleTimes.short,
    },
  );

const getAdminProposedTheories = () =>
  apiClient.get('/admin/proposed-theory').then(res => {
    res.data?.data.sort((a, b) => {
      if (a.status === b.status) {
        if (a.status === 'active') return b.rank - a.rank;
        else return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      } else if (a.status === 'active') return -1;
      else return 1;
    });
    return res.data;
  });

export const useAdminProposedTheories = () => useQuery(keys.admin, getAdminProposedTheories);

const getMyProposedTheories = () => apiClient.get('/proposed-theory/me').then(res => res.data);

export const useMyProposedTheories = () => {
  const [jwt] = useJwt();
  return useQuery(keys.me, getMyProposedTheories, {
    enabled: !!jwt,
  });
};

const proposeTheory = async pt => await apiClient.post('/proposed-theory', pt);

export const useProposeTheory = function () {
  const queryClient = useQueryClient();
  return useMutation(
    ({ title, body, tags }) =>
      proposeTheory({
        title: title,
        body: body,
        tags: tags,
      }).then(res => res.data),
    { onSuccess: () => queryClient.invalidateQueries(keys.allLists) },
  );
};

const rankProposedTheory = async (proposedTheoryId, rank) =>
  await apiClient
    .post('/proposed-theory/' + proposedTheoryId + '/rank', rank)
    .then(res => res.data);

export const useRankProposedTheory = function () {
  const queryClient = useQueryClient();
  return useMutation(
    ({ proposedTheory, rank }) => rankProposedTheory(proposedTheory.id, { rank }),
    {
      onSuccess: (data, variables) => {
        // update proposed theories list
        if (queryClient.getQueryData(keys.me))
          queryClient.setQueryData(keys.me, oldPts => {
            return {
              ...oldPts,
              data: oldPts.data.map(pt =>
                pt.id === variables.proposedTheory.id
                  ? { ...pt, ...data.data, userRank: variables.rank }
                  : pt,
              ),
            };
          });

        if (queryClient.getQueryData(keys.allLists))
          queryClient.setQueryData(keys.allLists, oldPts => {
            return {
              ...oldPts,
              pages: oldPts.pages.map(page => ({
                ...page,
                data: page.data.map(pt =>
                  pt.id === variables.proposedTheory.id
                    ? { ...pt, ...data.data, userRank: variables.rank }
                    : pt,
                ),
              })),
            };
          });

        // update this proposed-theory
        if (queryClient.getQueryData(keys.one(variables.proposedTheory.slug)))
          queryClient.setQueryData(keys.one(variables.proposedTheory.slug), oldProposedTheory => {
            return {
              ...oldProposedTheory,
              data: {
                ...oldProposedTheory.data,
                ...data.data,
                userRank: variables.rank,
              },
            };
          });
      },
    },
  );
};

const startUpdateProposedTheory = async id =>
  await apiClient.patch('proposed-theory/' + id + '/start');

export const useStartUpdateProposedTheory = function () {
  const queryClient = useQueryClient();
  return useMutation(pt => startUpdateProposedTheory(pt.id).then(res => res.data), {
    onSuccess: () =>
      queryClient.invalidateQueries(keys.allLists) && queryClient.invalidateQueries(keys.allOnes),
  });
};

const updateProposedTheory = async (id, pt) => await apiClient.patch('/proposed-theory/' + id, pt);

export const useUpdateProposedTheory = function () {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, title, body, tags }) => {
      return updateProposedTheory(id, {
        title: title,
        body: body,
        tags: tags,
      }).then(res => res.data);
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(keys.allLists);
        queryClient.invalidateQueries(keys.allOnes);
        queryClient.invalidateQueries(keys.revisions(variables.id));
      },
    },
  );
};

const publishProposedTheory = async id =>
  await apiClient.post('/proposed-theory/' + id + '/publish');

export const usePublishProposedTheory = function () {
  const queryClient = useQueryClient();
  return useMutation(id => publishProposedTheory(id).then(res => res.data), {
    onSuccess: data => {
      queryClient.invalidateQueries(keys.allLists);
      queryClient.invalidateQueries(keys.one(data.data.slug));
      queryClient.invalidateQueries('theories');
    },
  });
};

const getRevisions = async id =>
  await apiClient.get('/proposed-theory/revisions/' + id).then(res => res.data);

export const useGetRevisions = (id, options) =>
  useQuery(keys.revisions(id), () => getRevisions(id), options);
