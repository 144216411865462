import { useCreateComment } from '../../services/comments';
import { useContext, useState } from 'react';
import UiContext from '../../state/UiContext';
import Button from '@mui/material/Button';
import { usePrompt } from '../../hooks/react-router-dom-extension';
import CommentBodyTextarea from './CommentBodyTextarea';
import { useIsTrusted } from '../../services/config';
import { Tooltip } from '@mui/material';
import CredentialModals from '../CredentialModals';
import AuthContext from '../../state/AuthContext';

const CommentEditor = ({ subject, idField }) => {
  const [expanded, setExpanded] = useState(false);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const { toastSuccess, toastError } = useContext(UiContext);
  const create = useCreateComment();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isTrustedToComment, trustLevelRequired] = useIsTrusted(
    idField === 'evidence' ? 'evidenceComment' : 'proposedTheoryComment',
  );
  const { isSignedIn, isNamed } = useContext(AuthContext);
  const [credentialsOpen, setCredentialsOpen] = useState(false);

  const prompt = 'Discard unsaved comment?';
  usePrompt(prompt, unsavedChanges);

  function onClick() {
    if (isTrustedToComment) setExpanded(true);
  }

  function onChangeTitle(e) {
    setTitle(e.target.value);
    setUnsavedChanges(true);
  }

  function onChangeBody(newBody) {
    setBody(newBody);
    setUnsavedChanges(true);
  }

  function onSubmit() {
    if (!isSignedIn || !isNamed) return setCredentialsOpen(true);
    if (!body && !title) return toastError('Body or title required');

    create.mutate(
      { [idField]: subject.id, title, body },
      {
        onSuccess: data => {
          toastSuccess(data.message);
          setTitle('');
          setBody('');
          setExpanded(false);
          setUnsavedChanges(false);
        },
        onError: data => toastError(data.message),
      },
    );
  }

  function onCancel() {
    if (unsavedChanges && !window.confirm(prompt)) return;

    setExpanded(false);
    setTitle('');
    setBody('');
    setUnsavedChanges(false);
  }

  return (
    <Tooltip
      title={
        isTrustedToComment ? '' : 'Making a comment requires Trust Level ' + trustLevelRequired
      }
    >
      <div className='space-y-2 > *'>
        <div className='bg-gray-100 rounded p-4 drop-shadow'>
          <input
            value={title}
            disabled={!isTrustedToComment}
            maxLength={60}
            className='p-1 w-full'
            placeholder={expanded ? 'Title (optional)' : 'New comment'}
            onChange={onChangeTitle}
            onClick={onClick}
          />
        </div>
        {expanded && (
          <div className='bg-gray-100 rounded p-4 drop-shadow'>
            <CommentBodyTextarea
              placeholder='Body (optional)'
              body={body}
              expanded
              onChange={onChangeBody}
            />
          </div>
        )}
        {expanded && (
          <div className='flex flex-row justify-around'>
            <Button variant='contained' size='small' onClick={onSubmit} disabled={create.isLoading}>
              Submit
            </Button>
            <Button
              variant='containedTertiary'
              size='small'
              onClick={onCancel}
              disabled={create.isLoading}
            >
              Cancel
            </Button>
          </div>
        )}

        <CredentialModals
          open={credentialsOpen}
          requireUsername={true}
          signinPrompt={'Please enter your email to post this comment'}
          usernamePrompt={'Please choose a username to post this comment'}
          onSuccess={() => {
            setCredentialsOpen(false);
            onSubmit();
          }}
          onClose={() => setCredentialsOpen(false)}
        />
      </div>
    </Tooltip>
  );
};

export default CommentEditor;
