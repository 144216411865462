import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Paper, Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import HelpIcon from '@mui/icons-material/Help';

import UiContext from 'state/UiContext';
import { updateSubscription, useSubscriptionSettings } from 'services/subscription-settings';

import MKBox from 'mkcomponents/MKBox';
import { GreenSwitch } from './GreenSwitch';
import AuthContext from '../../state/AuthContext';

const Subscription = ({ subscription, category }) => {
  const { toastSuccess, toastError } = useContext(UiContext);
  const [isActive, setIsActive] = useState(subscription.isActive);

  useEffect(() => {
    setIsActive(subscription.isActive);
  }, [subscription]);

  const handleChange = useCallback(
    async e => {
      const checked = e.target.checked;
      try {
        const res = await updateSubscription({
          id: subscription.id,
          isActive: checked,
          category,
        });
        toastSuccess(res.message);

        setIsActive(checked);
      } catch (e) {
        toastError(e.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subscription, category],
  );

  return (
    <div>
      <Stack direction='row' alignItems='center' spacing={2} sx={{ fontSize: '15px' }}>
        <GreenSwitch checked={isActive} onChange={handleChange} color='secondary' />
        {subscription.link ? (
          <Link to={subscription.link} className='hover:opacity-80'>
            {subscription.label}
          </Link>
        ) : (
          <div>{subscription.label}</div>
        )}
        <Tooltip title={subscription.description}>
          <HelpIcon />
        </Tooltip>
      </Stack>
      {!!subscription.children?.length && (
        <div className='-mb-2 pt-2 pl-20'>
          <SubscriptionCategory data={subscription.children} child={true} />
        </div>
      )}
    </div>
  );
};

const SubscriptionCategory = ({ data, child }) => (
  <>
    {data.map(category => (
      <div
        key={category.id}
        className={classnames({
          'mb-6': !child,
          'mb-2': child,
        })}
      >
        <div className='text-sm font-bold text-black mb-1.5'>{category.label}</div>
        {category.subscriptions.map(subscription => (
          <Subscription key={subscription.id} subscription={subscription} category={category.id} />
        ))}
      </div>
    ))}
  </>
);

const SubscriptionSettings = () => {
  const { isSignedIn } = useContext(AuthContext);

  const subscriptionSettings = useSubscriptionSettings({
    enabled: isSignedIn,
  });

  const data = subscriptionSettings?.data?.data || [];

  if (data.length === 0) {
    return null;
  }

  return (
    <MKBox>
      <Paper sx={{ minHeight: '100px', bgColor: 'white' }} px={4}>
        <Stack p={2}>
          <SubscriptionCategory data={data} />
        </Stack>
      </Paper>
    </MKBox>
  );
};

export default SubscriptionSettings;
