import { Typography, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

const SearchResultSection = ({ title, children }) => {
  const theme = useTheme();

  return (
    <Stack>
      <Typography style={theme.typography.h1}>{title}</Typography>
      {!!children?.length && (
        <Grid container>
          {children.map((child, i) => (
            <Grid item xs={12} xl={6} key={i}>
              {child}
            </Grid>
          ))}
        </Grid>
      )}

      {!children?.length && <Typography>No results</Typography>}
    </Stack>
  );
};

export default SearchResultSection;
