import { Dialog, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MKInput from '../mkcomponents/MKInput';
import { useContext, useState } from 'react';
import Container from '@mui/material/Container';
import UiContext from '../state/UiContext';

const Confirmation = ({ children, open, prompt, onClose }) => {
  function onClickYes(e) {
    e.stopPropagation();
    onClose(true);
  }

  function onClickNo(e) {
    e.stopPropagation();
    onClose(false);
  }

  return (
    <Dialog open={open}>
      <DialogTitle>{prompt}</DialogTitle>
      <Container>
        {children}
        <Stack direction='row' justifyContent='space-between'>
          <Button onClick={onClickYes}>Yes</Button>
          <Button onClick={onClickNo}>No</Button>
        </Stack>
      </Container>
    </Dialog>
  );
};

export default Confirmation;

export const ConfirmationWithInput = ({ open, prompt, label, inputRequired, onClose }) => {
  const [inputValue, setInputValue] = useState('');
  const { toastError } = useContext(UiContext);

  function onCloseConfirmation(value) {
    if (value && inputValue === '' && inputRequired) toastError(label + ' required');
    else onClose(value && inputValue);
  }

  return (
    <Confirmation open={open} prompt={prompt} onClose={onCloseConfirmation}>
      <MKInput
        variant='standard'
        label={label}
        fullWidth
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
      />
    </Confirmation>
  );
};
