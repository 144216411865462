import { useAdjustTrust, useGetUsers } from '../../services/users';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import ta from 'time-ago';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import Username from 'components/elements/Username';

const Users = () => {
  const usersQuery = useGetUsers();
  const adjustTrust = useAdjustTrust();

  const users = usersQuery.data?.pages?.reduce(
    (list, page) => [
      ...list,
      ...page.data.map(user => ({
        ...user,
        username: user.username && <Username username={user.username} />,
        signedUp: user.completedSignup && <CheckIcon />,
        banned: user.isBanned && <CheckIcon />,
        trustEarned: <Button onClick={onClickTrustEarned(user)}>{user.trustEarned}</Button>,
        createdAt: ta.ago(user.createdAt),
      })),
    ],
    [],
  );

  function onClickTrustEarned(user) {
    return () => {
      const delta = window.prompt('How much to add?');
      delta && adjustTrust.mutate({ id: user.id, delta });
    };
  }

  const fields = [
    'username',
    'email',
    'displayName',
    'signedUp',
    'banned',
    'trustLevel',
    'trustEarned',
    'createdAt',
  ];

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {fields.map((field, i) => (
                <TableCell key={field} align={i !== 0 ? 'right' : undefined}>
                  {field}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map(user => (
              <TableRow key={user.id}>
                {fields.map((field, i) => (
                  <TableCell
                    key={field}
                    component={i === 0 ? 'th' : undefined}
                    scope={i === 0 ? 'row' : undefined}
                    align={i !== 0 ? 'right' : undefined}
                  >
                    {user[field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {usersQuery.hasNextPage && (
        <Button onClick={() => usersQuery.fetchNextPage()} variant='contained' margin='auto'>
          {usersQuery.isFetchingNextPage ? 'Loading more...' : 'Load More'}
        </Button>
      )}
    </>
  );
};

export default Users;
