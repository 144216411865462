import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography, useTheme } from '@mui/material';
import colors from '../../assets/theme/base/colors';
import TextField from '@mui/material/TextField';
import ui from '../../config/ui';
import BodyEditor from '../BodyEditor';
import TagEditor from './TheorySection/TagEditor';
import Button from '@mui/material/Button';
import CredentialModals from '../CredentialModals';
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../state/AuthContext';
import UiContext from '../../state/UiContext';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

import {
  useProposedTheory,
  useProposeTheory,
  useStartUpdateProposedTheory,
  useUpdateProposedTheory,
} from '../../services/proposed-theories';
import { useIsTrusted } from '../../services/config';
import { usePrompt } from '../../hooks/react-router-dom-extension';

const ProposedTheoryEditor = () => {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [chosenTags, setChosenTags] = useState([]); // list of tags to be added with this theory
  const { isSignedIn, isNamed } = useContext(AuthContext);
  const { toastSuccess, toastError } = useContext(UiContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const editingProposedTheoryQuery = useProposedTheory(searchParams.get('editId'));
  const proposeTheory = useProposeTheory();
  const updateProposedTheory = useUpdateProposedTheory();
  const startUpdateProposedTheory = useStartUpdateProposedTheory();
  const theme = useTheme();
  const [credentialsOpen, setCredentialsOpen] = useState(false);
  const [isTrustedToProposeTheory, trustLevelRequiredToProposeTheory] =
    useIsTrusted('proposeTheory');

  const editingProposedTheory = editingProposedTheoryQuery.data?.data;
  const titleDisabled = editingProposedTheory?.status === 'active';
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const prompt = editingProposedTheory ? 'Discard unsaved changes?' : 'Discard unsaved proposal?';
  usePrompt(prompt, unsavedChanges);

  useEffect(() => {
    if (editingProposedTheory) {
      if (editingProposedTheory.status === 'pending')
        startUpdateProposedTheory.mutate(editingProposedTheory);
      document.documentElement.scrollTop = 0;
      setTitle(editingProposedTheory.title);
      setBody(editingProposedTheory.body);
      setUnsavedChanges(false);
      setChosenTags(editingProposedTheory.tags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!editingProposedTheory]);

  function checkCredentialsAndSubmit() {
    if (!isSignedIn || !isNamed) setCredentialsOpen(true);
    else submit();
  }

  function submit() {
    proposeTheory.mutate(
      { title: title, body: body, tags: chosenTags },
      {
        onSuccess: res => {
          toastSuccess(res.message);
          setTitle('');
          setBody('');
          setUnsavedChanges(false);
          setChosenTags([]);
        },
        onError: res => {
          toastError(res.message);
        },
      },
    );
  }

  function onCancelEdit() {
    if (unsavedChanges && !window.confirm(prompt)) return;

    if (editingProposedTheory?.status === 'editing')
      updateProposedTheory.mutate({ id: editingProposedTheory?.id }); // set status back to pending
    setSearchParams({});
    setTitle('');
    setBody('');
    setUnsavedChanges(false);
    setChosenTags([]);
  }

  function onUpdateTheory() {
    updateProposedTheory.mutate(
      {
        id: editingProposedTheory?.id,
        ...(titleDisabled ? null : { title }),
        body,
        tags: chosenTags,
      },
      {
        onSuccess: res => {
          toastSuccess(res.message);
          setTitle('');
          setBody('');
          setUnsavedChanges(false);
          setChosenTags([]);
          setSearchParams({});
        },
        onError: res => {
          toastError(res.message);
        },
      },
    );
  }
  return (
    <>
      {!isTrustedToProposeTheory && (
        <Typography variant='h4' color='info' textAlign={'center'} marginTop={3}>
          Trust Level {trustLevelRequiredToProposeTheory} is required before you can propose a new
          theory.
          <br />
          You can learn more about trust levels at{' '}
          <RouterLink to='/help?topic=trust-levels'>this help page</RouterLink>.
        </Typography>
      )}
      <Grid container item xs={12} lg={12} sx={{ mx: 'auto' }}>
        <Box width='100%'>
          <Grid container item p={3}>
            <Grid container item spacing={3}>
              {/*Title and Body*/}
              <Grid item xs={12}>
                <Typography
                  color={colors.text.unSelected + ' !important'}
                  marginBottom={2}
                  marginTop={5}
                  style={{ ...theme.typography.tableData }}
                >
                  Question
                </Typography>
                <TextField
                  disabled={titleDisabled || !isTrustedToProposeTheory}
                  variant='outlined'
                  value={title}
                  fullWidth
                  inputProps={{ maxLength: ui.theoryTitleLength }}
                  onChange={e => {
                    setUnsavedChanges(true);
                    setTitle(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <p className='text-unselected text-lg mb-2 ml-1'>
                  For help writing your proposal, see our{' '}
                  <a
                    className='text-primary'
                    target='_blank'
                    rel='noreferrer'
                    href='/help?topic=guidelines-for-proposing-debates'
                  >
                    proposal guidelines
                  </a>
                </p>
                <BodyEditor
                  body={body}
                  onChange={t => {
                    setUnsavedChanges(true);
                    setBody(t);
                  }}
                  disabled={!isTrustedToProposeTheory}
                />
              </Grid>

              <TagEditor chosenTags={chosenTags} setChosenTags={setChosenTags} />
            </Grid>
            <Grid item justifyContent='center' xs={12} md={6} lg={3} my={2} marginTop={5}>
              <Button
                type='submit'
                variant='contained'
                fullWidth
                onClick={editingProposedTheory ? onUpdateTheory : checkCredentialsAndSubmit}
                disabled={
                  updateProposedTheory.isLoading ||
                  proposeTheory.isLoading ||
                  !isTrustedToProposeTheory
                }
              >
                {editingProposedTheory ? 'Save Changes' : 'Propose Theory'}
              </Button>
            </Grid>
            <CredentialModals
              open={credentialsOpen}
              requireUsername={true}
              signinPrompt={'Please enter your email to propose this theory'}
              usernamePrompt={'Please choose a username to propose this theory'}
              onSuccess={submit}
              onClose={() => setCredentialsOpen(false)}
            />
            {editingProposedTheory && (
              <Grid item my={2} xs={6} marginTop={5}>
                <Button
                  variant='containedTertiary'
                  style={{ marginLeft: 10 }}
                  onClick={onCancelEdit}
                >
                  Cancel
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default ProposedTheoryEditor;
