/* eslint-disable no-param-reassign */
/**
 =========================================================
 * Material Kit 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useContext, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';

// react-router mkcomponents
import { Link as RouterLink, useLocation } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

import Icon from '@mui/material/Icon';
import { makeStyles } from '@mui/styles';
import NavbarItem from './NavbarItem';
import NavbarMobile from './NavbarMobile';
import breakpoints from 'assets/theme/base/breakpoints';
import { useTags } from '../../services/tags';
import {
  parseNotification,
  readNotification,
  useNotifications,
  useInvalidateNotifications,
} from 'services/notifications';
import NotificationItem from 'components/Navbar/NotificationItem';
import staticMenu, { isVisible } from '../../state/menu-and-routes';
import colors from 'assets/theme/base/colors';
import AuthContext from '../../state/AuthContext';
import Logo from 'assets/images/logo.png';
import Dropdown from './Dropdown';
import { useSignOut } from '../../services/auth';
import { useTrustedActionsRef } from '../../services/config';
import flattenPages from '../../util/flattenPages';

const useStyles = makeStyles({
  logo: {
    '& img': {
      width: '40px',
      alignSelf: 'center',
      height: 20,
    },
    '& span': {
      color: '#114573',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '27px',
      alignSelf: 'center',
      marginLeft: '5px',
    },
  },
  navMenuItem: {
    borderRadius: 8,
    cursor: 'pointer',
    transition: 'all 300ms linear',
    '&:hover': {
      backgroundColor: colors.grey[200],
      color: colors.dark.main,
      '& *': {
        color: colors.dark.main,
      },
    },
  },
  markAll: {
    backgroundColor: colors.brand.background,
    color: colors.brand.default,
    width: '100%',
    height: '47px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 400,
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    padding: '10px',
    span: {
      cursor: 'pointer',
    },
  },
});

function Navbar({ brand, transparent, light, sticky, relative }) {
  const [menu, setMenu] = useState(staticMenu);
  const [dropdown, setDropdown] = useState(null);
  const [dropdownName, setDropdownName] = useState('');
  const [isMobileNavbarOpen, setMobileNavbarOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const { me, isGuest, isAuthenticated, isSignedIn, isAdmin } = useContext(AuthContext);
  const signOut = useSignOut();
  const navbarRef = useRef(null);
  const location = useLocation();
  const tagsQuery = useTags();
  const notificationsQuery = useNotifications({ enabled: isAuthenticated });
  const notifications = flattenPages(notificationsQuery.data?.pages);
  const hasUnreadNotification = notifications?.some(notification => !notification.read);
  const trustedActions = useTrustedActionsRef();
  const invalidateNotifications = useInvalidateNotifications();

  const classes = useStyles();

  useEffect(() => {
    function onClickAnywhere(e) {
      if (dropdown && !dropdown.contains(e.target)) collapseMenu();
    }
    document.removeEventListener('click', onClickAnywhere, false);
    document.addEventListener('click', onClickAnywhere, false);
    return () => document.removeEventListener('click', onClickAnywhere, false);
  }, [dropdown]);

  // insert tag routes into menu
  useEffect(
    () => {
      if (tagsQuery.isSuccess && menu?.length && menu[1]?.collapse?.length) {
        const tagsMenu = [{ name: 'All', route: '/debates', component: 'Theories' }];
        tagsMenu.push(
          ...tagsQuery.data?.data?.map(tag => ({
            name: tag.title,
            route: '/debates/' + tag.slug,
            component: 'Theories',
          })),
        );
        setMenu(oldMenu => {
          const newMenu = [...oldMenu];
          newMenu[1]['collapse'] = tagsMenu;
          return newMenu;
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tagsQuery.isSuccess],
  );

  useEffect(() => {
    const flags = { isGuest, isAuthenticated, isSignedIn, isAdmin };
    setMenu(oldMenu =>
      oldMenu.map(item => ({
        ...item,
        hidden: !isVisible({ item, ...flags, trustedActions: trustedActions.current }),
        collapse: item.collapse?.map(item => {
          return {
            ...item,
            hidden: !isVisible({ item, ...flags, trustedActions: trustedActions.current }),
          };
        }),
      })),
    );
  }, [
    isGuest,
    isAuthenticated,
    isSignedIn,
    isAdmin,
    trustedActions,
    trustedActions.current?.length,
  ]);

  // insert notification routes into menu
  useEffect(
    () => {
      const push = 4;
      if (notificationsQuery.isSuccess && menu?.length && menu[push]?.collapse) {
        const notificationsMenu = notifications
          ?.map(notification => {
            const parsedNotification = parseNotification(notification);

            if (!parsedNotification) {
              console.log('malformed notification', notification);
              return null;
            }

            return {
              key: notification.id,
              name: <NotificationItem notification={parsedNotification} />,
              backgroundColor: parsedNotification.read ? undefined : '#f5fbff',
              route: parsedNotification.route,
              onClickMenuItem() {
                if (!notification.read) {
                  readNotification(notification.id).then(() => invalidateNotifications()); // todo update cache instead?
                }
              },
            };
          })
          .filter(notification => notification);
        notificationsMenu?.unshift({
          key: 'markall',
          className: classes.markAll,
          name: 'Mark All As Read',
          onClickMenuItem() {
            readNotification().then(() => invalidateNotifications());
          },
        });
        setMenu(oldMenu => {
          const newMenu = [...oldMenu];
          newMenu[push].collapse = notificationsMenu;
          newMenu[push].name = 'Notifications';
          if (!notificationsMenu.length) {
            newMenu[push].collapse = [{ name: 'No notifications available' }];
          }
          return newMenu;
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationsQuery.isFetching],
  );

  function onReachDropdownBottom() {
    if (dropdownName === 'Notifications') notificationsQuery.fetchNextPage();
  }

  useEffect(
    () => {
      // Refetch notifications when route is changed
      setTimeout(() => isAuthenticated && invalidateNotifications(), 1500);

      // also clear any dropdowns
      collapseMenu();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname],
  );

  useEffect(() => {
    setMenu(oldMenu =>
      oldMenu.map(menu => ({
        ...menu,
        ...(me && menu.id === 'messages'
          ? { name: me.dms ? `Messages (${me.dms > 10 ? '10+' : me.dms})` : 'Messages' }
          : null),
      })),
    );
  }, [me]);

  useEffect(() => {
    // A function that sets the display state for the NavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.md) {
        setIsMobileView(true);
        setMobileNavbarOpen(false);
      } else {
        setIsMobileView(false);
        setMobileNavbarOpen(false);
      }
    }

    /**
      The event listener that's calling the displayMobileNavbar function when
      resizing the window.
      */
    window.addEventListener('resize', displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', displayMobileNavbar);
  }, []);

  function onClickMenuItem(name) {
    setMobileNavbarOpen(false);
    if (name === 'Sign Out') signOut.mutate();
  }

  function collapseMenu() {
    setDropdown(null);
    setDropdownName(null);
  }

  const dropdownItems = menu.reduce((foundItems, { name, collapse, hidden }) => {
    if (foundItems) return foundItems;
    else if (hidden) return null;
    else if (collapse && name === dropdownName) return collapse;
    else return null;
  }, null);

  const renderedNavbarItems = menu?.map(
    item =>
      !item.hidden && (
        <NavbarItem
          key={item.name}
          name={item.name}
          icon={item.icon}
          href={item.href}
          route={item.route}
          collapse={Boolean(item.collapse)}
          onClick={() => onClickMenuItem(item.name)}
          onMouseEnter={({ currentTarget }) => {
            if (item.collapse) {
              setDropdown(currentTarget);
              setDropdownName(item.name);
            }
          }}
          light={light}
          notifications={notifications}
        />
      ),
  );

  return (
    <Box ref={navbarRef} sx={sticky ? { position: 'sticky', top: 0, zIndex: 10 } : null}>
      <Box
        py={1}
        px={{ xs: 2, sm: 2, md: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
        my={relative ? 0 : 2}
        mx={relative ? 0 : 3}
        width={relative ? '100%' : 'calc(100% - 48px)'}
        borderRadius='xl'
        shadow={transparent ? 'none' : 'md'}
        color={light ? 'white' : 'dark'}
        position={relative ? 'relative' : 'absolute'}
        left={0}
        zIndex={3}
      >
        <Box display='flex' justifyContent='space-between' height={50} alignItems='center'>
          <Box
            component={RouterLink}
            to='/'
            lineHeight={1}
            py={transparent ? 1.5 : 0.75}
            ml={2}
            style={{ textDecoration: 'none' }}
            pl={relative || transparent ? 0 : { md: 0, lg: 1 }}
          >
            <Box display='flex' className={classes.logo}>
              <img src={Logo} alt='Logo' />
              <span>{brand}</span>
            </Box>
          </Box>
          <Box color='inherit' display={isMobileView ? 'none' : 'flex'} ml='auto' mr={3}>
            {renderedNavbarItems}
          </Box>
          <Box ml={isMobileView ? 'auto' : 0}></Box>
          <Box
            display={isMobileView ? 'block' : 'none'}
            className='relative'
            lineHeight={0}
            py={1.5}
            pl={1.5}
            color={transparent ? 'white' : 'inherit'}
            sx={{ cursor: 'pointer' }}
            onClick={() => setMobileNavbarOpen(!isMobileNavbarOpen)}
          >
            {(me?.dms || hasUnreadNotification) && !isMobileNavbarOpen && (
              <span className='absolute w-2 h-2 bg-red-600 border border-white rounded-full top-4 right-0 ' />
            )}
            <Icon fontSize='default'>{isMobileNavbarOpen ? 'close' : 'menu'}</Icon>
          </Box>
        </Box>
        <Box
          bgColor={transparent ? 'white' : 'transparent'}
          shadow={transparent ? 'lg' : 'none'}
          borderRadius='xl'
          px={transparent ? 2 : 0}
        >
          {isMobileView && (
            <NavbarMobile
              notifications={notifications}
              routes={menu}
              open={isMobileNavbarOpen}
              onClickMenuItem={onClickMenuItem}
            />
          )}
        </Box>
      </Box>

      <Dropdown
        items={dropdownItems}
        parentRef={dropdown}
        onClickMenuItem={onClickMenuItem}
        onReachBottom={onReachDropdownBottom}
      />
    </Box>
  );
}

// Setting default values for the props of DefaultNavbar
Navbar.defaultProps = {
  brand: 'Evincer',
  transparent: false,
  light: false,
  action: false,
  sticky: false,
  relative: false,
  center: false,
};

// Typechecking props for the DefaultNavbar
Navbar.propTypes = {
  brand: PropTypes.string,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(['external', 'internal']).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'dark',
        'light',
        'default',
        'white',
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
  sticky: PropTypes.bool,
  relative: PropTypes.bool,
  center: PropTypes.bool,
};

export default Navbar;
