import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';

import UiContext from 'state/UiContext';
import { disableSubscription } from 'services/subscription-settings';

import MKTypography from 'mkcomponents/MKTypography';
import { useQueryClient } from 'react-query';
import SubscriptionSettings from 'components/SubscriptionSettings/SubscriptionSettings';
import { subscriptionSettingsQueryKeys } from 'services/subscription-settings';
import AuthContext from '../state/AuthContext';

const SubscriptionSettingsPage = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { toastSuccess, toastError } = useContext(UiContext);
  const { isSignedIn } = useContext(AuthContext);
  const [subscriptionComplete, setSubscriptionComplete] = useState(false);
  const queryClient = useQueryClient();

  const { token, subscribe } = useMemo(() => {
    const params = new URLSearchParams(search);
    return {
      token: params.get('token'),
      label: params.get('label'),
      subscribe: params.get('subscribe'),
    };
  }, [search]);

  useEffect(
    () => {
      if (!subscriptionComplete) {
        return;
      }

      if (isSignedIn) {
        queryClient.invalidateQueries(subscriptionSettingsQueryKeys.all);

        // Remove the query parameters from the URL
        navigate('', { replace: true });
      } else {
        navigate('/');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subscriptionComplete, isSignedIn],
  );

  const disable = useCallback(async () => {
    try {
      const res = await disableSubscription({ idOrToken: token });
      toastSuccess(res.message);
    } catch (e) {
      toastError(e.message);
    } finally {
      setSubscriptionComplete(true);
    }
  }, [toastError, toastSuccess, token]);

  useEffect(() => {
    if (token && subscribe === 'false') {
      setTimeout(() => disable().then(() => {}), 1000);
    }
  }, [token, subscribe, disable]);

  if (!isSignedIn) {
    return null;
  }

  return (
    <Stack spacing={3} py={2} px={4}>
      <MKTypography variant={'h1'}>Subscription settings</MKTypography>
      <SubscriptionSettings />
    </Stack>
  );
};

export default SubscriptionSettingsPage;
