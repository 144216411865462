import { fetchClient } from 'services/api';
import { staleTimes } from './query-settings';
import { useQuery, useMutation, useQueryClient } from 'react-query';

const apiClient = fetchClient();

export const getTags = (params = {}) => {
  return apiClient
    .get('/tag', {
      params,
    })
    .then(res => {
      res.data.data.sort((a, b) => (a.slug < b.slug ? -1 : 1));
      return res.data;
    });
};

export const useTags = () => useQuery('tags', getTags, { staleTime: staleTimes.long });

const createTag = async ({ title }) => {
  const response = await apiClient.post('/tag', {
    title: title,
  });
  return response;
};

export const useCreateTag = function () {
  const queryClient = useQueryClient();
  return useMutation(({ title }) => createTag({ title: title }).then(res => res.data), {
    onSuccess: () => queryClient.invalidateQueries('tags'),
  });
};

const updateTag = async tag => {
  const formData = new FormData();
  ['title', 'image'].forEach(field => tag[field] && formData.append(field, tag[field]));

  await apiClient.patch('/tag/' + tag.id, formData);
};

export const useUpdateTag = () => {
  const queryClient = useQueryClient();
  return useMutation(updateTag, {
    onSuccess: () => queryClient.invalidateQueries('tags'),
  });
};

const deleteTag = async ({ id }) => {
  const { data: response } = await apiClient.delete('/tag/' + id);
  return response;
};

export const useDeleteTag = function () {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id }) => {
      return deleteTag({ id: id }).then(res => res.data);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries('tags');
      },
    },
  );
};
