import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { useContext } from 'react';
import UiContext from '../../state/UiContext';
const useStyles = makeStyles({
  banner: {
    width: '100%',
    height: '250px',
    display: 'flex',
    backgroundSize: 'cover',
    justifyContent: 'center',
    '& span': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '40px',
      lineHeight: '48px',
      color: '#FFFFFF',
      alignSelf: 'center',
      justifyContent: 'center',
    },
  },
  mobile: {
    height: '125px',
  },
});

export default function Heading({ text, backgroundImg }) {
  const classes = useStyles();
  const { smPlus } = useContext(UiContext);

  return (
    <Box
      className={smPlus ? classes.banner : classes.banner + ' ' + classes.mobile}
      style={{ backgroundImage: `url(${backgroundImg})` }}
    >
      <span>{text}</span>
    </Box>
  );
}
