import { useContext, useState } from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MKBox from '../../mkcomponents/MKBox';
import MKInput from '../../mkcomponents/MKInput';
import MKButton from '../../mkcomponents/MKButton';

import { useTags, useCreateTag, useDeleteTag, useUpdateTag } from 'services/tags';
import UiContext from '../../state/UiContext';
import ui from '../../config/ui';
import Button from '@mui/material/Button';
import TheoryImg1 from 'assets/images/Theory/1.png';

export default function AdminTags() {
  const [newTag, setNewTag] = useState('');
  const { toastSuccess, toastError } = useContext(UiContext);

  const tags = useTags().data;
  const updateTag = useUpdateTag();

  const createMutation = useCreateTag();
  const deleteMutation = useDeleteTag();

  function onSubmitTag() {
    createMutation.mutate(
      { title: newTag },
      {
        onSuccess: res => {
          toastSuccess(res.message);
          setNewTag('');
        },
        onError: res => {
          toastError(res.message);
        },
      },
    );
  }

  function onDeleteTag(id) {
    return () =>
      deleteMutation.mutate(
        { id: id },
        {
          onSuccess: () => {
            toastSuccess('Tag deleted');
          },
          onError: data => {
            toastError(data.message);
          },
        },
      );
  }

  function onClickImage(id) {
    return () => document.getElementById(`image${id}`).click();
  }

  function onChangeImage(id) {
    return e =>
      updateTag.mutate({ id, image: e.target.files[0] }, { onError: e => toastError(e.message) });
  }

  return (
    <MKBox
      position='relative'
      width='500px'
      display='flex'
      flexDirection='column'
      borderRadius='xl'
      bgColor='white'
      shadow='xl'
    >
      <MKBox p={2}>
        {tags?.data?.map(tag => (
          <MKBox key={tag.slug}>
            {tag.title}
            <Button onClick={onDeleteTag(tag.id)}>
              <DeleteForeverIcon />
            </Button>

            <input
              id={`image${tag.id}`}
              accept='image/*'
              className='imageInput'
              type='file'
              hidden
              onChange={onChangeImage(tag.id)}
            />
            <img
              width='200px'
              alt='tag'
              onClick={onClickImage(tag.id)}
              src={tag.image?.url || TheoryImg1}
            ></img>
          </MKBox>
        ))}
      </MKBox>

      <MKBox p={2}>
        <MKInput
          variant='standard'
          label='Tag'
          fullWidth
          inputProps={{ maxLength: ui.tagLength }}
          value={newTag}
          onChange={e => setNewTag(e.target.value)}
        />
      </MKBox>

      <MKBox p={2}>
        <MKButton type='submit' variant='gradient' color='info' onClick={onSubmitTag}>
          Create Tag
        </MKButton>
      </MKBox>
    </MKBox>
  );
}
