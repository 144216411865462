import { useParams, useSearchParams } from 'react-router-dom';
import { useEvidence, useGetRevisions } from '../services/evidence';
import EvidenceNode from '../components/Evidence/EvidenceNode';
import Stack from '@mui/material/Stack';
import Breadcrumbs from 'components/Breadcrumbs';
import SubscriptionButton from 'components/SubscriptionButton';
import { Typography } from '@mui/material';
import colors from '../assets/theme/base/colors';
import React, { useEffect, useRef, useState } from 'react';
import HeadTags from '../components/HeadTags';
import RevisionHistory from '../components/RevisionHistory';

const Evidence = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const evidence = useEvidence(params.slug).data?.data;
  const [showRevisionHistory, setShowRevisionHistory] = useState(false);
  const revisionHistoryRef = useRef();

  useEffect(() => {
    if (searchParams.get('showRevisionHistory')) {
      setShowRevisionHistory(true);
      setSearchParams({}, { replace: true });
      window.setTimeout(
        () => revisionHistoryRef?.current.scrollIntoView({ behavior: 'smooth' }),
        100,
      );
    }
  }, [searchParams, setSearchParams, setShowRevisionHistory, revisionHistoryRef]);

  if (!evidence) return <Typography>Loading...</Typography>;

  let breadcrumbs = [
    {
      link: '/',
      text: 'Home',
    },
    {
      link: '/debates',
      text: 'Debates',
    },
    {
      link: '/debate/' + evidence.theory.slug,
      text: evidence.theory.title,
    },
  ];
  evidence?.parents
    .map((parent, i) => ({ ...parent, for: i % 2 ? evidence.for : !evidence.for }))
    .slice()
    .reverse()
    .forEach((parent, i) =>
      breadcrumbs.push({
        link: (i === 0 ? '/evidence/' : '/rebuttal/') + parent.slug,
        text: parent.title,
        for: parent.for,
        color: colors.evidence[parent.for ? 'for' : 'against'],
      }),
    );

  breadcrumbs.push({
    text: evidence.title,
    for: evidence.for,
    color: colors.evidence[evidence.for ? 'for' : 'against'],
  });

  if (breadcrumbs.length >= 4)
    breadcrumbs[3].text = (breadcrumbs[3].for ? 'Yes:' : 'No: ') + breadcrumbs[3].text;

  return (
    <Stack spacing={5}>
      <HeadTags title={evidence.title} description={evidence.body} />

      <Breadcrumbs items={breadcrumbs} />

      <EvidenceNode
        startExpanded={true}
        theory={evidence.theory}
        evidence={evidence}
        level={1}
        parentEvidence={evidence.parents.length ? evidence.parents[0] : undefined}
      />

      <div className='px-5'>
        <RevisionHistory
          show={showRevisionHistory}
          ref={revisionHistoryRef}
          subject={evidence}
          useQuery={useGetRevisions}
        />
      </div>

      <SubscriptionButton
        subscription={evidence.subscription}
        dataId={evidence.id}
        dataKey='evidence'
        path='/evidence-subscription'
        tooltip='Get notified about new rebuttals and comments'
      />
    </Stack>
  );
};

export default Evidence;
