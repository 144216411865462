import { TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { SearchWhiteIcon } from '../icons';
import Grid from '@mui/material/Grid';
import DefaultBannerImg from 'assets/images/background2.png';
import { useTags } from '../../services/tags';
import UiContext from '../../state/UiContext';

const useStyles = makeStyles({
  root: {
    width: '100%',
    minHeight: '350px',
    paddingTop: 70,
    paddingBottom: 20,
    '& .tag': {
      background: 'rgba(255, 255, 255, 0.2)',
      padding: '5px 20px',
      backdropFilter: 'blur(10px)',
      color: 'white',
      fontSize: 16,
      borderRadius: '41px',
      border: '1px solid rgba(255, 255, 255, 0.35);',
      cursor: 'pointer',
    },
    '& .tag-mobile': {
      padding: '3px 10px',
      fontSize: 12,
      borderRadius: '20px',
    },
    '& .searchBox': {
      position: 'relative',
      '& .searchIcon': {
        position: 'absolute',
        right: 16,
        top: 12,
        background: 'rgba(255, 255, 255, 0.2)',
        width: 52,
        height: 52,
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 100,
        cursor: 'pointer',
        '& img': {
          width: 25,
        },
      },
      '& .MuiTextField-root': {
        width: '100%',
        '& input': {
          background: 'rgba(255, 255, 255, 0.2)',
          height: 42,
          border: '2px solid rgba(255, 255, 255, 0.4)',
          backdropFilter: 'blur(4px)',
          borderRadius: 41,
          color: 'white',
          fontSize: 20,
          lineHeight: '24px',
          fontWeight: 400,
          '&::placeholder': {
            color: 'white !important',
          },
        },
        '& fieldset': {
          display: 'none',
        },
        '&.Mui-focused': {
          '& input': {
            color: 'white !important',
          },
          color: 'white !important',
        },
      },
    },
  },
});
const SearchForm = ({ BannerImg }) => {
  const { mdPlus } = useContext(UiContext);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [searchString, setSearchString] = useState('');
  const classes = useStyles();
  const tagsQuery = useTags();

  useEffect(() => setSearchString(params.get('searchString') || ''), [params]);

  function onChangeSearchString(e) {
    setSearchString(e.target.value);
  }

  function submitSearch() {
    navigate('/search/?searchString=' + encodeURIComponent(searchString));
  }

  return (
    <Box
      className={classes.root}
      style={{
        background: `url(${BannerImg || DefaultBannerImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box width='90%' maxWidth={'750px'} margin='auto'>
        <Box className='searchBox'>
          <TextField
            placeholder='Search...'
            value={searchString}
            onChange={onChangeSearchString}
            onKeyUp={e => e.key === 'Enter' && submitSearch()}
          />
          <Box className='searchIcon' onClick={submitSearch}>
            <img src={SearchWhiteIcon} alt='searchIcon'></img>
          </Box>
        </Box>
        <Box marginTop={3} justifyContent={'center'}>
          <Grid container spacing={1} style={{ justifyContent: 'center' }}>
            {tagsQuery.data?.data?.map(tag => (
              <Grid item key={tag.slug}>
                <Box
                  className={mdPlus ? 'tag' : 'tag tag-mobile'}
                  onClick={() => navigate('/debates/' + tag.slug)}
                >
                  #{tag.slug}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default SearchForm;
