import help from './Help.json';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import React, { useContext, useEffect, useState } from 'react';
import Body from '../components/Body';
import Card from '@mui/material/Card';
import { CardContent } from '@mui/material';
import MKTypography from '../mkcomponents/MKTypography';
import Stack from '@mui/material/Stack';
import { useSearchParams } from 'react-router-dom';
import UiContext from '../state/UiContext';
import BannerImg from '../assets/images/bannerImg2.png';
import Heading from '../components/Banners/Heading';
import Button from '@mui/material/Button';
import Breadcrumbs from '../components/Breadcrumbs';
import HeadTags from '../components/HeadTags';

const Help = () => {
  const [tab, setTab] = useState();
  const [params, setParams] = useSearchParams();
  const { isXs } = useContext(UiContext);

  useEffect(() => {
    if (!tab && params.get('topic')) setTab(params.get('topic'));
    if (tab && !params.get('topic')) setTab(null);
  }, [tab, params]);

  function onClickTopic(slug) {
    return () => {
      setParams('?topic=' + slug);
      setTab(slug);
    };
  }

  const slugs = help.map(section => Object.keys(section)[0]);
  const topicMap = help.reduce((prev, curr) => {
    const slug = Object.keys(curr)[0];
    return { ...prev, [slug]: curr[slug] };
  }, {});
  const topic = topicMap[tab];
  const body = topic ? topic.slice(1).join('\n\n') : '';

  const breadcrumbItems = [
    {
      link: '/',
      text: 'Home',
    },
    {
      link: '/help',
      text: 'Help',
    },
  ];
  if (topic) breadcrumbItems.push({ text: topic[0] });

  return (
    <>
      <HeadTags title='Help' />

      <Heading text='Help Page' backgroundImg={BannerImg} />

      <Breadcrumbs items={breadcrumbItems} />

      <Container sx={{ mt: 3 }}>
        <Stack spacing={3}>
          <Grid container spacing={2}>
            {tab === undefined && (
              <Grid item xs={4}>
                Choose a topic
              </Grid>
            )}

            <Grid item xs={4}>
              <Stack spacing={2} alignItems={tab ? 'flex-end' : 'flex-start'}>
                {slugs.map(slug => (
                  <Button
                    key={slug}
                    size={(isXs && 'small') || 'medium'}
                    sx={{ textAlign: tab ? 'right' : 'left' }}
                    disabled={tab === slug}
                    onClick={onClickTopic(slug)}
                  >
                    {topicMap[slug][0]}
                  </Button>
                ))}
              </Stack>
            </Grid>

            <Grid item xs={8}>
              {topic && <MKTypography variant='h2'>{topic[0]}</MKTypography>}
              {body && (
                <Card>
                  <CardContent>
                    <Body body={body} />
                  </CardContent>
                </Card>
              )}
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </>
  );
};

export default Help;
