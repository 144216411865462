import { useRespond } from '../../services/comments';
import { useContext, useState } from 'react';
import UiContext from '../../state/UiContext';
import Button from '@mui/material/Button';
import { usePrompt } from '../../hooks/react-router-dom-extension';
import CommentBodyTextarea from './CommentBodyTextarea';
import CredentialModals from '../CredentialModals';
import AuthContext from '../../state/AuthContext';

const CommentResponseEditor = ({ subject, comment }) => {
  const [expanded, setExpanded] = useState(false);
  const [body, setBody] = useState('');
  const { toastSuccess, toastError } = useContext(UiContext);
  const respond = useRespond();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { isSignedIn, isNamed } = useContext(AuthContext);
  const [credentialsOpen, setCredentialsOpen] = useState(false);

  const prompt = 'Discard unsaved response?';
  usePrompt(prompt, unsavedChanges);

  function onChangeBody(newBody) {
    setBody(newBody);
    setUnsavedChanges(true);
  }

  function onSubmit() {
    if (!isSignedIn || !isNamed) return setCredentialsOpen(true);
    if (!body) return toastError('Body required');

    respond.mutate(
      { subjectId: subject?.id, id: comment.id, body },
      {
        onSuccess: data => {
          toastSuccess(data.message);
          setBody('');
          setExpanded(false);
          setUnsavedChanges(false);
        },
        onError: data => toastError(data.message),
      },
    );
  }

  function onCancel() {
    if (unsavedChanges && !window.confirm(prompt)) return;
    setExpanded(false);
    setBody('');
    setUnsavedChanges(false);
  }

  return (
    <div className='space-y-1 > *'>
      <CommentBodyTextarea
        placeholder={'Your Response'}
        body={body}
        onChange={onChangeBody}
        onClick={() => setExpanded(true)}
        expanded={expanded}
        disabled={respond.isLoading}
      />

      {expanded && (
        <div className='flex flex-row justify-around'>
          <Button variant='contained' size='small' onClick={onSubmit} disabled={respond.isLoading}>
            Submit
          </Button>
          <Button variant='containedTertiary' size='small' onClick={onCancel}>
            Cancel
          </Button>
        </div>
      )}

      <CredentialModals
        open={credentialsOpen}
        requireUsername={true}
        signinPrompt={'Please enter your email to post this response'}
        usernamePrompt={'Please choose a username to post this response'}
        onSuccess={() => {
          setCredentialsOpen(false);
          onSubmit();
        }}
        onClose={() => setCredentialsOpen(false)}
      />
    </div>
  );
};

export default CommentResponseEditor;
