/**
 =========================================================
 * Material Kit 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material mkcomponents
import MKBox from 'mkcomponents/MKBox';
import Navbar from 'components/Navbar/Navbar';
import Footer from 'components/Footer/Footer';
import { Routes, useLocation } from 'react-router-dom';
import SignInModal from './SignIn/SignInModal';
import Toast from './Toast';
import React, { useContext, useEffect, useState } from 'react';
import UiContext from '../state/UiContext';
import { useUpdateUserQueryData } from '../services/users';
import { getRoutes } from '../state/menu-and-routes';
import usePushNotifications from 'hooks/use-push-notifications';
import { useClearCache } from 'react-clear-cache';
import MKTypography from '../mkcomponents/MKTypography';
import MKButton from '../mkcomponents/MKButton';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import AuthContext from '../state/AuthContext';

function Layout() {
  const theme = useTheme();
  const { toastSuccess, toastInfo, unsavedEditors } = useContext(UiContext);
  const { me, signingIn, setSigningIn } = useContext(AuthContext);
  const { pathname } = useLocation();
  const updateUserQueryData = useUpdateUserQueryData();
  const { subscribe, unsubscribe } = usePushNotifications();
  const {
    loading: reloading,
    isLatestVersion,
    emptyCacheStorage,
  } = useClearCache({ duration: 5 * 60 * 1000 });
  const [showUpdateButton, setShowUpdateButton] = useState(false);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (!isLatestVersion && !showUpdateButton) {
      if (!unsavedEditors) emptyCacheStorage(); // empty cache and refresh page
      else setShowUpdateButton(true);
    }
  }, [isLatestVersion, unsavedEditors, emptyCacheStorage, showUpdateButton, setShowUpdateButton]);

  useEffect(() => {
    if (!me?.id) {
      return;
    }

    const channel = subscribe(`private-self-${me.id}`);
    if (!channel) {
      return;
    }

    channel.bind('update-dms', ({ dms }) => updateUserQueryData({ dms }));

    channel.bind('trust-earned', ({ delta, trustEarned, trustLevel }) => {
      const newLevel = trustLevel > me.trustLevel;
      updateUserQueryData({ trustEarned, trustLevel });
      toastInfo((delta > 0 ? '+' : '') + delta + ' Trust');
      if (newLevel) toastInfo('You reached Trust Level ' + trustLevel);
    });
    return () => {
      channel.unbind('update-dms');
      channel.unbind('trust-earned');
      unsubscribe(`private-self-${me.id}`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, subscribe]);

  return (
    <>
      <Box
        position='relative'
        py={0.25}
        sx={{
          backgroundColor: theme.palette.white.main,
          borderBottom: '1px solid rgba(0,0,0,0.05)',
        }}
      >
        <Navbar transparent relative light center />
      </Box>

      {showUpdateButton && (
        <Container sx={{ p: 1 }}>
          <Stack direction='row' spacing={2}>
            <MKTypography variant='h4'>A new version of Evincer is available!</MKTypography>
            <MKButton
              disabled={reloading}
              onClick={e => {
                e.preventDefault();
                emptyCacheStorage();
              }}
            >
              Update
            </MKButton>
            {!!unsavedEditors && (
              <MKTypography variant='h4' color='warning'>
                This will cause you to lose any unsaved changes.
              </MKTypography>
            )}
          </Stack>
        </Container>
      )}

      <MKBox width='100%' mx='auto' sx={{ minHeight: '100vh' }}>
        <Box
          width='100%'
          p={0}
          justifyContent='center'
          height='100%'
          maxWidth={1440}
          margin={'auto'}
        >
          <Box>
            <Routes>{getRoutes()}</Routes>

            <SignInModal
              open={!!signingIn}
              title={signingIn}
              onSuccess={() => {
                toastSuccess('Sign-in successful');
                setSigningIn(false);
              }}
              onClose={() => setSigningIn(false)}
            />

            <Toast />
          </Box>
        </Box>
      </MKBox>

      <Footer />
    </>
  );
}

export default Layout;
