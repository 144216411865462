import { useRepublishEvidence } from 'services/evidence';
import { useContext } from 'react';
import AuthContext from 'state/AuthContext';

const Status = ({ evidence }) => {
  const republishEvidence = useRepublishEvidence();
  const { isAdmin } = useContext(AuthContext);

  function republish() {
    if (isAdmin && window.confirm('republish?')) republishEvidence.mutate(evidence);
  }

  if (evidence.status === 'published') return null;
  else
    return (
      <p className='text-info text-xl' onClick={republish}>
        {evidence.status}
        {evidence.status === 'archived' && evidence.archiveReason && `: ${evidence.archiveReason}`}
      </p>
    );
};

export default Status;
