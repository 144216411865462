import axios from 'axios';
import api from 'config/api';

// migration (temporary) todo remove Oct 31
const jwt = localStorage.getItem('jwt');
if (jwt === '' || (jwt?.length && jwt[0] !== '"')) localStorage.setItem('jwt', JSON.stringify(jwt));

export const fetchClient = () => {
  const defaultOptions = {
    baseURL: api.baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
    validateStatus: () => true, // so that we can throw our own custom errors (below)
  };
  const instance = axios.create(defaultOptions);

  // insert auth headers to all requests
  instance.interceptors.request.use(config => {
    let jwt = JSON.parse(localStorage.getItem('jwt'));
    if (jwt && !(config.headers && config.headers.authorization)) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers.authorization = 'jwt ' + jwt;
    }
    return config;
  });

  instance.interceptors.response.use(async res => {
    //await new Promise(r => setTimeout(r,500)); //uncomment to simulate delay

    if (res.headers['x-jwt']) {
      localStorage.setItem('jwt', JSON.stringify(res.headers['x-jwt']));
    }

    if (res.status === 401) {
      // invalid header
      localStorage.setItem('jwt', JSON.stringify(''));
    }

    if (res.status < 200 || res.status >= 300) {
      throw res.data;
    } else {
      return res;
    }
  });

  return instance;
};

/**
 * @deprecated it's not being used.
 * @param {} rt
 */
