/**
 =========================================================
 * Material Kit 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

// react-router-dom components
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
// @mui material components
import Collapse from '@mui/material/Collapse';
import Icon from '@mui/material/Icon';

// Material Kit 2 PRO React components
import { useTheme } from '@mui/material';
import colors from 'assets/theme/base/colors';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { ThreeDotIcon } from '../icons';
import AuthContext from '../../state/AuthContext';
import DefaultProfileImage from '../../assets/images/UserProfile/defaultProfileImage.png';

const useStyles = makeStyles({
  root: {
    paddingLeft: '20px',
    paddingRight: '20px',
    marginBottom: 0,
    '@media (max-width:767px)': {
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: '20px',
    },
    position: 'relative',
    '& .notificationIcon': {
      position: 'relative',
      '& .notificationDot': {
        width: 8,
        height: 8,
        background: '#FF3F3F',
        border: '1px solid #FFFFFF',
        borderRadius: 100,
        position: 'absolute',
        left: 17,
      },
    },
    '& .dot': {
      position: 'absolute',
      bottom: '-10px',
      left: '50%',
      width: 5,
      height: 5,
      justifyContent: 'center',
      margin: 'auto',
      borderRadius: 100,
      '@media (max-width:767px)': {
        display: 'none',
      },
    },
    '&:hover': {
      '& span': {
        color: colors.brand.default + ' !important',
      },
      '& .dot': {
        backgroundColor: colors.brand.default,
      },
    },
  },
});

function NavbarItem({
  name,
  icon,
  children,
  collapseStatus,
  light,
  href,
  route,
  collapse,
  onClick,
  notifications,
  ...rest
}) {
  const theme = useTheme();
  const hasUnreadNotification = notifications?.some(notification => !notification.read);

  const { me, isAuthenticated, isSignedIn } = useContext(AuthContext);
  const routeComponent = {
    component: RouterLink,
    to: route,
  };

  const classes = useStyles();
  return (
    <>
      <Box
        {...rest}
        onClick={onClick}
        opacity={light ? 1 : 0.6}
        alignSelf={'center'}
        style={{ cursor: 'pointer', userSelect: 'none', textDecoration: 'none' }}
        {...((route || href) && routeComponent)}
      >
        {name === 'Account' && isAuthenticated ? (
          <Box display='flex' pt={2}>
            <img
              src={me?.profileImage?.thumbnail?.url || DefaultProfileImage}
              className='w-10 h-10 rounded-sm object-cover'
              alt='User Avatar'
            />
            <Box ml={1}>
              <p
                style={{
                  ...theme.typography.formTitle,
                  color: theme.palette.text.regular,
                  margin: 0,
                }}
              >
                {isSignedIn ? 'Signed in as' : 'Guest User'}
              </p>
              <p
                style={{ ...theme.typography.h4, fontWeight: 500, marginTop: 8, marginBottom: 16 }}
              >
                {me?.displayName || me?.username || me?.email}
              </p>
            </Box>
            <span style={{ color: theme.palette.text.unSelected, marginLeft: 8 }}>
              {me?.dms ? (
                <MailOutlineIcon
                  sx={{
                    fontWeight: 'normal',
                    verticalAlign: 'middle',
                    fontSize: 'large',
                    color: colors.tertiary.focus,
                  }}
                ></MailOutlineIcon>
              ) : (
                <img
                  src={ThreeDotIcon}
                  alt='Icon'
                  style={{ width: '4px', marginTop: '10px', marginLeft: '10px' }}
                />
              )}
            </span>
          </Box>
        ) : (
          <Box className={classes.root}>
            <Box display='flex' alignItems='baseline'>
              {name === 'Notifications' && (
                <Box
                  lineHeight={1}
                  color='inherit'
                  className='notificationIcon'
                  sx={{
                    alignSelf: 'center',
                    position: 'relative',
                    '& *': { verticalAlign: 'middle' },
                    borderRadius: 2,
                    padding: 1,
                    border: name === 'Notifications' ? '1px solid rgba(0,0,0,0.05)' : '',
                  }}
                >
                  {hasUnreadNotification && <span className='notificationDot'></span>}
                  <img src={icon} alt='Notification Icon' />
                </Box>
              )}
              <span style={{ ...theme.typography.navRegular }}>
                {name !== 'Notifications' && name}
              </span>
              {name !== 'Notifications' && collapse && (
                <span style={{ color: theme.palette.text.unSelected, lineHeight: 0 }}>
                  <Icon sx={{ fontWeight: 'normal', verticalAlign: 'middle' }}>
                    keyboard_arrow_down
                  </Icon>
                </span>
              )}
            </Box>
            {name !== 'Notifications' && (
              <Box className='dot'>
                <span></span>
              </Box>
            )}
          </Box>
        )}
      </Box>
      {children && (
        <Collapse in={Boolean(collapseStatus)} timeout={400} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of NavbarItem
NavbarItem.defaultProps = {
  children: false,
  collapseStatus: false,
  light: false,
  href: '',
  route: '',
};

// Typechecking props for the NavbarItem
NavbarItem.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  collapseStatus: PropTypes.bool,
  light: PropTypes.bool,
  href: PropTypes.string,
  route: PropTypes.string,
  collapse: PropTypes.bool.isRequired,
};

export default NavbarItem;
