import { useCallback, useContext, useEffect, useState } from 'react';
import UiContext from '../state/UiContext';
import { useIsTrusted } from '../services/config';
import { useTheme } from '@mui/material';
import {
  ThumbUpIcon,
  ThumbDownIcon,
  ThumbUpFilledIcon,
  ThumbDownFilledIcon,
  ThumbUpWhiteIcon,
  ThumbDownWhiteIcon,
} from './icons';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import TooltipButton from './TooltipButton';
import AuthContext from '../state/AuthContext';

const useStyles = makeStyles({
  root: {
    '& button': {
      '& img': {
        width: '15px',
      },
    },
  },
});

const RankPanel = ({ subject, subjectKey, mutator, disabled, expanded, direction }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [timer, setTimer] = useState();
  const { isSignedIn, setSigningIn } = useContext(AuthContext);
  const { toastError } = useContext(UiContext);
  const [currentRank, setCurrentRank] = useState(); // cache updated vote here (overriding subject.vote)
  const [unsavedDelta, setUnsavedDelta] = useState(false);
  const [isTrustedToPromote] = useIsTrusted('promote');
  const [isTrustedToDoublePromote] = useIsTrusted('doublePromote');
  if (!isTrustedToPromote) disabled = true;

  const RankButton = ({ type, onClick, children, disabled }) => {
    const [isTrustedToPromote, trustLevelRequiredToPromote] = useIsTrusted('promote');
    const buttonBgColor =
      type === 'double-up-active'
        ? theme.palette.green.main
        : type === 'up-active'
        ? theme.palette.green.light
        : type === 'double-down-active'
        ? theme.palette.error.main
        : type === 'down-active'
        ? theme.palette.error.light
        : '#EFEFEF';

    return (
      <TooltipButton
        title={
          isTrustedToPromote ? '' : 'Ranking requires Trust Level ' + trustLevelRequiredToPromote
        }
        variant='contained'
        style={{
          padding: 0,
          margin: 0,
          boxShadow: 'none',
          border: type === 'deactive' && '1px solid rgba(0, 0, 0, 0.03)',
          backgroundColor: buttonBgColor,
          minWidth: direction === 'vertical' ? '50px' : 'auto',
          width: '100%',
          minHeight: direction === 'vertical' && 50,
        }}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </TooltipButton>
    );
  };

  const getCurrentRank = useCallback(() => {
    return currentRank !== undefined
      ? currentRank
      : subject.userRank !== undefined
      ? subject.userRank
      : 0;
  }, [currentRank, subject]);

  const onRank = useCallback(
    delta => {
      if (!isSignedIn) {
        setSigningIn(
          'Please enter your email to save your ' + (delta > 0 ? 'promotion' : 'demotion'),
        );
        setUnsavedDelta(delta);
        return;
      }

      const oldRank = getCurrentRank();
      if (
        (oldRank === 2 && delta === 1) ||
        (oldRank === -2 && delta === -1) ||
        (((oldRank === 1 && delta === 1) || (oldRank === -1 && delta === -1)) &&
          !isTrustedToDoublePromote)
      )
        return;

      const newRank = oldRank + delta;
      setCurrentRank(newRank);

      window.clearTimeout(timer);
      setTimer(
        window.setTimeout(() => {
          setTimer(undefined);
          mutator.mutate(
            { [subjectKey]: subject, rank: newRank },
            {
              onError: res => {
                toastError(res.message);
                setCurrentRank(undefined);
              },
            },
          );
        }, 1000),
      );

      return () => window.clearTimeout(timer);
    },
    [
      getCurrentRank,
      isSignedIn,
      isTrustedToDoublePromote,
      mutator,
      setSigningIn,
      subject,
      subjectKey,
      timer,
      toastError,
    ],
  );

  useEffect(() => {
    if (unsavedDelta && isSignedIn) {
      // did they sign in after ranking?
      onRank(unsavedDelta);
      setUnsavedDelta(false);
    }
  }, [unsavedDelta, isSignedIn, onRank]);

  function onClickUp(e) {
    e.preventDefault();
    e.stopPropagation();
    onRank(1);
  }

  function onClickDown(e) {
    e.preventDefault();
    e.stopPropagation();
    onRank(-1);
  }
  return (
    <Box
      alignItems='center'
      display='flex'
      flexDirection={direction === 'vertical' ? 'column' : 'row'}
      justifyContent='space-between'
      borderRadius={'4px'}
      padding={0.5}
      marginTop={direction !== 'vertical' && 3}
      className={classes.root}
      backgroundColor={direction === 'vertical' ? theme.palette.white.main : 'transparent'}
      width={direction === 'vertical' ? '58px' : '100%'}
      sx={{ border: '1px solid rgba(0, 0, 0, 0.03);' }}
      minHeight={direction === 'vertical' && '140px'}
    >
      <Box spacing={-1} width={direction !== 'vertical' ? '33%' : '100%'}>
        <RankButton
          type={
            getCurrentRank() === 0
              ? 'deactive'
              : getCurrentRank() === 1
              ? 'up-active'
              : getCurrentRank() === 2 && 'double-up-active'
          }
          onClick={onClickUp}
          disabled={disabled}
        >
          <img
            className={disabled ? 'filter brightness-200' : ''}
            src={
              getCurrentRank() === 2
                ? ThumbUpFilledIcon
                : getCurrentRank() === 1
                ? ThumbUpWhiteIcon
                : ThumbUpIcon
            }
            alt='Thumb Up Icon'
          />
        </RankButton>
      </Box>

      {expanded && (
        <Box fontSize={12} fontWeight={600} textAlign='center' padding={2}>
          {subject.rank ? subject.rank : 0}
        </Box>
      )}
      <Box spacing={-1} width={direction !== 'vertical' ? '33%' : '100%'}>
        <RankButton
          type={
            getCurrentRank() === 0
              ? 'deactive'
              : getCurrentRank() === -2
              ? 'double-down-active'
              : getCurrentRank() === -1 && 'down-active'
          }
          onClick={onClickDown}
          disabled={disabled}
        >
          <img
            className={disabled ? 'filter brightness-200' : ''}
            alt='Thumb Down Icon'
            src={
              getCurrentRank() === -2
                ? ThumbDownFilledIcon
                : getCurrentRank() === -1
                ? ThumbDownWhiteIcon
                : ThumbDownIcon
            }
          />
        </RankButton>
      </Box>
    </Box>
  );
};

export default RankPanel;
