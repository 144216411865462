import CommentEditor from './CommentEditor';
import Comment from './Comment';
import flattenPages from '../../util/flattenPages';
import { useGetComments } from '../../services/comments';

const CommentList = ({ subject, idField }) => {
  const commentsQuery = useGetComments({ [idField]: subject.id });
  const comments = flattenPages(commentsQuery.data?.pages);

  return (
    <div className='space-y-2 > * mb-10 text-sm'>
      <CommentEditor subject={subject} idField={idField} />
      {comments.map(comment => (
        <Comment key={comment.id} subject={subject} comment={comment} />
      ))}
      {commentsQuery.hasNextPage && (
        <button
          className='text-xs mt-5 cursor-pointer text-primary mx-auto block'
          onClick={() => commentsQuery.fetchNextPage()}
        >
          load more
        </button>
      )}
    </div>
  );
};

export default CommentList;
