import { fetchClient } from 'services/api';

const apiClient = fetchClient();

export const subscribe = async ({ path, dataKey, id }) => {
  const res = await apiClient.post(path, {
    [dataKey]: id,
  });

  return res.data;
};

export const unsubscribe = async ({ path, id }) => {
  const url = `${path}/${id}`;
  await apiClient.delete(url);
};
