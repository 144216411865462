import MKTypography from '../mkcomponents/MKTypography';

const ValidationError = ({ error, ...rest }) =>
  error ? (
    <MKTypography variant='caption' color='error' {...rest}>
      {error}
    </MKTypography>
  ) : null;

export default ValidationError;
