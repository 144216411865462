import { fetchClient } from 'services/api';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';

const apiClient = fetchClient();

const keys = {
  subject: id => ['comments', { id }],
  user: 'user-comments',
};

const get = async ({ evidenceId, proposedTheoryId, pageParam }) =>
  await apiClient
    .get(
      '/comment/?page=' +
        (pageParam || 1) +
        (evidenceId
          ? '&evidenceId=' + evidenceId
          : proposedTheoryId
          ? '&proposedTheoryId=' + proposedTheoryId
          : ''),
    )
    .then(res => {
      return res.data;
    });

export const useGetComments = ({ evidenceId, proposedTheoryId }, options = {}) => {
  return useInfiniteQuery(
    evidenceId || proposedTheoryId ? keys.subject(evidenceId || proposedTheoryId) : keys.user,
    ({ pageParam }) => get({ evidenceId, proposedTheoryId, pageParam }),
    {
      getNextPageParam: lastPage => {
        return lastPage.meta.nextPage ? lastPage.meta.page + 1 : null;
      },
      ...options,
    },
  );
};

//{pages: [{data:[]}]}

const createComment = async comment =>
  await apiClient.post('/comment', comment).then(res => res.data);

export const useCreateComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ evidenceId, proposedTheoryId, title, body }) =>
      createComment({ evidenceId, proposedTheoryId, title, body }),
    {
      onSuccess: (data, variables) =>
        queryClient.setQueryData(
          keys.subject(variables.evidenceId || variables.proposedTheoryId),
          oldComments => ({
            pages: oldComments.pages.map((page, i) => ({
              ...page,
              data: [...(i === 0 ? [data.data] : []), ...page.data],
            })),
          }),
        ),
    },
  );
};

const respond = async (id, body) =>
  await apiClient.post('/comment/' + id, { body }).then(res => res.data);

export const useRespond = () => {
  const queryClient = useQueryClient();
  return useMutation(({ subjectId, id, body }) => respond(id, body), {
    onSuccess: (data, variables) =>
      queryClient.setQueryData(
        variables.subjectId ? keys.subject(variables.subjectId) : keys.user,
        oldComments => ({
          pages: oldComments.pages.map(page => ({
            ...page,
            data: page.data.map(comment =>
              comment.id === variables.id
                ? { ...comment, responses: [...comment.responses, data.data] }
                : comment,
            ),
          })),
        }),
      ),
  });
};

const resolve = async id =>
  await apiClient.patch('/comment/' + id + '/resolve').then(res => res.data);

export const useResolve = () => {
  const queryClient = useQueryClient();
  return useMutation(({ subjectId, id }) => resolve(id), {
    onSuccess: (data, variables) =>
      queryClient.setQueryData(
        variables.subjectId ? keys.subject(variables.subjectId) : keys.user,
        oldComments => ({
          pages: oldComments.pages.map(page => ({
            ...page,
            data: page.data.map(comment =>
              comment.id === variables.id ? { ...comment, ...data.data } : comment,
            ),
          })),
        }),
      ),
  });
};

const markRead = async id =>
  await apiClient.patch('/comment/' + id + '/read').then(res => res.data);

export const useMarkRead = () => {
  const queryClient = useQueryClient();
  return useMutation(({ subjectId, id }) => markRead(id), {
    onSuccess: (data, variables) =>
      queryClient.setQueryData(
        variables.subjectId ? keys.subject(variables.subjectId) : keys.user,
        oldComments => ({
          pages: oldComments.pages.map(page => ({
            ...page,
            data: page.data.map(comment =>
              comment.id === variables.id ? { ...comment, read: true } : comment,
            ),
          })),
        }),
      ),
  });
};
