import Box from '@mui/material/Box';
import { CameraIcon } from './icons';
import { v1 as uuid } from 'uuid';
import { useUpdateMe } from '../services/users';
import { useContext } from 'react';
import UiContext from '../state/UiContext';
import { useIsTrusted } from '../services/config';
import { Tooltip } from '@mui/material';

const ImageUploadButton = ({ field, onSuccess }) => {
  const id = uuid();
  const updateMe = useUpdateMe();
  const { toastError } = useContext(UiContext);
  const [isTrustedToUploadImage, trustLevelRequiredToUploadImage] = useIsTrusted('uploadImage');

  function onClick() {
    if (isTrustedToUploadImage) document.getElementById(id).click();
  }

  function onChange(e) {
    updateMe.mutate(
      { [field]: e.target.files[0] },
      { onSuccess, onError: e => toastError(e.message) },
    );
  }

  return (
    <Box className='flex w-full h-full place-content-center'>
      <input
        id={id}
        accept='image/*'
        className='imageInput'
        type='file'
        hidden
        onChange={onChange}
      />
      <Tooltip
        title={
          isTrustedToUploadImage
            ? ''
            : 'Uploading images requires Trust Level ' + trustLevelRequiredToUploadImage
        }
      >
        <img
          src={CameraIcon}
          className='w-6/12 h-6/12 cursor-pointer'
          alt='Camera Icon'
          onClick={onClick}
        />
      </Tooltip>
    </Box>
  );
};

export default ImageUploadButton;
