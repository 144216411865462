import { useInfiniteQuery } from 'react-query';
import { fetchClient } from './api';
import { staleTimes } from './query-settings';

const apiClient = fetchClient();

const keys = {
  all: ['activity'],
  user: (u, t) => ['activity', { idOrUsername: u }, { types: t }],
};

const getActivity = async (idOrUsername, types, page) => {
  let queryString = '/activity/' + (idOrUsername || '') + '?perPage=20&page=' + (page || 1) + '&';

  if (types) types.forEach((t, i) => (queryString += 'type' + i + '=' + t + '&'));

  return await apiClient.get(queryString).then(res => res.data);
};

export const useActivity = ({ idOrUsername, types, enabled }) => {
  if (!types) types = [];
  const key = keys.user(idOrUsername, types);
  const staleTime =
    (idOrUsername && staleTimes.medium) || (types && staleTimes.short) || staleTimes.blip;
  return useInfiniteQuery(key, ({ pageParam }) => getActivity(idOrUsername, types, pageParam), {
    getNextPageParam: lastPage => (lastPage.meta.nextPage ? lastPage.meta.page + 1 : null),
    staleTime: staleTime,
    enabled: enabled !== undefined ? enabled : true,
  });
};
