import { Helmet } from 'react-helmet';
import React from 'react';

const HeadTags = ({ title, description, image }) => {
  const fullTitle =
    title && (title.toLowerCase().includes('evincer') ? title : title + ' | Evincer');
  return (
    <>
      {title && (
        <Helmet>
          <title>{fullTitle}</title>
          <meta name='twitter:title' content={title.substring(0, 70)} />
          <meta property='og:title' content={fullTitle} />
        </Helmet>
      )}

      {description && (
        <Helmet>
          <meta name='description' property='og:description' content={description} />
          <meta name='twitter:description' content={description.substring(0, 200)} />
        </Helmet>
      )}

      {image && (
        <Helmet>
          <meta name='twitter:image' content={image} />
          {title && <meta name='twitter:image:alt' content={title} />}
          <meta name='image' property='og:image' content={image} />
        </Helmet>
      )}
    </>
  );
};

export default HeadTags;
