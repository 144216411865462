import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import ta from 'time-ago';
import classnames from 'classnames';
import uniqBy from 'lodash/uniqBy';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import usePushNotifications from 'hooks/use-push-notifications';
import { clearConversation, sendNewMessage, useMessages } from 'services/conversation';
import { useMe } from 'services/users';
import UiContext from 'state/UiContext';

import DefaultProfileImage from 'assets/images/UserProfile/defaultProfileImage.png';
import styles from './Messages.module.scss';

const parseMessages = messages =>
  uniqBy(
    messages.map((message, index) => {
      const nextMessage = messages[index - 1];
      return {
        ...message,
        sameSender: nextMessage?.from.id === message.from.id,
      };
    }),
    'id',
  );

const Messages = props => {
  const { conversation } = props;
  const messageListEl = useRef(null);
  const lastMessageRef = useRef(null);
  const inputRef = useRef(null);
  const { isLoading, isFetchingNextPage, data, fetchNextPage, hasNextPage } = useMessages(
    conversation.id,
  );
  const me = useMe().data?.data;

  const { toastError } = useContext(UiContext);
  const { subscribe } = usePushNotifications();

  const [messages, setMessages] = useState();
  const [message, setMessage] = useState('');

  useEffect(() => {
    clearConversation(conversation.id);
  }, [conversation]);

  useEffect(() => {
    setMessages(data);

    // Scroll to bottom the first time data is loaded
    if (data?.pages.length === 1) {
      scrollToBottom();
    } else {
      setTimeout(() => {
        if (lastMessageRef.current) {
          lastMessageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 200);
    }
  }, [data]);

  const addNewMessage = useCallback(
    message => {
      message.own = message.from.id !== conversation.recipient.id;
      messages?.pages[0]?.data.unshift(message);
      const newMessages = {
        ...messages,
      };
      setMessages(newMessages);
    },
    [conversation.recipient.id, messages],
  );

  useEffect(() => {
    const channel = subscribe(`private-conversation-${conversation.id}`);
    if (!channel) {
      return;
    }

    channel.bind('new-message', ({ message }) => {
      addNewMessage(message);
      clearConversation(conversation.id);
      scrollToBottom();
    });

    return () => {
      channel.unbind('new-message');
    };
  }, [conversation, addNewMessage, subscribe]);

  const scrollToBottom = () => {
    setTimeout(() => {
      messageListEl.current.scrollTop = messageListEl.current.scrollHeight;
      messageListEl.current.scrollIntoView({ block: 'end' });
    }, 50);
  };

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      if (!message) {
        return;
      }

      try {
        const sentMessage = await sendNewMessage({
          text: message,
          to: conversation.recipient.id,
        });

        addNewMessage(sentMessage.data);
        setMessage('');
        inputRef.current.textContent = '';

        scrollToBottom();
      } catch (e) {
        toastError(e.message);
      }
    },
    [message, conversation.recipient.id, addNewMessage, toastError],
  );

  const handleInputChange = useCallback(e => {
    setMessage(e.target.textContent);
  }, []);

  const handleInputKeydown = useCallback(
    e => {
      if (e.code === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(e);
      }
    },
    [handleSubmit],
  );

  return (
    <div className={classnames('flex flex-col justify-center h-full relative', styles.root)}>
      <div ref={messageListEl} className='p-5 w-full block overflow-y-auto h-[calc(100vh-200px)]'>
        {!data?.pages[0].length && isLoading && (
          <div className='w-full text-center p-24 text-3xl'>No messages yet</div>
        )}
        {hasNextPage && (
          <Box textAlign='center'>
            <Button onClick={() => fetchNextPage()} variant='contained' margin='auto'>
              {isFetchingNextPage ? 'Loading more...' : 'Load More'}
            </Button>
          </Box>
        )}
        <ul className='p-0 m-0 list-none pb-24'>
          {messages?.pages
            .slice(0)
            .reverse()
            .map((page, pageIndex) => {
              const parsedMessages = parseMessages([...(page?.data || []).slice(0).reverse()]);

              return parsedMessages.map((message, i) => (
                <li
                  className={classnames('ml-0 mb-5', {
                    'flex flex-col items-end': message.own,
                  })}
                  key={i}
                  {...(i === parsedMessages.length - 1 &&
                  pageIndex === 0 &&
                  messages.pages.length > 1
                    ? { ref: lastMessageRef }
                    : null)}
                >
                  <Box display={'flex'}>
                    <Box className='w-14 flex-shrink-0 mr-4'>
                      {!message.sameSender && (
                        <Box
                          className={classnames({
                            'text-right': message.own,
                          })}
                        >
                          {!message.own && (
                            <div>
                              <img
                                src={
                                  conversation.recipient.profileImage?.thumbnail?.url ||
                                  DefaultProfileImage
                                }
                                className='h-10 w-10 object-cover justify-center text-center rounded-sm'
                                alt='User Img'
                              />
                            </div>
                          )}
                        </Box>
                      )}
                    </Box>
                    <Box>
                      <div
                        className={classnames(
                          'text-sm opacity-90 font-normal whitespace-pre-wrap leading-4 break-word',
                          {
                            'py-2.5 px-5 text-white bg-primary': message.own,
                            'text-shuttleGray': !message.own,
                          },
                        )}
                        style={{
                          ...(message.own ? { borderRadius: '5px 5px 5px 0' } : null),
                        }}
                      >
                        {message.text}
                      </div>
                      <div className='font-normal leading-3 mt-1 text-shuttleGray text-xxs'>
                        {ta.ago(message.createdAt)}
                      </div>
                    </Box>
                  </Box>
                </li>
              ));
            })}
        </ul>
      </div>
      <form
        className='flex w-full absolute bottom-0 left-0 z-20 items-center p-6 bg-alabaster rounded-br'
        onSubmit={handleSubmit}
      >
        <img
          className='mr-2.5 object-cover flex-shrink-0 w-[55px] h-[55px] rounded-sm'
          src={me?.profileImage?.thumbnail?.url || DefaultProfileImage}
          alt='User Avatar Img'
        />
        <div
          ref={inputRef}
          className={classnames(
            styles.input,
            'relative flex items-center items-center whitespace-pre-wrap border-box overflow-y-scroll overflow-x-auto w-full outline-none',
          )}
          contentEditable='true'
          onKeyDown={handleInputKeydown}
          onInput={handleInputChange}
          onBlur={handleInputChange}
        />
        <button
          type='submit'
          className='bg-primary flex justify-center items-center text-white font-xl self-center border-0 w-[45px] h-[45px] rounded-[5px] cursor-pointer disabled:cursor-default disabled:opacity-70'
          disabled={!message}
        >
          <SendIcon />
        </button>
      </form>
    </div>
  );
};

export default Messages;
