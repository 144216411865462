import BalanceIcon from '@mui/icons-material/Balance';
import MuiLink from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import RecommendIcon from '@mui/icons-material/Recommend';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NotesIcon from '@mui/icons-material/Notes';
import Card from '@mui/material/Card';
import { CardContent, Skeleton, Typography } from '@mui/material';
import TheoryTitle, { ProposedTheoryTitle } from './elements/TheoryTitle';
import EvidenceTitle from './elements/EvidenceTitle';
import { makeStyles } from '@mui/styles';
import colors from 'assets/theme/base/colors';
import { CalendarIcon } from './icons';
import Box from '@mui/material/Box';
import CreatedAt from './elements/CreatedAt';
import Stack from '@mui/material/Stack';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Username from './elements/Username';
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles({
  root: {
    '& .activityCard': {
      color: colors.text.regular,
      fontSize: 20,
      fontWeight: 400,
      padding: 20,
      '& span': {
        alignSelf: 'center',
      },
      '& .icon-box': {
        background: '#F4F4F4',
        borderRadius: '6px',
        width: 65,
        height: 65,
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        marginRight: 10,

        '& img, & svg': {
          width: 20,
          marginRight: 0,
        },
      },

      '& .title': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 4,
        '-webkit-box-orient': 'vertical',
      },

      '& .desc': {
        color: colors.text.unSelected,
        marginLeft: 10,
        marginRight: 10,
      },
      '& a': {
        color: colors.text.regular + '!important',
        fontSize: '20px !important',
        fontWeight: 400 + ' !important',
        alignSelf: 'center',
      },
    },
    '& .date': {
      marginTop: 5,
      '& img': {
        width: '13px !important',
        height: '13.28px !important',
        color: colors.text.unSelected,
        alignSelf: 'center',
        marginRight: '3px',
      },
      '& span': {
        fontSize: 16,
        fontWeight: 400,
        color: colors.text.unSelected,
        alignSelf: 'center',
      },
    },
  },
});
const iconStyle = { mr: 2 };

const UserSignupActivity = () => {
  return (
    <Stack direction='row'>
      <Box className='icon-box'>
        <HowToRegIcon sx={iconStyle} />
      </Box>
      <Box alignSelf={'center'}>
        <Typography variant='h4' component='span'>
          Signed Up
        </Typography>
      </Box>
    </Stack>
  );
};

const TheoryLikelihoodActivity = ({ action }) => {
  const likelihood = action.theoryLikelihoodLog?.likelihood || action.theoryLikelihood?.likelihood;
  const answer =
    likelihood === 10
      ? 'No'
      : likelihood === 30
      ? 'Unlikely'
      : likelihood === 50
      ? 'Maybe'
      : likelihood === 70
      ? 'Likely'
      : likelihood === 90
      ? 'Yes'
      : '?';
  return (
    <Stack direction='row'>
      <Box className='icon-box'>
        <BalanceIcon sx={iconStyle} />
      </Box>
      <Box alignSelf={'center'}>
        <Typography variant='h4' component='span'>
          {answer}
        </Typography>
        {' on '}
        <TheoryTitle theory={action.theory} />
      </Box>
    </Stack>
  );
};

const ProposedTheoryRankActivity = ({ action }) => {
  return (
    <Stack direction='row'>
      <Box className='icon-box'>
        <RecommendIcon sx={iconStyle} />
      </Box>
      <Box alignSelf={'center'}>
        <Typography variant='h4' component='span'>
          {action.proposedTheoryRankLog.rank > 0 && '+'}
          {action.proposedTheoryRankLog?.rank}
        </Typography>
        {' on '}
        <ProposedTheoryTitle proposedTheory={action.proposedTheory} />
      </Box>
    </Stack>
  );
};

const ProposedTheoryActivity = ({ action }) => {
  const Icon = action.type === 'proposed-theory-edit' ? EditIcon : LightbulbIcon;
  return (
    <Stack direction='row'>
      <Box className='icon-box'>
        <Icon sx={iconStyle} />
      </Box>
      <Box alignSelf={'center'}>
        <ProposedTheoryTitle proposedTheory={action.proposedTheory} />
      </Box>
    </Stack>
  );
};

const ProposedTheoryReadActivity = ({ action }) => {
  return (
    <Stack direction='row'>
      <Box className='icon-box'>
        <VisibilityIcon sx={iconStyle} />
      </Box>
      <Box alignSelf={'center'}>
        {'Read '}
        <MuiLink to={'/proposal/' + action.proposedTheory.slug} component={RouterLink}>
          {action.proposedTheory.title}
        </MuiLink>
      </Box>
    </Stack>
  );
};

const PublishedTheoryActivity = ({ action }) => {
  return (
    <Stack direction='row'>
      <Box className='icon-box'>
        <HistoryEduIcon sx={iconStyle} />
      </Box>
      <Box alignSelf={'center'}>
        <MuiLink to={'/debate/' + action.theory.slug} variant='h4' component={RouterLink}>
          {action.theory.title}
        </MuiLink>
      </Box>
    </Stack>
  );
};

const TheoryReadActivity = ({ action }) => {
  return (
    <Stack direction='row'>
      <Box className='icon-box'>
        <VisibilityIcon sx={iconStyle} />
      </Box>
      <Box alignSelf={'center'}>
        {'Read '}
        <MuiLink to={'/debate/' + action.theory.slug} variant='h3' component={RouterLink}>
          {action.theory.title}
        </MuiLink>
      </Box>
    </Stack>
  );
};

const EvidenceActivity = ({ action }) => {
  const Icon = action.type === 'evidence-edit' ? EditIcon : AppRegistrationIcon;
  return (
    <Stack direction='row'>
      <Box className='icon-box'>
        <Icon sx={iconStyle} />
      </Box>
      <Box alignSelf={'center'}>
        <MuiLink
          to={(action.evidence.parent ? '/rebuttal/' : '/evidence/') + action.evidence.slug}
          variant='h5'
          component={RouterLink}
        >
          {action.evidence.title}
        </MuiLink>
        {action.evidence.for ? ' for ' : ' against '}
        <MuiLink to={'/debate/' + action.theory.slug} variant='h4' component={RouterLink}>
          {action.theory?.title}
        </MuiLink>
      </Box>
    </Stack>
  );
};

const EvidenceReadActivity = ({ action }) => {
  return (
    <Stack direction='row'>
      <Box className='icon-box'>
        <VisibilityIcon sx={iconStyle} />
      </Box>
      <Box alignSelf={'center'}>
        {'Read '}
        <MuiLink
          to={(action.evidence.parent ? '/rebuttal/' : '/evidence/') + action.evidence.slug}
          variant='h5'
          component={RouterLink}
        >
          {action.evidence.title}
        </MuiLink>
        {action.evidence.for ? ' for ' : ' against '}
        <MuiLink to={'/debate/' + action.theory?.slug} variant='h4' component={RouterLink}>
          {action.theory?.title}
        </MuiLink>
      </Box>
    </Stack>
  );
};

const EvidenceRankActivity = ({ action }) => {
  return (
    <Stack direction='row'>
      <Box className='icon-box'>
        <ThumbsUpDownIcon sx={iconStyle} />
      </Box>
      <Box alignSelf={'center'}>
        <Typography variant='h4' component='span'>
          {action.evidenceRankLog.rank > 0 && '+'}
          {action.evidenceRankLog.rank}
        </Typography>
        {' on '}
        <EvidenceTitle evidence={action.evidence} />
        {' in '}
        {action.theory && <TheoryTitle theory={action.theory} />}
      </Box>
    </Stack>
  );
};

const commentLink = action =>
  (action.evidence ? (action.evidence.parent ? '/rebuttal/' : '/evidence/') : '/proposal/') +
  (action.evidence?.slug || action.proposedTheory?.slug) +
  '?showComment=' +
  action.comment.id;

export const CommentActivity = ({ action }) => {
  return (
    <Stack direction='row'>
      <Box className='icon-box'>
        <NotesIcon sx={iconStyle} />
      </Box>
      <Box alignSelf='center'>
        <MuiLink component={RouterLink} to={commentLink(action)}>
          {action.comment.title}
        </MuiLink>
        {' on '}
        {action.evidence ? (
          <EvidenceTitle evidence={action.evidence} />
        ) : (
          <TheoryTitle theory={action.proposedTheory} isProposedTheory={true} />
        )}
      </Box>
    </Stack>
  );
};

export const CommentResponseActivity = ({ action }) => {
  return (
    <Stack direction='row'>
      <Box className='icon-box'>
        <NotesIcon sx={iconStyle} />
      </Box>
      <Box alignSelf='center'>
        <MuiLink component={RouterLink} to={commentLink(action)}>
          Response to
        </MuiLink>
        <h5>{action.comment.title}</h5>
        {' on '}
        {action.evidence ? (
          <EvidenceTitle evidence={action.evidence} />
        ) : (
          <TheoryTitle theory={action.proposedTheory} isProposedTheory={true} />
        )}
      </Box>
    </Stack>
  );
};

export const CommentResolveActivity = ({ action }) => {
  return (
    <Stack direction='row'>
      <Box className='icon-box'>
        <NotesIcon sx={iconStyle} />
      </Box>
      <Box alignSelf='center'>
        <MuiLink component={RouterLink} to={commentLink(action)}>
          Comment resolved:
        </MuiLink>
        <h5>{action.comment.title}</h5>
        {' on '}
        {action.evidence ? (
          <EvidenceTitle evidence={action.evidence} />
        ) : (
          <TheoryTitle theory={action.proposedTheory} isProposedTheory={true} />
        )}
      </Box>
    </Stack>
  );
};

export const CommentReadActivity = ({ action }) => {
  return (
    <Stack direction='row'>
      <Box className='icon-box'>
        <NotesIcon sx={iconStyle} />
      </Box>
      <Box alignSelf='center'>
        <MuiLink component={RouterLink} to={commentLink(action)}>
          Read comment:
        </MuiLink>
        <h5>{action.comment.title}</h5>
        {' on '}
        {action.evidence ? (
          <EvidenceTitle evidence={action.evidence} />
        ) : (
          <TheoryTitle theory={action.proposedTheory} isProposedTheory={true} />
        )}
      </Box>
    </Stack>
  );
};

export const actionComponents = {
  'user-signup': UserSignupActivity,
  'proposed-theory-activate': ProposedTheoryActivity,
  'proposed-theory-create': ProposedTheoryActivity,
  'proposed-theory-edit': ProposedTheoryActivity,
  'proposed-theory-read': ProposedTheoryReadActivity,
  'theory-likelihood-log': TheoryLikelihoodActivity,
  'theory-publish': PublishedTheoryActivity,
  'evidence-read': EvidenceReadActivity,
  'evidence-edit': EvidenceActivity,
  'evidence-publish': EvidenceActivity,
  'evidence-create': EvidenceActivity,
  'evidence-rank-log': EvidenceRankActivity,
  'theory-read': TheoryReadActivity,
  'comment-create': CommentActivity,
  'comment-respond': CommentResponseActivity,
  'comment-resolve': CommentResolveActivity,
  'comment-read': CommentReadActivity,
  'proposed-theory-rank-log': ProposedTheoryRankActivity,
};

const Date = ({ date }) => {
  return (
    <Box display='flex' className='date'>
      <img src={CalendarIcon} alt='Calendar Icon' />
      <CreatedAt createdAt={date} />
    </Box>
  );
};

export const ActivityItem = ({ children }) => (
  <Card>
    <CardContent>{children}</CardContent>
  </Card>
);

const Activity = ({ activities, showUsername, showTrust }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {activities?.map((action, i) => {
        const ActionComponent = actionComponents[action.type];
        if (ActionComponent)
          return (
            <Stack
              key={i}
              className='activityCard'
              display={'flex'}
              direction='row'
              justifyContent={'space-between'}
              sx={{
                borderBottom: activities.length !== i + 1 ? '1px solid rgba(0, 0, 0, 0.05)' : '',
              }}
            >
              <ActionComponent action={action} />
              <Stack direction='row' spacing={2}>
                {showUsername && <Username username={action.user.username} />}
                <Date date={action.createdAt} />
                {showTrust && (
                  <Typography variant='h3'>
                    {(action.trustEarned > 0 ? '+' : '') + action.trustEarned + 'T'}
                  </Typography>
                )}
              </Stack>
            </Stack>
          );
        else return console.error('No component found for ', action.type);
      })}
      {!activities && [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => <Skeleton height={105} key={i} />)}
    </Box>
  );
};
export default Activity;
