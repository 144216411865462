import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import colors from 'assets/theme/base/colors';
import { Tooltip } from '@mui/material';

const Username = ({ username, tooltip }) =>
  username ? (
    <Tooltip title={tooltip || ''} placement='right'>
      <MuiLink
        sx={{ textTransform: 'none', alignSelf: 'center', color: colors.text.unSelected }}
        variant='h6'
        component={RouterLink}
        to={'/user/' + username}
      >
        @{username}
      </MuiLink>
    </Tooltip>
  ) : null;
export default Username;
