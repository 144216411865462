import Body from '../../Body';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import colors from 'assets/theme/base/colors';
import DefaultProfileImage from 'assets/images/UserProfile/defaultProfileImage.png';
import DefaultTheoryImage from 'assets/images/Theory/2.png';
import TheoryTitle from './TheoryTitle';
import { useGetTheoryImage } from '../../../services/theories';
import Tag from '../../elements/Tag';
import TheoryButtonPanel from '../TheoryButtonPanel';
import WrapStack from 'components/wrappers/WrapStack';
import { useContext, useState } from 'react';
import { Skeleton } from '@mui/material';
import UiContext from '../../../state/UiContext';

const useStyles = makeStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, 0.05)',
    padding: 25,
    '& .img-container': {
      position: 'relative',
    },
    '& .theory-desc': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '200%',
      color: colors.text.unSelected + ' !important',
      opacity: 0.9,
      marginTop: 20,
      marginBottom: '20px',
    },
    '& .user': {
      marginTop: '20px',
      '& img': {
        width: '30px',
        height: '30px',
        borderRadius: '3px',
        marginRight: '7px',
        objectFit: 'cover',
      },
      '& .name': {
        color: colors.text.regular,
        fontSize: 16,
        fontWeight: 400,
        alignSelf: 'center',
      },
    },
    '& .theory-image': {
      width: '100%',
      aspectRatio: '3/1',
      objectFit: 'cover',
      borderRadius: '4px',
    },
  },
});
const TheoryTitleBody = ({ theory, clamped, onClickEdit }) => {
  const classes = useStyles();
  const [showTags, setShowTags] = useState(!clamped);
  const getTheoryImage = useGetTheoryImage();
  const { isXs, isSm, isMd, isLg } = useContext(UiContext);

  return (
    <Box className={classes.root}>
      {theory ? <TheoryTitle theory={theory} /> : <Skeleton height={100} />}

      <Box>
        {theory ? (
          <Box className='img-container'>
            <img
              src={getTheoryImage(theory) || DefaultTheoryImage}
              alt='Theory'
              className='theory-image'
            />
            <TheoryButtonPanel theory={theory} onClickEdit={onClickEdit} />
          </Box>
        ) : (
          <Skeleton
            variant='rounded'
            height={isXs ? 200 : isSm ? 250 : isMd ? 300 : isLg ? 350 : 400}
          />
        )}
        <Box display='flex' className='user'>
          <img
            src={theory?.user?.profileImage?.thumbnail?.url || DefaultProfileImage}
            width='30px'
            height='30px'
            alt='User Avatar'
          />
          {theory ? (
            <MuiLink variant='h6' component={RouterLink} to={'/user/' + theory.user.username}>
              <span className='name'>{theory.user.username}</span>
            </MuiLink>
          ) : (
            <Skeleton width={120} />
          )}
        </Box>
        {theory ? (
          <Box className='theory-desc'>
            <Body body={theory.body} clamped={clamped} onExpand={() => setShowTags(true)} />
          </Box>
        ) : (
          [1, 2, 3, 4].map(i => <Skeleton key={i} />)
        )}
        {showTags && (
          <WrapStack>
            {theory?.tags.map((tag, i) => (
              <Tag tag={tag} key={i} />
            ))}
          </WrapStack>
        )}
      </Box>
    </Box>
  );
};
export default TheoryTitleBody;
