import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import colors from 'assets/theme/base/colors';
import Box from '@mui/material/Box';

const Tag = ({ tag }) => (
  <MuiLink
    to={'/debates/' + tag.slug}
    variant='h5'
    color={colors.brand.default}
    component={RouterLink}
  >
    <Box sx={{ background: '#F8FCFF', borderRadius: '20px', padding: '5px 10px' }}>
      #{tag.title}
    </Box>
  </MuiLink>
);
export default Tag;
