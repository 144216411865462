import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@mui/material/Link';

const EvidenceTitle = ({ evidence }) => (
  <MuiLink
    to={(evidence.parent ? '/rebuttal/' : '/evidence/') + evidence.slug}
    variant='h5'
    component={RouterLink}
  >
    {evidence.title}
  </MuiLink>
);
export default EvidenceTitle;
