import * as React from 'react';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  breadcrumbs: {
    marginLeft: '8px',
    marginTop: '20px',
    marginBottom: '20px',
    '& a, & p': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '15px',
      display: 'flex',
      alignItems: 'center',
      color: '#5D6373',
    },
  },
  crumb: {
    borderRadius: '4px',
  },
});

export default function Breadcrumbs({ items }) {
  const classes = useStyles();

  const breadcrumbs = items.map((item, i) => (
    <Box key={i} className={classes.crumb} sx={{ backgroundColor: item.color }}>
      {item.link ? (
        <MuiLink underline='hover' color='inherit' to={item.link} component={RouterLink}>
          {item.text}
        </MuiLink>
      ) : (
        <Typography color='text.primary'>{item.text}</Typography>
      )}
    </Box>
  ));

  return (
    <Box className={classes.breadcrumbs} spacing={2}>
      <MuiBreadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </MuiBreadcrumbs>
    </Box>
  );
}
