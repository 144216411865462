import { fetchClient } from 'services/api';
import { useMutation, useQueryClient } from 'react-query';
import { userQueryKeys } from './users';
import useLocalStorageState from 'use-local-storage-state';

const apiClient = fetchClient();

export const useJwt = () => useLocalStorageState('jwt', { defaultValue: '' });

export const useSignedIn = () => useLocalStorageState('signed-in', { defaultValue: false });
// whether they are isSignedIn.  This is used to detect sign-in from another tab.

export const useSignedOut = () => useLocalStorageState('signed-out', { defaultValue: false });
// did they explicitly sign out.  This is used to prevent creating new users for signed-out visitors.

const getToken = async (params = {}) => {
  const client = apiClient;
  const url = '/auth/create-token';
  const { data: response } = await client.post(url, params);
  return response;
};

export const useGetToken = () => {
  const queryClient = useQueryClient();
  return useMutation(({ email, invite }) => getToken({ email, invite }), {
    onSuccess: (data, variables) =>
      queryClient.setQueryData(userQueryKeys.me, oldMe =>
        oldMe?.data?.completedSignup
          ? oldMe
          : { ...oldMe, data: { ...oldMe?.data, email: variables.email } },
      ),
  });
};

async function verifyToken(params) {
  return await apiClient.post('/auth/verify-token', params);
}

export const useOnSignIn = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries({ refetchType: 'none' }); // none prevents losing body editors
};

export const useVerifyToken = () => {
  const queryClient = useQueryClient();
  const [, setJwt] = useJwt();
  const [, setSignedIn] = useSignedIn();
  const [, setSignedOut] = useSignedOut();
  const onSignIn = useOnSignIn();

  return useMutation(({ token }) => verifyToken({ token: token }).then(res => res.data), {
    onSuccess: data => {
      setSignedOut(false);

      if (data.data.jwt) {
        setJwt(data.data.jwt);
      }

      onSignIn();

      queryClient.setQueryData(userQueryKeys.me, oldMe => {
        return { ...oldMe, data: data.data.user };
      });

      setSignedIn(true); // needs to happen last
    },
  });
};

export const useSignOut = () => {
  const [, setJwt] = useJwt();
  const [, setSignedOut] = useSignedOut();
  const [, setSignedIn] = useSignedIn();
  const queryClient = useQueryClient();

  return useMutation(async () => {
    setSignedOut(true); // must happen first to prevent new user from being created
    setJwt('');

    await queryClient.cancelQueries();

    return queryClient.resetQueries().then(() => {
      setSignedIn(false); // needs to happen last
    });
  });
};
