import { useNavigate, useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import {
  useGetRevisions,
  useProposedTheory,
  useRankProposedTheory,
} from '../services/proposed-theories';
import CommentList from '../components/Comments/CommentList';
import { useMe } from '../services/users';
import { sameId } from '../util/id';
import SubscriptionButton from 'components/SubscriptionButton';
import TheoryTitleBody from '../components/Theory/TheorySection/TheoryTitleBody';
import RankPanel from '../components/RankPanel';
import Breadcrumbs from '../components/Breadcrumbs';
import { useGetTheoryImage } from '../services/theories';
import HeadTags from '../components/HeadTags';
import Edited from '../components/Edited';
import { useRef, useState } from 'react';
import RevisionHistory from '../components/RevisionHistory';

const ProposedTheory = () => {
  const params = useParams();
  const proposedTheoryHook = useProposedTheory(params?.slug);
  const proposedTheory = proposedTheoryHook?.data?.data;
  const rankProposedTheoryHook = useRankProposedTheory();
  const me = useMe().data?.data;
  const navigate = useNavigate();
  const getTheoryImage = useGetTheoryImage();
  const [showRevisionHistory, setShowRevisionHistory] = useState(false);
  const revisionHistoryRef = useRef();

  function onClickEdited() {
    setShowRevisionHistory(true);
    revisionHistoryRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  const breadcrumbItems = [
    {
      link: '/',
      text: 'Home',
    },
    {
      link: '/proposals',
      text: 'Proposals',
    },
    {
      text: proposedTheory?.title,
    },
  ];
  return (
    <Stack>
      <HeadTags
        title={proposedTheory?.title}
        description={proposedTheory?.body}
        image={getTheoryImage(proposedTheory)}
      />

      <Breadcrumbs items={breadcrumbItems} />
      {proposedTheory && (
        <SubscriptionButton
          subscription={proposedTheory.subscription}
          dataId={proposedTheory.id}
          dataKey='proposedTheory'
          path='/theory-subscription'
          tooltip='Get notified about new comments and edits'
        />
      )}
      <TheoryTitleBody
        theory={proposedTheory}
        onClickEdit={
          sameId(me, proposedTheory?.user) &&
          proposedTheory.status !== 'published' &&
          (() => navigate('/proposals/new/?editId=' + proposedTheory?.id))
        }
      />
      <Edited subject={proposedTheory} onClick={onClickEdited} />

      <div className={'p-5'}>
        {proposedTheory?.status === 'active' && (
          <RankPanel
            subject={proposedTheory}
            subjectKey='proposedTheory'
            direction='horizontal'
            expanded={true}
            mutator={rankProposedTheoryHook}
          />
        )}

        <p className='mt-10'>Comments</p>

        {proposedTheory && <CommentList subject={proposedTheory} idField={'proposedTheoryId'} />}

        <RevisionHistory
          ref={revisionHistoryRef}
          show={showRevisionHistory}
          subject={proposedTheory}
          useQuery={useGetRevisions}
        />
      </div>
    </Stack>
  );
};

export default ProposedTheory;
