import React, { useContext, useRef } from 'react';
import { marked } from 'marked';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import DOMPurify from 'dompurify';
import { useCreateImage } from '../services/images';
import UiContext from '../state/UiContext';
import { useIsTrusted } from '../services/config';

const BodyEditor = ({ body, imageUploadPurpose, autoFocus, placeholder, onChange, disabled }) => {
  const mdEditor = useRef();
  const createImage = useCreateImage();
  const { toastError } = useContext(UiContext);
  const [isTrustedToUploadImage] = useIsTrusted('uploadImage');

  function _onChange({ text }) {
    onChange(text);
  }

  function onImageUpload(file) {
    return new Promise(resolve => {
      createImage.mutate(
        { image: file, purpose: imageUploadPurpose },
        {
          onSuccess: res => resolve(res.data.url),
          onError: data => toastError(data.message),
        },
      );
    });
  }

  const plugins = [
    'logger',
    'header',
    'font-bold',
    'font-italic',
    'list-unordered',
    'list-ordered',
    'block-quote',
    'table',
    'link',
    'mode-toggle',
    'full-screen',
    'tab-insert', // doesn't work?  It's supposed to insert a tab when user presses tab.
  ];

  if (imageUploadPurpose && isTrustedToUploadImage) plugins.push('image');

  return (
    <div className='markup-body'>
      <MdEditor
        ref={mdEditor}
        plugins={plugins}
        name='Body'
        placeholder={placeholder || 'Body'}
        view={{ html: false }}
        linkUrl='https://www.example.com'
        value={body}
        autoFocus={autoFocus}
        style={{
          height: (body.length > 400 && '600px') || (body.length > 200 && '300px') || '200px',
        }}
        renderHTML={text => DOMPurify.sanitize(marked.parse(text))}
        onChange={_onChange}
        onChangeTrigger='afterRender'
        imageAccept='.jpg,.png,.jpeg'
        onImageUpload={imageUploadPurpose && onImageUpload}
        readOnly={disabled}
      />
    </div>
  );
};

export default BodyEditor;
