import SearchForm from '../components/Search/SearchForm';
import SearchResults from '../components/Search/SearchResults';
import { useSearchParams } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';
import Stack from '@mui/material/Stack';

const Search = () => {
  const [params] = useSearchParams();
  const searchString = params.get('searchString');

  const breadcrumbItems = [
    {
      link: '/',
      text: 'Home',
    },
    {
      link: '/debates',
      text: 'Debates',
    },
  ];

  if (searchString) breadcrumbItems.push({ text: '"' + searchString + '"' });

  return (
    <Stack spacing={2}>
      <Breadcrumbs items={breadcrumbItems} />
      <SearchForm />
      <SearchResults />
    </Stack>
  );
};

export default Search;
