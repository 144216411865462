import BannerBackground2 from '../assets/images/background2.png';
import Heading from '../components/Banners/Heading';
import React from 'react';
import Container from '@mui/material/Container';
import { useGetComments } from '../services/comments';
import flattenPages from '../util/flattenPages';
import Comment from '../components/Comments/Comment';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';

const Comments = () => {
  const commentsQuery = useGetComments({});
  const comments = flattenPages(commentsQuery.data?.pages).map(comment => ({
    ...comment,
    link:
      (comment.evidence
        ? (comment.evidence.parent ? '/rebuttal/' : '/evidence/') + comment.evidence.slug
        : comment.proposedTheory
        ? '/proposal/' + comment.proposedTheory.slug
        : '') +
      '?showComment=' +
      comment.id,
    content: comment.evidence?.title || comment.proposedTheory?.title,
  }));

  const breadcrumbItems = [
    {
      link: '/',
      text: 'Home',
    },
    {
      link: '/comments',
      text: 'Comments',
    },
  ];

  return (
    <Container>
      <div className='flex flex-col space-y-5 > * text-sm'>
        <Heading text='Unresolved Comments' backgroundImg={BannerBackground2} />

        <Breadcrumbs items={breadcrumbItems} />

        {comments.map(comment => (
          <div key={comment.id} className='flex flex-row'>
            <Comment comment={comment} />
            <Link className='p-3 w-1/4' to={comment.link}>
              {comment.content}
            </Link>
          </div>
        ))}
        {commentsQuery.hasNextPage && (
          <button
            className='text-sm p-5 cursor-pointer text-primary mx-auto block'
            onClick={() => commentsQuery.fetchNextPage()}
          >
            load more
          </button>
        )}
      </div>
    </Container>
  );
};

export default Comments;
