import { useSearchParams } from 'react-router-dom';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { sameId } from '../../util/id';
import CommentResponseEditor from './CommentResponseEditor';
import UiContext from '../../state/UiContext';
import { useMarkRead, useResolve } from '../../services/comments';
import classnames from 'classnames';
import AuthContext from '../../state/AuthContext';
import UsernameCreatedAt from '../elements/UsernameCreatedAt';
import Username from '../elements/Username';
import DOMPurify from 'dompurify';
import { marked } from 'marked';

const Comment = ({ subject, comment }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { toastError } = useContext(UiContext);
  const [expanded, setExpanded] = useState(false);
  const { me } = useContext(AuthContext);
  const resolve = useResolve();
  const markRead = useMarkRead();
  const ref = useRef();

  const toggleExpand = useCallback(() => {
    setExpanded(!expanded);
    if (!expanded) markRead.mutate({ subjectId: subject?.id, id: comment.id });
  }, [setExpanded, expanded, comment, markRead, subject]);

  useEffect(() => {
    if (comment.id === searchParams.get('showComment')) {
      if (!expanded) toggleExpand();
      else {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
        setSearchParams({}, { replace: true });
      }
    }
  }, [ref, expanded, comment, searchParams, setSearchParams, toggleExpand]);

  const isAuthor = sameId(me, comment.author);
  const isCommenter = sameId(me, comment.user);
  const resolved =
    (isAuthor && comment.authorResolved) ||
    (isCommenter && comment.commenterResolved) ||
    (comment.authorResolved && comment.commenterResolved);

  function onResolve(e) {
    e.stopPropagation();
    resolve.mutate(
      { subjectId: subject?.id, id: comment.id },
      {
        onError: data => toastError(data.message),
      },
    );
  }

  const resolvedBy = [
    ...(comment.authorResolved ? [subject?.user?.username] : []),
    ...(comment.commenterResolved ? [comment.user?.username] : []),
  ];

  return (
    <div ref={ref} className={classnames('w-full flex flex-col p-3', { 'shadow-md': true })}>
      <UsernameCreatedAt username={comment.user.username} createdAt={comment.createdAt} compact />
      <div className='flex flex-row justify-between cursor-pointer' onClick={toggleExpand}>
        <p
          className={classnames(
            'font-bold my-auto',
            comment.read ? 'text-unselected' : 'text-regular',
          )}
        >
          {comment.title}
        </p>
        <div className='flex flex-row space-x-2 > *'>
          {(resolved || isAuthor || isCommenter) && (
            <button
              className={
                'px-3 text-xs border-2 rounded ' + (!resolved ? 'text-secondary' : 'text-gray-500')
              }
              onClick={onResolve}
            >
              {resolved ? 'Resolved' : 'Resolve'}
            </button>
          )}
          {
            <span className='material-icons-round'>
              {expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </span>
          }
        </div>
      </div>
      {expanded && (
        <div className='pl-4 mt-3 flex flex-col space-y-3 > *'>
          {comment.responses?.map(response => (
            <div key={response.id}>
              <UsernameCreatedAt
                username={response.user.username}
                createdAt={response.createdAt}
                compact
              />
              <div
                className={'break-word markup-body'}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(marked.parse(response.body)),
                }}
              />
            </div>
          ))}

          {resolvedBy.length === 1 && (
            <div>
              <Username username={resolvedBy[0]} /> marked as resolved
            </div>
          )}
          {resolvedBy.length === 2 && (
            <div>
              Resolved by <Username username={resolvedBy[0]} /> and{' '}
              <Username username={resolvedBy[1]} />
            </div>
          )}

          <CommentResponseEditor subject={subject} comment={comment} />
        </div>
      )}
    </div>
  );
};

export default Comment;
