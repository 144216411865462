import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import colors from 'assets/theme/base/colors';
import LikelihoodPanel from '../../LikelihoodPanel';
import TheoryTitleBody from './TheoryTitleBody';
import TagEditor from './TagEditor';
import { useUpdateTags, useUpdateTheory } from 'services/theories';
import { useContext, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import AuthContext from '../../../state/AuthContext';

const useStyles = makeStyles({
  stepProgressBar: {
    border: '1px solid rgba(0, 0, 0, 0.05)',
    padding: 25,
    marginTop: '40px !important',
    '& .title': {
      color: colors.text.regular,
      opacity: '0.9',
      fontWeight: 500,
      fontSize: 24,
    },
  },
});
const TheorySection = ({ theory, clamped }) => {
  const classes = useStyles();
  const updateTags = useUpdateTags();
  const updateTheory = useUpdateTheory();
  const [editing, setEditing] = useState(false);
  const imageInput = useRef();
  const { isAdmin } = useContext(AuthContext);

  function onSetChosenTags(tags) {
    updateTags.mutate({ theory, tags });
  }

  function onChooseImage(e) {
    updateTheory.mutate({ id: theory.id, image: e.target.files[0] });
  }

  return (
    <div>
      <TheoryTitleBody
        theory={theory}
        clamped={clamped}
        onClickEdit={isAdmin && (() => setEditing(true))}
      />
      {editing && (
        <>
          <TagEditor chosenTags={theory?.tags || []} setChosenTags={onSetChosenTags} />
          <input
            ref={imageInput}
            accept='image/*'
            className='imageInput'
            type='file'
            hidden
            onChange={onChooseImage}
          />
          <Button onClick={() => imageInput.current.click()}>Image</Button>
        </>
      )}
      <Box className={classes.stepProgressBar}>
        <Grid container spacing={3} alignItems='center'>
          <Grid item xs={12} md={4}>
            <Box className='title'>Your Answer</Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <LikelihoodPanel theory={theory} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default TheorySection;
