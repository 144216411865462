import Box from '@mui/material/Box';
import { Tooltip } from '@mui/material';
import EvidenceEditor from '../Evidence/EvidenceEditor';
import IconButton from '@mui/material/IconButton';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import Evidences from '../Evidence/Evidences';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { useContext, useState } from 'react';
import { useIsTrusted } from '../../services/config';
import UiContext from '../../state/UiContext';

const EvidenceColumn = ({
  theory,
  collapsed,
  side,
  onClickRestore,
  onChildExpand,
  collapseEvents,
}) => {
  const { clipboardType, paste } = useContext(UiContext);
  const [pastedEvidence, setPastedEvidence] = useState();
  const [isTrustedToPostEvidence, trustLevelRequiredToPostEvidence] = useIsTrusted('postEvidence1');
  const [isTrustedToPaste] = useIsTrusted('copyPaste');

  function onClickPaste() {
    setPastedEvidence(paste());
  }

  function _onChildExpand(branch) {
    if (branch?.editor?.level === 0) setPastedEvidence(undefined);
    onChildExpand(branch);
  }

  if (collapsed)
    return (
      <Container sx={{ padding: 0, zIndex: 2 }}>
        <IconButton size='large' sx={{ padding: 0 }} onClick={onClickRestore}>
          {side === 'against' ? <ArrowCircleRightOutlinedIcon /> : <ArrowCircleLeftOutlinedIcon />}
        </IconButton>
      </Container>
    );

  return (
    <Stack spacing={2}>
      <Box className='text-unselected mt-6 text-2xl font-semibold text-center'>
        {side === 'for' ? '"Yes" Evidence' : '"No" Evidence'}
      </Box>

      <Stack direction='row' spacing={1}>
        <Tooltip
          title={
            isTrustedToPostEvidence
              ? ''
              : 'Posting Evidence requires Trust Level ' + trustLevelRequiredToPostEvidence
          }
        >
          <span style={{ width: '100%' }}>
            <EvidenceEditor
              initialTitle={pastedEvidence?.title}
              initialBody={pastedEvidence?.body}
              theory={theory}
              level={1}
              isFor={side === 'for'}
              disabled={!isTrustedToPostEvidence}
              onExpand={_onChildExpand}
              collapseEvents={collapseEvents}
            />
          </span>
        </Tooltip>
        {clipboardType === 'evidence' && isTrustedToPaste && (
          <Tooltip title='Paste'>
            <IconButton onClick={onClickPaste}>
              <ContentPasteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      <Evidences
        isFor={side === 'for'}
        theory={theory}
        onExpand={_onChildExpand}
        level={1}
        collapseEvents={collapseEvents}
      />
    </Stack>
  );
};

export default EvidenceColumn;
