import { useActivity } from '../../services/activity';
import Activity from '../../components/Activity';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { useState } from 'react';
import flattenPages from '../../util/flattenPages';

const AdminActivity = () => {
  const types = [
    'theory-publish',
    'proposed-theory-activate',
    'proposed-theory-edit',
    'evidence-publish',
    'evidence-edit',
    'theory-likelihood-log',
    'evidence-rank-log',
    'proposed-theory-rank-log',
    'user-invite',
    'user-signup',
    'user-trust-adjust',
    'comment-create',
    'comment-respond',
  ];

  const [showTrust, setShowTrust] = useState(false);
  const activityQuery = useActivity({ types });
  const activity = flattenPages(activityQuery.data?.pages);

  return (
    <Container>
      <Button variant={showTrust ? 'primary' : ''} onClick={() => setShowTrust(!showTrust)}>
        Show Trust
      </Button>
      <Activity activities={activity} showUsername={true} showTrust={showTrust} />
      {activityQuery.hasNextPage && (
        <Button onClick={() => activityQuery.fetchNextPage()}>Load more</Button>
      )}
    </Container>
  );
};

export default AdminActivity;
