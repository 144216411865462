import Box from '@mui/material/Box';

import DefaultProfileImage from 'assets/images/UserProfile/defaultProfileImage.png';
import { ThumbUpIcon, ThumbDownIcon, ChatIcon } from '../icons';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Grid from '@mui/material/Grid';
import CreatedAt from '../elements/CreatedAt';
import { Link as RouterLink } from 'react-router-dom';
import styles from './EvidenceCard.module.scss';
import Body from '../Body';
import Stack from '@mui/material/Stack';

const EvidenceCard = ({ evidence, theory, user, date }) => {
  return (
    <Box className={styles.root}>
      <Stack direction='row' className={styles.title}>
        <RouterLink to={'/user/' + user.username} className='flex-shrink-0'>
          <img src={user.profileImage?.thumbnail?.url || DefaultProfileImage} alt='User Avatar' />
        </RouterLink>
        <Box>
          <RouterLink to={(evidence.parent ? '/rebuttal/' : '/evidence/') + evidence.slug}>
            <p className={styles.name}>{evidence.title}</p>
          </RouterLink>
        </Box>

        <Box display='flex' className={styles.date}>
          <AccessTimeOutlinedIcon />
          <CreatedAt createdAt={date} />
        </Box>
      </Stack>
      <Box className={styles.body}>
        <Body body={evidence.body} />
      </Box>
      <p className={styles.theory}>
        <span>{evidence.for ? 'For ' : 'Against '}</span>
        <RouterLink to={'/debate/' + theory.slug}>
          <span>{theory.title}</span>
        </RouterLink>
      </p>
      <Box className={styles.counterNumber}>
        <Grid container spacing={1}>
          <Grid item xs={4} lg={4} md={4}>
            <Box display='flex' justifyContent='center'>
              <img src={ChatIcon} alt='Chat Icon' />
              <span>{evidence.rebuttalCount}</span>
            </Box>
          </Grid>
          <Grid item xs={4} lg={4} md={4}>
            <Box display='flex' justifyContent='center'>
              <img src={ThumbUpIcon} alt='Thumb Up Icon' />
              <span>{evidence.promotes}</span>
            </Box>
          </Grid>
          <Grid item xs={4} lg={4} md={4}>
            <Box display='flex' justifyContent='center'>
              <img src={ThumbDownIcon} alt='Thumb Down Icon' />
              <span>{evidence.demotes}</span>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EvidenceCard;
