import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Box from '@mui/material/Box';
import MuiLink from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import colors from '../../assets/theme/base/colors';
import Icon from '@mui/material/Icon';
import typography from '../../assets/theme/base/typography';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import AuthContext from '../../state/AuthContext';
import { useContext } from 'react';

const useStyles = makeStyles({
  navMenuDropDownItem: {
    ...typography.navRegular,
    cursor: 'pointer',
    transition: 'all 300ms linear',
    textDecoration: 'none',
    '&:hover': {
      color: colors.brand.default + ' !important',
      '& .MuiIcon-root': {
        color: colors.brand.default + ' !important',
      },
      '& span': {
        color: colors.brand.default + ' !important',
      },
    },
  },
});

const Dropdown = ({ items, parentRef, onClickMenuItem, onReachBottom }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { me } = useContext(AuthContext);

  function onScroll(e) {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && onReachBottom) onReachBottom();
  }

  // Render the routes on the dropdown menu
  const renderedRoutes = items?.map((item, i) => {
    if (item.hidden) return null;

    const linkComponent = {
      component: MuiLink,
      href: item.href,
      target: '_blank',
      rel: 'noreferrer',
    };
    const routeComponent = {
      component: RouterLink,
      to: item.route,
    };
    return (
      <Box
        key={i}
        onClick={() => (item.onClickMenuItem || onClickMenuItem)(item.name)}
        {...(item.route ? routeComponent : linkComponent)}
        display='flex'
        alignItems='center'
        variant='button'
        textTransform='capitalize'
        minWidth={'12rem'}
        fontWeight={'regular'}
        pt={0.625}
        pb={1.625}
        px={2}
        className={item.className || classes.navMenuDropDownItem}
        justifyContent={'space-between'}
        backgroundColor={item.name === 'Sign Out' ? '#FFF7F7' : ''}
        borderRadius='4px'
        style={{
          borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.05)' : '',
          backgroundColor: item.backgroundColor,
        }}
      >
        <Box display={'flex'} sx={{ marginTop: 1, marginBottom: 1 }}>
          {item.icon && (
            <Box
              lineHeight={1}
              color={
                item.name === 'Sign Out'
                  ? theme.palette.tertiary.focus
                  : theme.palette.text.unSelected
              }
              display={'flex'}
              style={{ alignSelf: 'center', '& *': { verticalAlign: 'middle' } }}
            >
              <Icon sx={{ marginRight: 1, alignSelf: 'center' }}>{item.icon}</Icon>
            </Box>
          )}
          <span
            style={{
              color:
                item.name === 'Sign Out'
                  ? theme.palette.tertiary.focus
                  : theme.palette.text.unSelected,
            }}
          >
            {item.name}
          </span>
          {item.name === 'Messages' && !!me?.dms && (
            <Box
              sx={{
                width: 20,
                height: 20,
                backgroundColor: theme.palette.tertiary.focus,
                color: theme.palette.white.main,
                fontSize: 10,
                borderRadius: 100,
                marginLeft: 1,
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'center',
                justifyContent: 'center',
              }}
            >
              {me?.dms}
            </Box>
          )}
        </Box>

        {item.collapse && (
          <Icon
            fontSize='small'
            sx={{
              fontWeight: 'normal',
              verticalAlign: 'middle',
              mr: -0.5,
            }}
          >
            keyboard_arrow_right
          </Icon>
        )}
      </Box>
    );
  });

  return (
    <Popper
      anchorEl={parentRef}
      popperRef={null}
      open={Boolean(parentRef)}
      placement='bottom-start'
      transition
      style={{ zIndex: 10 }}
      modifiers={[
        {
          name: 'arrow',
          enabled: true,
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          sx={{
            transformOrigin: 'left top',
            borderRadius: 1,
            background: ({ palette: { white } }) => white.main,
          }}
        >
          <div className='rounded drop-shadow-lg'>
            <div
              onScroll={onScroll}
              className='overflow-auto bg-gray-50'
              style={{ maxHeight: '90vh' }}
            >
              {renderedRoutes}
            </div>
          </div>
        </Grow>
      )}
    </Popper>
  );
};

export default Dropdown;
