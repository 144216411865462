import Grid from '@mui/material/Grid';
import { useProposedTheories, useRankProposedTheory } from '../services/proposed-theories';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Breadcrumbs from '../components/Breadcrumbs';
import Heading from '../components/Banners/Heading';
import BannerImg from 'assets/images/bannerImg2.png';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import TheoryCard from '../components/Cards/TheoryCard';
import flattenPages from '../util/flattenPages';
import HeadTags from '../components/HeadTags';

function ProposedTheories() {
  const { isFetchingNextPage, isLoading, data, fetchNextPage, hasNextPage } = useProposedTheories();
  const rankTheory = useRankProposedTheory();
  const navigate = useNavigate();

  // const onClickEdit = pt => () => navigate('/proposed-theories/new?editId='+pt.id);
  const breadcrumbItems = [
    {
      link: '/',
      text: 'Home',
    },
    {
      text: 'Proposals',
    },
  ];

  const pTheories = isLoading ? [null, null, null, null, null, null] : flattenPages(data.pages);

  return (
    <Box>
      <HeadTags title='Proposals' />
      <Heading text='Proposals' backgroundImg={BannerImg} />
      <Breadcrumbs items={breadcrumbItems} />
      <div className='flex flex-row justify-between mx-7 space-x-2 > *'>
        <p className='my-4'>Promote the questions you'd like to see debated</p>
        <Button sx={{ my: 2 }} variant='contained' onClick={() => navigate('/proposals/new')}>
          New Proposal
        </Button>
      </div>
      <Grid container spacing={0}>
        {pTheories.map((pt, i) => (
          <Grid item xs={12} md={6} lg={4} xl={3} key={i}>
            <TheoryCard theory={pt} user={pt?.user} date={pt?.createdAt} mutator={rankTheory} />
          </Grid>
        ))}
      </Grid>

      <Stack direction='row' justifyContent='flex-end' marginBottom={4}>
        {hasNextPage && (
          <Button onClick={() => fetchNextPage()} variant='contained' margin='auto'>
            {isFetchingNextPage ? 'Loading more...' : 'Load More'}
          </Button>
        )}
        <Button sx={{ mx: 4 }} variant='contained' onClick={() => navigate('/proposals/new')}>
          New Proposal
        </Button>
      </Stack>
    </Box>
  );
}
export default ProposedTheories;
