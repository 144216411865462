import EvidenceCard from '../Cards/EvidenceCard';
import TheoryCard from '../Cards/TheoryCard';
import { useSearch } from '../../services/search';
import { useSearchParams } from 'react-router-dom';
import SearchResultSection from './SearchResultSection';
import { Typography } from '@mui/material';

const SearchResults = () => {
  const [params] = useSearchParams();
  const searchQuery = useSearch({ searchString: params.get('searchString') });
  const searchResults = searchQuery.data?.data;

  if (searchQuery.isLoading) return <Typography>Loading...</Typography>;
  else
    return (
      <>
        <SearchResultSection title='Evidence:'>
          {searchResults?.evidence?.map(evidence => (
            <EvidenceCard
              key={evidence.slug}
              evidence={evidence}
              theory={evidence.theory}
              user={evidence.user}
              date={evidence.publishedAt}
            />
          ))}
        </SearchResultSection>
        <SearchResultSection title='Debates:'>
          {searchResults?.theory?.map(theory => (
            <TheoryCard
              key={theory.slug}
              theory={theory}
              user={theory.user}
              date={theory.createdAt}
            />
          ))}
        </SearchResultSection>
      </>
    );
};

export default SearchResults;
