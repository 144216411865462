/**
 =========================================================
 * Material Kit 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import FooterImg from 'assets/images/footer.png';
import { makeStyles } from '@mui/styles';
import colors from 'assets/theme/base/colors';
import Subscribe from './Subscribe';
import PiLogo from 'assets/images/PI Logo Wht On Trns 1K.PNG';

const useStyles = makeStyles({
  footerStyle: {
    backgroundImage: `url(${FooterImg})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'black',
    backgroundSize: 'cover',
  },
  link: {
    color: colors.white.main,
    marginLeft: 20,
    marginRight: 20,
  },
  social: {
    color: colors.white.main,
    border: '1px solid white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    width: 40,
    height: 40,
  },
});

function CenteredFooter({ routes, socials }) {
  const classes = useStyles();

  const renderedRoutes = routes.map(link => (
    <Box
      key={link.name}
      component={RouterLink}
      to={link.href}
      style={{ textDecoration: 'none' }}
      variant='body2'
      className={classes.link}
      fontWeight='regular'
    >
      {link.name}
    </Box>
  ));

  const renderedSocials = socials.map(social => (
    <Box
      key={social.link}
      component={MuiLink}
      href={social.link}
      variant='body2'
      className={classes.social}
      fontWeight='regular'
    >
      {social.icon}
    </Box>
  ));

  return (
    <Box component='footer' pb={6} className={classes.footerStyle}>
      <Subscribe />

      <Grid container justifyContent='center' pt={6}>
        <Grid item xs={10} lg={8}>
          <Stack
            direction='row'
            flexWrap='wrap'
            justifyContent='center'
            spacing={{ xs: 2, lg: 3, xl: 6 }}
            mb={3}
          >
            {renderedRoutes}
          </Stack>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Stack display='flex' direction='row' justifyContent='center' spacing={3} mt={1} mb={3}>
            {renderedSocials}
          </Stack>
        </Grid>
      </Grid>
      <Stack
        display='flex'
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={3}
        mt={1}
        mb={3}
      >
        <p className='text-white text-sm'>© 2022 Evincer </p>
        <p className='text-white text-sm'>
          Copywriting & Consulting{' '}
          <a href='http://paradisointernational.com/' target='_blank' rel='noreferrer'>
            <img alt='PI logo' src={PiLogo} className='w-24 h-24 inline' />
          </a>
        </p>
      </Stack>
    </Box>
  );
}

// Setting default values for the props of CenteredFooter
// Typechecking props for the CenteredFooter
CenteredFooter.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  socials: PropTypes.arrayOf(PropTypes.object),
};

export default CenteredFooter;
