import { useEffect, useRef } from 'react';
import { useSignedIn } from '../services/auth';

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useDetectSignIn = ({ onSignIn, onSignOut }) => {
  const [signedIn] = useSignedIn();
  const wasSignedIn = usePrevious(!!signedIn);

  useEffect(() => {
    if (wasSignedIn === undefined) return;

    if (wasSignedIn === false && signedIn === true) onSignIn && onSignIn();

    if (wasSignedIn === true && signedIn === false) onSignOut && onSignOut();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasSignedIn, signedIn]);
};

export default useDetectSignIn;
