import Divider from '@mui/material/Divider';

import SlideDownModal from '../wrappers/SlideDownModal';
import useDetectSignIn from 'hooks/use-detect-sign-in';
import SignInForm from './SignInForm';

export default function SignInModal({ open, title, onSuccess, onClose }) {
  useDetectSignIn({
    onSignIn: onSuccess,
  });

  return (
    <SlideDownModal open={open} title={title} onClose={onClose}>
      <Divider sx={{ my: 0 }} />

      <SignInForm compact prompt=' ' newVisitor />
    </SlideDownModal>
  );
}
