import { fetchClient } from 'services/api';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { staleTimes } from './query-settings';
import api from 'config/api.js';
import id from '../util/id';
import _ from 'lodash';

const apiClient = fetchClient();

export const keys = {
  one: slug => ['evidence', { slug: slug }],
  allOnes: () => ['evidence'],
  children: (theory, parent, status) => ['evidences', { theory, parent: parent || null, status }],
  grandchildren: (theory, grandparent) => [
    'evidences',
    { theory, grandparent: grandparent || null },
  ],
  allChildren: () => ['evidences'],
  latest: () => ['latestEvidence'],
  revisions: id => ['evidenceRevisions', id],
};

function updateCacheWithRecord(queryClient, evidence, data) {
  // if this evidence was fetched specifically, update that cache
  if (queryClient.getQueryData(keys.one(evidence.slug)))
    queryClient.setQueryData(keys.one(evidence.slug), oldEvidence => {
      return { data: { ...oldEvidence.data, ...data } };
    });

  // if this evidence is in a parent's children list, update that list
  const parentId = id(evidence.parent) || null;
  const theoryId = id(evidence.theory);
  if (queryClient.getQueryData(keys.children(theoryId, parentId)))
    queryClient.setQueryData(keys.children(theoryId, parentId), oldEvidences => {
      return {
        ...oldEvidences,
        data: oldEvidences.data.map(e => (e.id === evidence.id ? { ...e, ...data } : e)),
      };
    });
}

const getEvidence = async slug => apiClient.get('/evidence/' + slug).then(res => res.data);

export const useEvidence = slug => {
  const queryClient = useQueryClient();
  return useQuery(keys.one(slug), () => getEvidence(slug), {
    staleTime: staleTimes.medium,
    onSuccess: data => {
      // create separate cache for child evidences so that we can update evidence uniformly
      queryClient.setQueryData(keys.children(id(data.data.theory), data.data.id), {
        data: data.data.children,
      });
    },
  });
};

const getAllEvidences = async (page = 0) =>
  apiClient.get('/evidence/?perPage=10&page=' + page).then(res => res.data);

export const useAllEvidences = () =>
  useInfiniteQuery(keys.latest(), ({ pageParam }) => getAllEvidences(pageParam), {
    getNextPageParam: lastPage =>
      lastPage.meta.pageCount > lastPage.meta.page ? lastPage.meta.page + 1 : null,
    staleTime: staleTimes.long,
  });

const getEvidenceChildren = async ({ status, theoryId, parentId, grandparentId }) => {
  const parentParam = parentId !== undefined ? '&parent=' + parentId : '';
  const grandparentParam = grandparentId !== undefined ? '&grandParent=' + grandparentId : '';
  const statusParam = status ? '&status=' + status : '';
  return apiClient
    .get('/evidence/?theory=' + theoryId + parentParam + grandparentParam + statusParam)
    .then(res => res.data);
};

export const useEvidenceChildren = function ({ theoryId, parentId, status }, options = {}) {
  return useQuery(
    keys.children(theoryId, parentId, status),
    () => getEvidenceChildren({ theoryId, parentId, status }),
    {
      staleTime: staleTimes.medium,
      ...options,
    },
  );
};

export const useEvidenceGrandchildren = function ({ theoryId, grandparentId }, options = {}) {
  const queryClient = useQueryClient();
  return useQuery(
    keys.grandchildren(theoryId, grandparentId),
    () => getEvidenceChildren({ theoryId, grandparentId }),
    {
      staleTime: staleTimes.medium,
      ...options,
      onSuccess: data => {
        _.uniq(data.data.map(e => e.parent)).forEach(id => {
          queryClient.setQueryData(keys.children(theoryId, id), {
            data: data.data.filter(e => e.parent === id),
          });
        });
      },
    },
  );
};

export const useHasChildren = function ({ theoryId, parentId }) {
  return !!useQueryClient().getQueryData(keys.children(theoryId, parentId))?.data?.length;
};

function delayedPublish(queryClient) {
  setTimeout(() => {
    queryClient.setQueriesData(keys.allChildren(), oldEvidences =>
      oldEvidences
        ? {
            data: oldEvidences.data.map(e =>
              e.status === 'pending' ? { ...e, status: 'published' } : e,
            ),
          }
        : undefined,
    );
  }, api.evidencePublicationTime);
}

const submitEvidence = async evidence => await apiClient.post('/evidence', evidence);

export const useSubmitEvidence = function () {
  const queryClient = useQueryClient();
  return useMutation(
    ({ title, body, theoryId, parent, isFor }) =>
      submitEvidence({
        title,
        body,
        theory: theoryId,
        parent,
        for: isFor,
      }).then(res => res.data),
    {
      onSuccess: (data, variables) => {
        // add new evidence to query data
        const me = queryClient.getQueryData('me')?.data;
        queryClient.setQueryData(
          keys.children(variables.theoryId, id(variables.parent) || null),
          oldEvidences => {
            return {
              data: [
                { ...data.data, user: me ? me : data.data.user, read: true },
                ...(oldEvidences?.data || []),
              ],
            };
          },
        );

        delayedPublish(queryClient);
      },
    },
  );
};

const startUpdateEvidence = async id => await apiClient.patch('evidence/' + id + '/start');

export const useStartUpdateEvidence = function () {
  const queryClient = useQueryClient();
  return useMutation(evidence => startUpdateEvidence(evidence.id).then(res => res.data), {
    onSuccess: (data, evidence) =>
      updateCacheWithRecord(queryClient, evidence, {
        status: 'editing',
      }),
  });
};

const updateEvidence = async ({ id, title, body }) =>
  await apiClient.patch('evidence/' + id, { title, body });

export const useUpdateEvidence = function () {
  const queryClient = useQueryClient();
  return useMutation(
    ({ evidence, title, body }) =>
      updateEvidence({ id: evidence.id, title, body }).then(res => res.data),
    {
      onSuccess: (data, variables) => {
        updateCacheWithRecord(queryClient, variables.evidence, data.data);
        queryClient.invalidateQueries(keys.revisions(variables.evidence.id));
        delayedPublish(queryClient);
      },
    },
  );
};

// Created to look through cached data for parent.  Not needed right now as long as the mutation user sends parent data.
// but might come in handy at some point
/*function findEvidenceParent(queryClient, id) {
  // look through all the cached evidence data to find the parent id
  queryClient.getQueriesData(['evidence']).reduce((prev, curr) => prev ? prev :
    curr.reduce(data => (prev, curr) => (prev || curr.data.id !== id) ? prev : curr.data.parent), null)
}*/

const markEvidenceRead = async id =>
  await apiClient.post('/evidence/' + id + '/read').then(res => res.data);

export const useMarkEvidenceRead = () => {
  const queryClient = useQueryClient();
  return useMutation(({ evidence }) => markEvidenceRead(evidence.id), {
    onSuccess: (data, variables) =>
      updateCacheWithRecord(queryClient, variables.evidence, data.data),
  });
};

const rankEvidence = async (id, { rank }) =>
  await apiClient.post('/evidence/' + id + '/rank', { rank }).then(res => res.data);

export const useRankEvidence = () => {
  const queryClient = useQueryClient();
  return useMutation(({ evidence, rank }) => rankEvidence(evidence.id, { rank: rank }), {
    onSuccess: (data, variables) =>
      updateCacheWithRecord(queryClient, variables.evidence, {
        userRank: variables.rank,
        ...data.data,
      }),
  });
};

const deleteEvidence = async id => await apiClient.delete('/evidence/' + id);

export const useDeleteEvidence = () => {
  const queryClient = useQueryClient();
  return useMutation(evidence => deleteEvidence(evidence.id).then(res => res.data), {
    onSuccess: (data, variables) => {
      const evidenceKey = keys.one(variables.slug);
      if (queryClient.getQueryData(evidenceKey)) queryClient.removeQueries(evidenceKey);

      queryClient.setQueriesData(keys.allChildren(), oldEvidence => {
        if (!oldEvidence) return undefined;
        return { data: oldEvidence.data.filter(e => e.id !== variables.id) };
      });
    },
  });
};

const unpublishEvidence = async (id, reason) =>
  await apiClient.patch('/evidence/' + id + '/unpublish/', { reason });

export const useUnpublishEvidence = () => {
  const queryClient = useQueryClient();
  return useMutation(
    evidence => unpublishEvidence(evidence.id, evidence.archiveReason).then(res => res.data),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(keys.allChildren());
        queryClient.invalidateQueries(keys.one(variables.slug));
      },
    },
  );
};

const republishEvidence = async id =>
  await apiClient.post('/evidence/' + id + '/publish').then(res => res.data);

export const useRepublishEvidence = () => {
  const queryClient = useQueryClient();
  return useMutation(evidence => republishEvidence(evidence.id), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(keys.allChildren());
      queryClient.invalidateQueries(keys.one(variables.slug));
    },
  });
};

const getRevisions = async id =>
  await apiClient.get('/evidence/revisions/' + id).then(res => res.data);

export const useGetRevisions = (id, options) =>
  useQuery(keys.revisions(id), () => getRevisions(id), options);
