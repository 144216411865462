import { fetchClient } from 'services/api';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { staleTimes } from './query-settings';
import { useJwt } from './auth';

const apiClient = fetchClient();

export const userQueryKeys = {
  me: 'me',
  users: 'users',
};

const getMe = async () => {
  // unfortunately, we can't trust react-query to not call this function even when enabled===false
  const signedOut = JSON.parse(localStorage.getItem('signed-out'));
  return signedOut ? Promise.resolve() : await apiClient.get('/me').then(res => res.data);
};

export const useMe = () => {
  const [, setJwt] = useJwt();

  const options = {
    staleTime: staleTimes.medium,
    onSuccess: data => {
      data?.data?.jwt && setJwt(data.data.jwt);
    },
  };
  return useQuery(userQueryKeys.me, getMe, options);
};

// todo
const getUser = async username => {
  return await apiClient.get('/user/' + username).then(res => res.data);
};

export const useGetUser = ({ username }) =>
  useQuery(
    username ? ['user', { username: username }] : userQueryKeys.me,
    () => (username ? getUser(username) : getMe()),
    { staleTime: staleTimes.medium },
  );

const updateMe = async user => {
  const formData = new FormData();
  ['username', 'displayName', 'bio', 'profileImage', 'backgroundImage'].forEach(
    field => user[field] && formData.append(field, user[field]),
  );

  return await apiClient
    .patch('/me', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(res => res.data);
};

export const useUpdateMe = () => {
  const queryClient = useQueryClient();
  return useMutation(updateMe, {
    onSuccess: data => {
      queryClient.setQueryData(userQueryKeys.me, data);
    },
  });
};

export const useUpdateUserQueryData = (key = 'me') => {
  const queryClient = useQueryClient();
  return newUserData =>
    queryClient.getQueryData(key) &&
    queryClient.setQueryData(key, oldUser => ({
      ...oldUser,
      data: {
        ...oldUser.data,
        ...newUserData,
      },
    }));
};

const getUsers = async (page = 0) =>
  await apiClient.get('/user?page=' + page).then(res => res.data);

export const useGetUsers = () => {
  return useInfiniteQuery(userQueryKeys.users, ({ pageParam }) => getUsers(pageParam), {
    getNextPageParam: lastPage =>
      lastPage.meta.pageCount <= lastPage.meta.page ? null : lastPage.meta.page + 1,
  });
};

const adjustTrust = async ({ id, delta }) =>
  await apiClient.patch('/user/' + id + '/trust/?delta=' + delta).then(res => res.data);

export const useAdjustTrust = () => {
  const queryClient = useQueryClient();
  return useMutation(adjustTrust, {
    onSuccess: () => queryClient.invalidateQueries(userQueryKeys.users),
  });
};
