import { useContext, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { Paper, Tooltip, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

import { useGetUser, useUpdateMe } from '../services/users';
import AuthContext from '../state/AuthContext';
import UiContext from '../state/UiContext';

import Activity from '../components/Activity';
import SubscriptionButton from 'components/SubscriptionButton';
import { useActivity } from '../services/activity';
import ui from '../config/ui';
import { validateUsername } from '../services/validators';
import ValidationError from '../components/ValidationError';
import Button from '@mui/material/Button';
import { useIsTrusted } from '../services/config';
import TooltipButton from '../components/TooltipButton';
import CredentialModals from '../components/CredentialModals';
import Heading from '../components/Banners/Heading';
import Box from '@mui/material/Box';
import BannerImg from 'assets/images/bannerImg2.png';
import Grid from '@mui/material/Grid';
import colors from 'assets/theme/base/colors';
import { ChatWhiteIcon, EditIcon, VerticalThreeDotIcon } from '../components/icons';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import DefaultProfileImage from 'assets/images/UserProfile/defaultProfileImage.png';
import TrustLevel from '../components/elements/TrustLevel';
import ImageUploadButton from '../components/ImageUploadButton';
import styles from './UserProfile.module.scss';
import classnames from 'classnames';
import flattenPages from '../util/flattenPages';
import HeadTags from '../components/HeadTags';

const filters = {
  All: [],
  Debates: ['proposed-theory-activate', 'theory-publish'],
  Arguments: ['evidence-publish'],
  Comments: ['comment-create', 'comment-respond'],
  Opinions: ['theory-likelihood-log'],
};
filters.All = [...filters.Debates, ...filters.Arguments, ...filters.Comments, ...filters.Opinions];

const UserProfile = () => {
  const { me, isSignedIn, isNamed } = useContext(AuthContext);
  const { toastSuccess, toastError } = useContext(UiContext);

  const params = useParams();
  const user = useGetUser({ username: params.username }).data?.data;
  const [filter, setFilter] = useState('All');
  const activityQuery = useActivity({
    idOrUsername: params.username || me?.username || me?.id,
    types: filters[filter],
    enabled: !!(params.username || me?.username || me?.id),
  });
  const activity = flattenPages(activityQuery.data?.pages);
  const [isEditing, setIsEditing] = useState(false);
  const [newUsername, setNewUsername] = useState();
  const [newDisplayName, setNewDisplayName] = useState();
  const [newBio, setNewBio] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  const updateMe = useUpdateMe();
  const [isTrustedToInitiateConversation, trustLevelRequiredToInitiateConversation] =
    useIsTrusted('initiateConversation');
  const [isTrustedToChooseUsername, trustLevelRequiredToChooseUsername] =
    useIsTrusted('chooseUsername');
  const [isTrustedToSubscribeToUser, trustLevelRequiredToSubscribeToUser] =
    useIsTrusted('subscribeToUser');
  const navigate = useNavigate();
  const [credentialsOpen, setCredentialsOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState();
  const [popOpen, setPopOpen] = useState(false);

  const handlePopClick = event => {
    setAnchorEl(event.currentTarget);
    setPopOpen(prev => !prev);
  };
  const handleClickEditBtn = () => {
    setPopOpen(false);
    setIsEditing(true);
    setNewBio(user.bio);
    setNewUsername(user.username);
    setNewDisplayName(user.displayName);
  };
  const id = anchorEl ? 'popover' : undefined;
  const isMe = !params?.username || me?.username === params.username;

  function onUpdateUser() {
    for (const k in validationErrors) {
      if (validationErrors[k]) {
        toastError(validationErrors[k]);
        return;
      }
    }

    if (isMe) {
      updateMe.mutate(
        { username: newUsername, displayName: newDisplayName, bio: newBio },
        {
          onSuccess: res => {
            toastSuccess(res.message);
            setIsEditing(false);
          },
          onError: res => {
            toastError(res.message);
          },
        },
      );
    }
  }

  function onCancelUpdate() {
    setNewUsername(undefined);
    setNewBio(undefined);
    setNewDisplayName(undefined);
    setValidationErrors({});
    setIsEditing(false);
  }

  function onChange(fn) {
    return e => fn(e.target.value);
  }

  function onChangeUsername(e) {
    const name = e.target.value;
    setValidationErrors({
      ...validationErrors,
      username: validateUsername(name),
    });
    setNewUsername(name);
  }

  function onClickSendMessage() {
    if (isNamed && isSignedIn) navigate(`/messages/${user.username}`);
    else setCredentialsOpen(true);
  }

  function onKeyUp(e) {
    e.key === 'Enter' && onUpdateUser();
  }

  const editingUsername = isMe && user && !user.username;
  return (
    <Grid container spacing={1} className={styles.root}>
      <Grid item xs={12} lg={isEditing ? 12 : 4} md={isEditing ? 12 : 6}>
        <Box>
          <HeadTags title={user?.username ? '@' + user?.username : 'User Profile'} />

          <Box className={styles.banner}>
            <Heading text={''} backgroundImg={user?.backgroundImage?.url || BannerImg} />
            <Box
              className={styles.userAvatar}
              sx={{
                backgroundImage: `url(${user?.profileImage?.url || DefaultProfileImage})`,
                backgroundSize: 'cover',
                '&:hover': {
                  boxShadow: isMe ? 'inset 0 0 0 2000px rgba(0, 6, 29, 0.75)' : '',
                },
              }}
            >
              {isMe && (
                <Box className={styles.overlay}>
                  <ImageUploadButton field='profileImage' onSuccess={() => setIsEditing(false)} />
                </Box>
              )}
            </Box>
            {!isEditing && isMe && (
              <Box
                className={classnames(styles.editIcon, styles.icon)}
                aria-describedby={id}
                variant='contained'
                onClick={handlePopClick}
              >
                <img src={VerticalThreeDotIcon} alt='Edit Icon' />
              </Box>
            )}
            <Popper
              className={styles.popWindow}
              open={popOpen}
              anchorEl={anchorEl}
              placement='bottom-end'
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper className={styles.popContent} onClick={handleClickEditBtn}>
                    <img src={EditIcon} alt='Edit Icon' />
                    <span>Edit</span>
                  </Paper>
                </Fade>
              )}
            </Popper>
            {isEditing && (
              <ImageUploadButton field='backgroundImage' onSuccess={() => setIsEditing(false)} />
            )}
          </Box>
          <Box spacing={2} alignItems='center'>
            {isEditing ? (
              <Box className={styles['edit-user-detail']}>
                <Typography
                  color={colors.text.regular}
                  fontWeight={600}
                  fontSize={28}
                  lineHeight='34px'
                  marginBottom={8}
                >
                  {' '}
                  Profile Info
                </Typography>
                <Box marginTop={3}>
                  <Typography className={styles.title}>Display Name</Typography>
                  <TextField
                    inputProps={{ maxLength: ui.displayNameLength }}
                    autoFocus
                    value={newDisplayName}
                    onChange={onChange(setNewDisplayName)}
                    onKeyUp={onKeyUp}
                    width={'100%'}
                  />
                </Box>
                {editingUsername && (
                  <Box marginTop={3}>
                    <Typography className={styles.title}>User Name</Typography>
                    <Tooltip
                      title={
                        isTrustedToChooseUsername
                          ? ''
                          : 'Choosing a @username requires Trust Level ' +
                            trustLevelRequiredToChooseUsername
                      }
                    >
                      <span>
                        <TextField
                          fullWidth
                          disabled={!isTrustedToChooseUsername}
                          inputProps={{ maxLength: ui.usernameLength }}
                          value={newUsername}
                          onChange={onChangeUsername}
                          onKeyUp={onKeyUp}
                        />
                      </span>
                    </Tooltip>
                  </Box>
                )}
                <ValidationError error={validationErrors.username} />
                <Box marginTop={3}>
                  <Typography className={styles.title}>Bio</Typography>
                  <TextField
                    autoFocus
                    fullWidth
                    value={newBio}
                    onChange={onChange(setNewBio)}
                    onKeyUp={onKeyUp}
                  />
                </Box>
                {isEditing && (
                  <Box direction='row' marginTop={4}>
                    <Button variant='containedTertiary' onClick={onCancelUpdate}>
                      Cancel
                    </Button>
                    <Button style={{ marginLeft: 20 }} variant='contained' onClick={onUpdateUser}>
                      Update
                    </Button>
                  </Box>
                )}
              </Box>
            ) : (
              <Box className={styles['user-detail']}>
                <Typography variant='h5' className={styles.displayName}>
                  {user?.displayName}
                </Typography>
                <Typography variant='h6' className={styles.username}>
                  @{user?.username}
                </Typography>
                <Box marginTop={2} marginBottom={2}>
                  {isMe ? (
                    <RouterLink to='/subscription-settings'>
                      <Button color='secondary' variant='contained' sx={{ fontWeight: '500' }}>
                        Subscription Settings
                      </Button>
                    </RouterLink>
                  ) : (
                    <TooltipButton
                      title={
                        isTrustedToInitiateConversation
                          ? ''
                          : 'Sending messages requires Trust Level ' +
                            trustLevelRequiredToInitiateConversation
                      }
                      disabled={!isTrustedToInitiateConversation}
                      onClick={onClickSendMessage}
                      variant='contained'
                    >
                      <Box variant='contained' className={styles.messageBtn}>
                        <img src={ChatWhiteIcon} alt='Chat Icon' />
                        <Box>Message</Box>
                      </Box>
                    </TooltipButton>
                  )}
                </Box>

                <Box className={styles.numbers} display='flex' justifyContent={'space-around'}>
                  <Box sx={{ cursor: 'pointer' }} onClick={() => navigate('/help?topic=evidence')}>
                    <Box className={styles.number}>{user?.evidenceCount || 0}</Box>
                    <Box className={styles.desc}>Evidence</Box>
                  </Box>
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate('/help?topic=answers-and-sways')}
                  >
                    <Box className={styles.number}>{user?.swayCount}</Box>
                    <Box className={styles.desc}>Sways</Box>
                  </Box>
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate(isMe ? '/trust-levels' : '/help?topic=trust-levels')}
                  >
                    <Box className={styles.number}>{user?.trustLevel}</Box>
                    <Box className={styles.desc}>Trust Level</Box>
                    <TrustLevel user={user} />
                  </Box>
                </Box>

                <Box className={styles.userBio} marginTop={5}>
                  {user?.bio}
                </Box>
              </Box>
            )}
          </Box>

          {!isMe && user && (
            <SubscriptionButton
              subscription={user.subscription}
              dataId={user.id}
              dataKey='user'
              path='/user-subscription'
              disabled={!isTrustedToSubscribeToUser}
              tooltip={
                isTrustedToSubscribeToUser
                  ? 'Get notified when @' + user.username + ' posts new content'
                  : 'Subscribing to a user requires Trust Level ' +
                    trustLevelRequiredToSubscribeToUser
              }
            />
          )}

          <CredentialModals
            open={credentialsOpen}
            requireUsername={true}
            signinPrompt={'Please enter your email before sending a message'}
            usernamePrompt={'Please choose a username before sending a message'}
            onSuccess={onClickSendMessage}
            onClose={() => setCredentialsOpen(false)}
          />
        </Box>
      </Grid>
      {!isEditing && (
        <Grid item xs={12} lg={8} md={6}>
          <Box width='100%'>
            <Stack spacing={3} padding={3}>
              <Grid container spacing={1} className={styles.root}>
                <Grid item xs={12} lg={3} md={12} alignSelf='center'>
                  <Typography
                    fontSize='32px'
                    fontWeight={600}
                    color={colors.text.regular}
                    style={{ opacity: 0.9 }}
                  >
                    My Activity
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={9} md={12} textAlign='right'>
                  {Object.keys(filters).map(f => (
                    <Button
                      className={classnames({ [styles.selected]: filter === f }, styles.filterBtn)}
                      disabled={filter === f}
                      key={f}
                      onClick={() => setFilter(f)}
                    >
                      {f}
                      <span className={styles.dot}></span>
                    </Button>
                  ))}
                </Grid>
              </Grid>
              <Box className={styles['activity-section']}>
                <Activity activities={activity} />
              </Box>
            </Stack>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default UserProfile;
