/**
 =========================================================
 * Material Kit 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useEffect } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';

// @mui material mkcomponents
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Material Kit 2 PRO React themes
import theme from 'assets/theme';

import './App.css';

import Layout from './components/Layout';
import { healthCheck } from 'services/health';
import { UiContextProvider } from './state/UiContext';
import { PushNotificationProvider } from 'contexts/PushNotificationContext';
import { AuthContextProvider } from './state/AuthContext';
import HeadTags from './components/HeadTags';

export default function App() {
  useEffect(() => {
    healthCheck();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <HeadTags
        title='Evincer'
        description='A platform for sharing the best empirical evidence for controversial questions without suppressing either side'
      />
      <UiContextProvider>
        <AuthContextProvider>
          <PushNotificationProvider>
            <ReactQueryDevtools initialIsOpen={false} />
            <CssBaseline />
            <Layout />
          </PushNotificationProvider>
        </AuthContextProvider>
      </UiContextProvider>
    </ThemeProvider>
  );
}
