import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { useContext } from 'react';
import UiContext from '../../state/UiContext';
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    margin: '8px',
  },
});

const TheoryButtonPanel = ({ theory, onClickEdit }) => {
  const classes = useStyles();
  const { toastSuccess } = useContext(UiContext);

  function onClickShare() {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      const path = (theory.status === 'published' ? '/debate/' : '/proposal/') + theory.slug;
      navigator.clipboard.writeText(window.location.origin + path);
      toastSuccess('URL copied to clipboard');
    }
  }

  return (
    <Box className={classes.root}>
      {onClickEdit && (
        <IconButton onClick={() => onClickEdit()} size='large' color='white'>
          <EditIcon />
        </IconButton>
      )}
      <IconButton aria-label='copy' size='large' color={'white'} onClick={onClickShare}>
        <ShareIcon />
      </IconButton>
    </Box>
  );
};

export default TheoryButtonPanel;
