import _ from 'lodash';
import Grid from '@mui/material/Grid';
import { useEffect, useRef, useState } from 'react';
import { Typography, useTheme } from '@mui/material';
import colors from '../../../assets/theme/base/colors';
import Box from '@mui/material/Box';
import ClearIcon from '@mui/icons-material/Clear';
import InputBase from '@mui/material/InputBase';
import ui from '../../../config/ui';
import Button from '@mui/material/Button';
import { useTags } from '../../../services/tags';
import { makeStyles } from '@mui/styles';
import WrapStack from 'components/wrappers/WrapStack';
import Stack from '@mui/material/Stack';

const useStyles = makeStyles({
  tagInput: {
    background: '#F4F4F4',
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderRadius: '162px',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& input': {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '16px',
      color: colors.text.unSelected,
    },
  },
  chosenTags: {
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  chosenTag: {
    background: '#F8FCFF',
    backdropFilter: 'blur(20px)',
    borderRadius: '41px',
    padding: '5px 20px',
    fontSize: 16,
    fontWeight: 400,
    position: 'relative',
    marginRight: 10,
    cursor: 'pointer',
    '& span': {
      position: 'absolute',
      top: '10px',
      right: '-5px',
      backgroundColor: 'rgba(255, 63, 63, 0.4)',
      padding: '3px',
      borderRadius: 100,
      display: 'flex',
      width: 15,
      height: 15,
      '& svg': {
        alignSelf: 'center',
        color: colors.tertiary.focus,
        width: 9,
      },
    },
  },
});

const TagEditor = ({ chosenTags, setChosenTags }) => {
  const theme = useTheme();
  const classes = useStyles();

  const [newTag, setNewTag] = useState('');
  const [suggestedTags, setSuggestedTags] = useState([]);
  const canonicalTags = useTags().data;
  const tagInput = useRef();

  useEffect(() => {
    setNewTag('');
    setSuggestedTags('');
  }, [chosenTags]);

  useEffect(() => {
    // update suggested tags based on typed tag
    if (newTag.length >= 2)
      setSuggestedTags(
        canonicalTags.data.filter(
          canonicalTag =>
            canonicalTag.slug.toLowerCase().includes(newTag.toLowerCase()) && // it matches this tag
            !chosenTags.some(chosenTag => chosenTag.slug === canonicalTag.slug), // and this tag isn't already chosen
        ),
      );
    else setSuggestedTags([]);
  }, [newTag, canonicalTags, chosenTags]);

  function addNewTag() {
    if (newTag) {
      const trimmedTag = _.kebabCase(newTag.replace(/[^A-Za-z0-9-_]/g, ''));
      if (chosenTags.some(chosenTag => chosenTag.title.toLowerCase() === trimmedTag)) return; // already chose this tag
      const canonicalTag = canonicalTags.data.find(tag => tag.title.toLowerCase() === trimmedTag);
      setChosenTags([...chosenTags, canonicalTag || { title: trimmedTag }]);
      setNewTag('');
      tagInput.current?.getElementsByTagName('input')[0]?.focus();
    }
  }

  function addSuggestedTag(tag) {
    return () => {
      setChosenTags([...chosenTags, tag]);
      tagInput.current?.getElementsByTagName('input')[0]?.focus();
    };
  }

  function onNewTagKeyUp(e) {
    if (e.key === 'Enter') addNewTag();
  }

  function clearChosenTag(title) {
    return () => setChosenTags(chosenTags.filter(chosenTag => chosenTag.title !== title));
  }

  return (
    <Grid container item>
      <Grid item xs={12}>
        <Stack spacing={2}>
          <Typography
            color={colors.text.unSelected + ' !important'}
            marginTop={3}
            style={{ ...theme.typography.tableData }}
          >
            Tags:
          </Typography>

          <WrapStack>
            {chosenTags.map((tag, i) => (
              <Box key={i} className={classes.chosenTag} onClick={clearChosenTag(tag.title)}>
                #{tag.slug || tag.title}
                <span>
                  <ClearIcon />
                </span>
              </Box>
            ))}
          </WrapStack>

          <Box className={classes.tagInput}>
            <InputBase
              ref={tagInput}
              fullWidth
              variant='outlined'
              label='Tags'
              inputProps={{ maxLength: ui.tagLength }}
              value={newTag}
              onKeyUp={onNewTagKeyUp}
              onChange={e => setNewTag(e.target.value)}
            />
            <Button variant='contained' sx={{ borderRadius: 30, fontSize: 16 }} onClick={addNewTag}>
              Add Tag
            </Button>
          </Box>
        </Stack>
      </Grid>
      {/*List of suggested tags*/}
      <Grid item container xs={12} sx={{ marginTop: 2 }}>
        {suggestedTags.map(tag => (
          <Grid item key={tag.id} xs={4}>
            <Button
              variant='contained'
              sx={{ borderRadius: 30, fontSize: 16 }}
              onClick={addSuggestedTag(tag)}
            >
              #{tag.slug}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default TagEditor;
