import Grid from '@mui/material/Grid';
import {
  useAdminProposedTheories,
  usePublishProposedTheory,
} from '../../services/proposed-theories';
import Card from '@mui/material/Card';
import { useContext } from 'react';
import UiContext from '../../state/UiContext';
import MKButton from '../../mkcomponents/MKButton';
import { Link as RouterLink } from 'react-router-dom';
import Username from '../../components/elements/Username';
import Stack from '@mui/material/Stack';

function ProposedTheories() {
  const proposedTheoriesHook = useAdminProposedTheories();
  const proposedTheories = proposedTheoriesHook.data?.data;
  const publishTheory = usePublishProposedTheory();
  const { toastSuccess, toastError } = useContext(UiContext);

  function onClickPublish(pt) {
    return () =>
      window.confirm('Publish ' + pt.title + '?') &&
      publishTheory.mutate(pt.id, {
        onSuccess: res => toastSuccess(res.message),
        onError: res => toastError(res.message),
      });
  }

  return (
    <Grid container spacing={5}>
      {proposedTheories?.map(pt => (
        <Grid item xs={10} key={pt.id}>
          <Card p={5}>
            <Grid container>
              <Grid item container xs={3}>
                <Grid item xs={12}>
                  Rank: {pt.rank}
                </Grid>
                <Grid item xs={12}>
                  {pt.status}
                </Grid>
                <Grid item xs={12}>
                  {pt.status === 'active' && (
                    <MKButton variant='outlined' color='secondary' onClick={onClickPublish(pt)}>
                      Publish
                    </MKButton>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={9}>
                <Stack>
                  <RouterLink to={'/proposal/' + pt?.slug}>{pt.title}</RouterLink>
                  <Username username={pt.user.username} />
                </Stack>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default ProposedTheories;
