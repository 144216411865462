import MuiButton from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';

const Button = withStyles({
  root: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
})(MuiButton);

const TooltipButton = ({ title, disabled, onClick, component, ...other }) => {
  const adjustedProps = {
    disabled: disabled,
    component: disabled ? 'div' : undefined,
    onClick: disabled ? undefined : onClick,
  };
  const Component = component || Button; // IconButtons don't work yet
  return (
    <Tooltip title={title}>
      <Component {...other} {...adjustedProps} />
    </Tooltip>
  );
};

export default TooltipButton;
