import Stack from '@mui/material/Stack';
import { useCallback, useContext, useEffect, useState } from 'react';
import UiContext from '../state/UiContext';
import { useUpdateLikelihood } from '../services/theories';
import SlideDownModal from './wrappers/SlideDownModal';
import MKTypography from '../mkcomponents/MKTypography';
import { useIsTrusted } from '../services/config';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import colors from 'assets/theme/base/colors';
const useStyles = makeStyles({
  progressBar: {
    width: '100%',
    height: '25px',
    border: '1px solid rgba(0, 0, 0, 0.05)',
    background: '#FBFBFB',
    borderRadius: 16,
    display: 'flex',
    marginTop: 40,
    '& .steps': {
      width: '27%',
      height: 25,
      position: 'relative',
      marginLeft: '-2%',
      '&.active': {
        border: '1px solid ' + colors.white.main,
        '& .percent': {
          color: colors.warning.main,
        },
      },
      '& .percent': {
        position: 'absolute',
        top: -40,
        right: '-6%',
        cursor: 'pointer',
        fontSize: 15,
        fontWeight: 500,
        color: colors.text.unSelected,
      },
      '& .check': {
        background: colors.white.main,
        border: '2px solid ' + colors.warning.main,
        borderRadius: 100,
        width: 35,
        height: 35,
        position: 'absolute',
        top: -5,
        right: -20,
        zIndex: 10,
        display: 'flex',
        '& div': {
          width: 17,
          height: 17,
          backgroundColor: colors.warning.main,
          borderRadius: 100,
          alignSelf: 'center',
          justifyContent: 'center',
          margin: 'auto',
        },
      },
    },
    '& .step0': {
      marginLeft: '0% !important',
      '& .check': {
        right: '-20px !important',
      },
      '& .percent': {
        left: '-10px !important',
      },
      width: '0 !important',
    },

    '& .step1': {
      '&.active': {
        backgroundColor: colors.darkYellow.first,
        zIndex: 4,
        borderRadius: 16,
      },
      '&.deactive': {
        borderRight: '2px solid rgba(0, 0, 0, 0.15)',
        borderRadius: 0,
      },
    },
    '& .step2': {
      '&.active': {
        backgroundColor: colors.darkYellow.second,
        borderRadius: 16,
        zIndex: 3,
      },
      '&.deactive': {
        borderRight: '2px solid rgba(0, 0, 0, 0.15)',
      },
    },
    '& .step3': {
      '&.active': {
        backgroundColor: colors.darkYellow.third,
        borderRadius: 16,
        zIndex: 2,
      },
      '&.deactive': {
        borderRight: '2px solid rgba(0, 0, 0, 0.15)',
      },
    },
    '& .step4': {
      '&.active': {
        backgroundColor: colors.darkYellow.fourth,
        borderRadius: 16,
        zIndex: 1,
      },
      '&.deactive': {},
    },
  },
});
const LikelihoodPanel = ({ theory, onSuccess }) => {
  const [currentLikelihood, setCurrentLikelihood] = useState();
  const [rankTimer, setRankTimer] = useState();
  const { toastError } = useContext(UiContext);
  const updateLikelihood = useUpdateLikelihood();
  const [isTrusted, trustLevelRequired] = useIsTrusted('changeLikelihood');
  const disabled = theory?.userLikelihood && !isTrusted;
  const classes = useStyles();
  const [showChangeRequirement, setShowChangeRequirement] = useState(false);

  function getcurrentLikelihood() {
    return currentLikelihood !== undefined ? currentLikelihood : theory?.userLikelihood;
  }

  useEffect(
    () => () => {
      window.clearTimeout(rankTimer);
    },
    [rankTimer],
  );

  const onChangeLikelihood = useCallback(
    lh => () => {
      if (disabled) return setShowChangeRequirement(true);

      setCurrentLikelihood(lh);
      window.clearTimeout(rankTimer);
      setRankTimer(
        window.setTimeout(() => {
          updateLikelihood.mutate(
            { theory: theory, likelihood: lh },
            {
              onSuccess,
              onError: res => {
                toastError(res.message);
              },
            },
          );
        }, 1000),
      );
    },
    [disabled, rankTimer, theory, onSuccess, toastError, updateLikelihood],
  );

  const Step = ({ p, index }) => {
    return (
      <Box className={`${getcurrentLikelihood() >= p ? 'active' : 'deactive'} steps step${index}`}>
        <Box className='percent' onClick={onChangeLikelihood(p)}>
          {(p === 10 && 'No') ||
            (p === 30 && 'Unlikely') ||
            (p === 50 && 'Maybe') ||
            (p === 70 && 'Likely') ||
            (p === 90 && 'Yes')}
        </Box>
        {getcurrentLikelihood() === p && (
          <Box className='check'>
            <Box></Box>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Box className={classes.progressBar}>
        {[10, 30, 50, 70, 90].map((p, i) => (
          <Step p={p} index={i} key={i} disabled={disabled} />
        ))}
      </Box>
      {disabled && showChangeRequirement && (
        <MKTypography color='info' variant='body2' sx={{ marginTop: 2 }}>
          Changing your answer requires Trust Level {trustLevelRequired}
        </MKTypography>
      )}
    </Box>
  );
};

export default LikelihoodPanel;

export const LikelihoodModal = ({ open, prompt, theory, onSuccess, onClose }) => {
  return (
    <SlideDownModal open={open} title={prompt} onClose={onClose}>
      <Stack sx={{ px: 6, py: 5 }} spacing={3}>
        <LikelihoodPanel theory={theory} onSuccess={onSuccess} />
        <MKTypography variant='body2'>
          Please make your best estimate given what you know now. You can change this later. We use
          this to better understand each other's stance on the question and track how strong the
          evidence is by awarding Sways to authors of influential evidence.
        </MKTypography>
      </Stack>
    </SlideDownModal>
  );
};
