import { forwardRef, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import Body from './Body';
import Username from './elements/Username';
import ta from 'time-ago';

const RevisionHistory = forwardRef(({ show, subject, useQuery }, ref) => {
  const revisionQuery = useQuery(subject?.id, { enabled: show });
  const [selectedRevisionId, setCurrentRevisionId] = useState(null);

  const revisions = useMemo(
    () => [
      ...(subject
        ? [
            {
              id: 'current',
              title: subject?.title,
              body: subject?.body,
              editedAt: subject?.editedAt || subject?.createdAt,
              user: { username: subject?.editedByUsername || subject?.user.username },
            },
          ]
        : []),
      ...(revisionQuery.data?.data ? revisionQuery.data?.data : []),
    ],
    [revisionQuery, subject],
  );

  const selectedRevision = revisions.find(r => r.id === selectedRevisionId);

  useEffect(
    () => !selectedRevisionId && revisions.length && setCurrentRevisionId(revisions[0].id),
    [selectedRevisionId, revisions, setCurrentRevisionId],
  );

  // keyboard
  useEffect(() => {
    function onKey(e) {
      const curr = revisions.findIndex(r => r.id === selectedRevisionId);
      if (!~curr) return;

      if (e.key === 'ArrowUp' && curr > 0) {
        e.preventDefault();
        setCurrentRevisionId(revisions[curr - 1].id);
      }

      if (e.key === 'ArrowDown' && curr < revisions.length - 1) {
        e.preventDefault();
        setCurrentRevisionId(revisions[curr + 1].id);
      }
    }
    document.addEventListener('keydown', onKey, false);
    return () => document.removeEventListener('keydown', onKey);
  }, [selectedRevisionId, revisions]);

  return (
    <div ref={ref} className='flex flex-row-reverse'>
      {show && (
        <>
          <div className={classnames('flex flex-col w-44 flex-shrink-0 items-center')}>
            <p className='text-xl font-semibold mb-3'>Revision History</p>

            {revisions.map(revision => (
              <div
                key={revision.id}
                className={classnames(
                  'border cursor-pointer px-2 py-4  text-sm w-full',
                  revision?.id === selectedRevisionId && 'bg-primary bg-opacity-30',
                )}
                onClick={() => setCurrentRevisionId(revision.id)}
              >
                <p>{ta.ago(revision.editedAt)}</p>
                {revision.id === 'current' && <p className='text-xs italic'>Current Version</p>}
                <p>@{revision.user.username}</p>
              </div>
            ))}
          </div>

          <div className='flex flex-col mr-3 w-full items-start'>
            <p className='text-regular font-semibold mb-3 text-xl'>
              {selectedRevision?.title || 'Click a revision to view ->'}
            </p>
            <div className='bg-gray-100 w-full'>
              <Body body={selectedRevision?.body} />
            </div>
            <div className='flex flex-row space-x-2 mt-2'>
              <Username username={selectedRevision?.user.username} />
              {selectedRevision && (
                <p className='text-xs'>
                  {new Date(selectedRevision?.editedAt).toLocaleDateString('en-us', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })}
                </p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
});

export default RevisionHistory;
