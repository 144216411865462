import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import Box from '@mui/material/Box';
import ui from 'config/ui';
import { useStartUpdateEvidence, useSubmitEvidence, useUpdateEvidence } from 'services/evidence';
import UiContext from 'state/UiContext';

import CredentialModals from 'components/CredentialModals';
import BodyEditor from 'components/BodyEditor';
import colors from '../../assets/theme/base/colors';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { LikelihoodModal } from '../LikelihoodPanel';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import AuthContext from '../../state/AuthContext';
import { usePrompt } from '../../hooks/react-router-dom-extension';

const useStyles = makeStyles({
  root: {
    '& .suggestBox': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'left',
      alignItems: 'baseline',
      backgroundColor: colors.white.main,
      padding: 10,
      borderRadius: 4,
      width: '100%',
      marginTop: 5,
      '& button': {
        textTransform: 'none',
        p: 0,
        color: colors.text.regular,
        opacity: 0.9,
        fontSize: 16,
        '&:hover': {
          color: colors.brand.default,
          opacity: 0.9,
        },
      },
    },
    '& .titleBox': {
      position: 'relative',
      width: '100%',
      '& fieldset': {
        border: 'none',
      },
      '& img': {
        position: 'absolute',
        right: 10,
        top: 23,
      },
    },
    '& .selectBox': {
      width: '100%',
      margin: 0,
      '& .MuiOutlinedInput-root': {
        color: colors.text.unSelected,
        opacity: 0.9,
        fontSize: 13,
        fontWeight: 500,
        height: 50,
        backgroundColor: colors.white.main,
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.green.main + ' !important',
          },
        },
        '& svg': {
          display: 'block !important',
          width: 30,
          height: 30,
          top: 'auto',
        },
        '& fieldset': {},
      },
    },
  },
});
const EvidenceEditor = ({
  initialTitle,
  initialBody,
  updatingEvidence,
  theory,
  level,
  disabled,
  parentEvidence,
  isFor,
  onExpand,
  onSuccess,
  onCancel,
  collapseEvents,
}) => {
  const classes = useStyles();
  const [editing, setEditing] = useState(!!parentEvidence || !!updatingEvidence);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [title, setTitle] = useState(initialTitle || '');
  const [body, setBody] = useState(initialBody || '');
  const submitEvidence = useSubmitEvidence();
  const startUpdateEvidence = useStartUpdateEvidence();
  const updateEvidence = useUpdateEvidence();
  const [credentialsOpen, setCredentialsOpen] = useState(false);
  const [likelihoodModalOpen, setLikelihoodModalOpen] = useState(false);
  const { isSignedIn, isNamed } = useContext(AuthContext);
  const { toastSuccess, toastError } = useContext(UiContext);
  const prompt = updatingEvidence
    ? 'Discard unsaved evidence changes?'
    : 'Discard unsaved evidence?';
  usePrompt(prompt, unsavedChanges);

  function expand(editing) {
    onExpand && onExpand({ editor: { level: editing ? level : 0 } });
    setEditing(editing);
  }

  useEffect(() => {
    if (updatingEvidence) startUpdateEvidence.mutate(updatingEvidence);

    if (editing) expand(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle parent changing setting initialTitle/body while mounted
  useEffect(() => {
    if (!title && !body) {
      if (initialTitle) setTitle(initialTitle);
      if (initialBody) setBody(initialBody);
      if (initialTitle || initialBody) setEditing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTitle, initialBody]);

  const loaded = useRef(false); // is this already loaded into the DOM?
  useEffect(() => {
    if (editing && loaded.current && !unsavedChanges) _cancel(); // we got a 'collapse' event

    loaded.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapseEvents]);

  const heading = useMemo(
    () =>
      parentEvidence
        ? 'Rebuttal to "' + parentEvidence.title + '"'
        : 'Evidence ' + (isFor ? 'for "' : 'against "') + theory.title + '"',
    [parentEvidence, isFor, theory],
  );

  const label = useMemo(
    () => (editing ? 'Title of ' + heading : 'Add ' + heading),
    [editing, heading],
  );

  function onClickTitle() {
    if (disabled) return;
    if (!editing) expand(true);
  }

  function onChangeTitle(e) {
    setUnsavedChanges(true);
    setTitle(e.target.value);
  }

  function onChangeBody(text) {
    setUnsavedChanges(true);
    setBody(text);
  }

  function checkRequirementsAndSubmit() {
    if (!isSignedIn || !isNamed) setCredentialsOpen(true);
    else if (!theory.userLikelihood) setLikelihoodModalOpen(true);
    else submit();
  }

  function onCredentialsSuccess() {
    if (!theory.userLikelihood) setLikelihoodModalOpen(true);
  }

  function onLikelihoodSuccess() {
    setLikelihoodModalOpen(false);
    submit();
  }

  function submit() {
    setCredentialsOpen(false);
    const options = {
      onSuccess: res => {
        setUnsavedChanges(false);
        toastSuccess(res.message);
        setTitle('');
        setBody('');
        expand(false);
        onSuccess && onSuccess();
      },
      onError: res => toastError(res.message),
    };

    if (updatingEvidence)
      updateEvidence.mutate({ evidence: updatingEvidence, title, body }, options);
    else
      submitEvidence.mutate(
        {
          title,
          body,
          theoryId: theory.id,
          parent: parentEvidence?.id,
          isFor,
        },
        options,
      );
  }

  function _cancel() {
    if (updatingEvidence?.status === 'editing')
      updateEvidence.mutate({ evidence: updatingEvidence, title: undefined, body: undefined }); // back to 'pending' status
    expand(false);
    setTitle('');
    setBody('');
    setUnsavedChanges(false);
    onCancel && onCancel();
  }

  function onClickCancel() {
    if (editing && (!unsavedChanges || window.confirm(prompt))) _cancel();
  }

  return (
    <>
      <Accordion
        className={classes.root}
        sx={{
          backgroundColor: isFor ? colors.evidence.for : colors.evidence.against,
        }}
        expanded={editing}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          sx={{
            overflow: 'hidden',
            padding: 3,
          }}
        >
          <Stack width='100%' alignItems='flex-start'>
            {editing && (
              <Box
                color={colors.text.unSelected}
                sx={{ opacity: 0.9 }}
                marginBottom={2}
                fontSize={16}
                fontWeight={400}
                lineHeight='19px'
              >
                {label}
              </Box>
            )}
            <Box className='titleBox'>
              <TextField
                disabled={disabled}
                inputProps={{ maxLength: ui.evidenceTitleLength }}
                onClick={onClickTitle}
                autoFocus={!!parentEvidence && updatingEvidence?.status !== 'published'}
                // label={label}
                placeholder={label}
                value={title}
                onChange={onChangeTitle}
                fullWidth
                style={{
                  backgroundColor: 'white',
                  border: '1px solid #C0E4FF',
                  borderRadius: 10,
                }}
              />
            </Box>

            {updatingEvidence?.status === 'published' && title !== updatingEvidence?.title && (
              <Stack>
                <Typography variant='h5' color='warning'>
                  URL will not change since this evidence is published.
                </Typography>
                <Typography variant='body2'>
                  Improving the title to better match the content is fine, but if you want to
                  significantly change the content, consider posting new evidence instead.
                </Typography>
              </Stack>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 3 }}>
          <p className='text-unselected text-lg mb-2 ml-1'>
            For help writing your post, see our{' '}
            <a
              className='text-primary'
              target='_blank'
              rel='noreferrer'
              href={
                '/help?topic=' +
                (parentEvidence
                  ? 'guidelines-for-posting-rebuttals'
                  : 'guidelines-for-posting-evidence')
              }
            >
              {parentEvidence ? 'rebuttal' : 'evidence'} guidelines
            </a>
          </p>
          <BodyEditor
            body={body}
            onChange={onChangeBody}
            imageUploadPurpose='evidence'
            autoFocus={updatingEvidence?.status === 'published'}
            disabled={disabled}
          />
          <Button
            sx={{ my: 3, mr: 2, borderRadius: '4px' }}
            variant='contained'
            disabled={submitEvidence.isLoading || updateEvidence.isLoading || disabled}
            onClick={checkRequirementsAndSubmit}
          >
            Submit
          </Button>
          <Button
            sx={{ my: 3 }}
            variant='containedTertiary'
            onClick={onClickCancel}
            disabled={disabled}
          >
            Cancel
          </Button>
        </AccordionDetails>
      </Accordion>

      <CredentialModals
        open={credentialsOpen}
        requireUsername={true}
        signinPrompt={'Please enter your email to post this evidence'}
        usernamePrompt={'Please choose a username to post this evidence'}
        onSuccess={onCredentialsSuccess}
        onClose={() => setCredentialsOpen(false)}
      />

      <LikelihoodModal
        open={likelihoodModalOpen}
        theory={theory}
        prompt={`Your answer to "${theory.title}"`}
        onSuccess={onLikelihoodSuccess}
        onClose={() => setLikelihoodModalOpen(false)}
      />
    </>
  );
};

export default EvidenceEditor;
