import React from 'react';
import CreatedAt from 'components/elements/CreatedAt';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import DefaultProfileImage from 'assets/images/UserProfile/defaultProfileImage.png';
import styles from './NotificationItem.module.scss';
import DOMPurify from 'dompurify';
import { marked } from 'marked';

const NotificationItem = ({ notification }) => {
  const navigate = useNavigate();
  return (
    <Box
      className={styles.root}
      onClick={() => {
        navigate(notification.route);
      }}
    >
      {!notification.read && <Box className={styles.dot} />}
      <Box className='flex'>
        <img
          src={notification.actionUser?.profileImage?.thumbnail?.url || DefaultProfileImage}
          alt='User Avatar'
        />
        <Box className={styles.content}>
          <Box className='flex' style={{ height: '20px' }}>
            <span className={styles.username}>{notification.actionUser?.username}</span>
            <Box className={styles.createTime}>
              <AccessTimeOutlinedIcon />
              <CreatedAt createdAt={notification.createdAt} />
            </Box>
          </Box>
          <Box
            className='normal-case mt-2'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(marked.parse(notification.text)),
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default NotificationItem;
