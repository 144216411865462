import { Paper, Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { useTrustConfig } from '../../services/config';
import { useMe } from '../../services/users';
import { sameId } from '../../util/id';

const TrustLevel = ({ user }) => {
  const trustConfig = useTrustConfig();
  const me = useMe().data?.data;

  const trustLevels = trustConfig.data?.data?.trustLevels;

  if (!trustLevels || !user) return null;

  const startTrust = 0;
  const endTrust = trustLevels['' + (user.trustLevel + 1)]?.trustNeeded || user.trustEarned;
  const progress = (100 * (user.trustEarned - startTrust)) / (endTrust - startTrust);

  if (!sameId(me, user)) return null;
  else
    return (
      <Tooltip title={user.trustEarned ? ` (${user.trustEarned}/${endTrust})` : ''}>
        <Paper sx={{ p: 0.5 }}>
          <Stack>
            {!!user.trustEarned && endTrust > startTrust && (
              <LinearProgress variant='determinate' color='secondary' value={progress} />
            )}
          </Stack>
        </Paper>
      </Tooltip>
    );
};

export default TrustLevel;
