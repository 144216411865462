import Box from '@mui/material/Box';
import { CalendarIcon } from '../../icons';
import CreatedAt from '../../elements/CreatedAt';
import colors from '../../../assets/theme/base/colors';
import { makeStyles } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    '& .title': {
      color: colors.text.regular,
      opacity: '0.9',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 32,
      lineHeight: '39px',
      /* identical to box height */

      display: 'flex',
      margin: 0,
    },
    '& .date': {
      marginLeft: 'auto',
      alignSelf: 'center',

      '& img': {
        width: '16.25px',
        height: '16.6px',
        marginRight: '7px',
        alignSelf: 'center',
      },
      '& span': {
        color: colors.text.unSelected,
        fontSize: 16,
        opacity: '0.9',
      },
    },
  },
});

const TheoryTitle = ({ theory, isLink }) => {
  const classes = useStyles();

  const title = isLink ? (
    <RouterLink to={'/debate/' + theory?.slug} style={{ textDecoration: 'none' }}>
      <p className='title'>{theory?.title}</p>
    </RouterLink>
  ) : (
    <p className='title'>{theory?.title}</p>
  );

  return (
    <Box className={classes.root} display='flex' marginTop={1} marginBottom={1}>
      {title}
      <Box display='flex' className='date'>
        <img src={CalendarIcon} alt='Calendar Icon' />
        <CreatedAt createdAt={theory?.createdAt} />
      </Box>
    </Box>
  );
};

export default TheoryTitle;
