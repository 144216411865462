/**
 =========================================================
 * Material Kit 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryClientProvider } from 'react-query';

import { queryClient } from 'contexts/react-query';
import sentryConfig from 'config/sentry';
import appConfig from 'config/app';

import App from 'App';

import './index.css';

if (appConfig.env === 'production') {
  Sentry.init({
    dsn: sentryConfig.dsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: appConfig.appEnv,
  });
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred, please try again</p>}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);
