import { fetchClient } from 'services/api';
import { useQuery } from 'react-query';

const apiClient = fetchClient();

export const search = (searchString, type) =>
  apiClient.post('/search/' + (type || ''), { searchString: searchString }).then(res => res.data);

export const useSearch = ({ searchString, type }) =>
  useQuery(['search', type, searchString], () => search(searchString, type), {
    enabled: !!searchString,
  });
