/**
 All of the routes for Evincer
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Navbar.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `name` key is used for the name of the route on the Navbar.
 2. The `icon` key is used for the icon of the route on the Navbar.
 3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 4. The `route` key is used to store the route location which is used for the react router.
 5. The `href` key is used to store the external links location.
 7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
 8. The `description` key is used to define the description of
 a route under its name.
 9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
 you can set the columns amount based on this key.
 10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
 */

import AdminTags from 'pages/Admin/AdminTags';
import ProposeTheory from '../pages/ProposeTheory';
import ProposedTheories from '../pages/ProposedTheories';
import AdminProposedTheories from '../pages/Admin/AdminProposedTheories';
import { Route } from 'react-router-dom';
import Theory from '../pages/Theory';
import Container from '@mui/material/Container';
import UserProfile from '../pages/UserProfile';
import Evidence from '../pages/Evidence';
import Home from '../pages/Home';
import Theories from '../pages/Theories';
import ProposedTheory from '../pages/ProposedTheory';
import SignInWithLink from 'pages/SignInWithLink';
import SignIn from 'pages/SignIn';
import SignUp from 'pages/SignUp';
import SubscriptionSettings from 'pages/SubscriptionSettings';
import Help from 'pages/Help';
import Messages from 'pages/Messages';
import StyleGuide from 'pages/StyleGuide/index';
import { NotificationIcon } from 'components/icons';
import Search from '../pages/Search';
import AdminNewsletter from '../pages/Admin/AdminNewsletter';
import TrustLevels from '../pages/TrustLevels';
import Invite from '../pages/Invite';
import Users from '../pages/Admin/Users';
import AdminActivity from '../pages/Admin/AdminActivity';
import Comments from '../pages/Comments';

const staticMenu = [
  {
    name: 'Debates',
    route: 'debates',
  },
  {
    name: 'Proposals',
    route: 'proposals',
  },
  {
    name: 'Help',
    route: 'help',
  },
  {
    name: 'Admin',
    hidden: true,
    visibleTo: 'admin',
    icon: 'article',
    collapse: [
      {
        name: 'tags',
        description: 'Create new tags',
        route: 'admin/tags',
      },
      {
        name: 'Proposals',
        description: 'Publish proposals',
        route: 'admin/proposals',
      },
      {
        name: 'newsletter',
        route: 'admin/newsletter',
      },
      {
        name: 'users',
        route: 'admin/users',
      },
      {
        name: 'activity',
        route: 'admin/activity',
      },
    ],
  },

  {
    name: 'Notifications',
    icon: NotificationIcon,
    hidden: true,
    visibleTo: 'authenticated',
    collapse: [],
  },

  {
    name: 'Account',
    icon: 'perm_identity',
    collapse: [
      {
        name: 'Sign In',
        icon: 'login',
        route: '/sign-in',
        visibleTo: '!signedIn',
      },
      {
        name: 'Sign Up',
        route: '/sign-up',
        icon: 'logout',
        visibleTo: '!signedIn',
      },

      {
        name: 'View Profile',
        visibleTo: 'authenticated',
        icon: 'perm_identity',
        route: '/user',
      },
      {
        name: 'Comments',
        id: 'comments',
        visibleTo: 'signedIn',
        icon: 'notes',
        route: '/comments',
      },
      {
        name: 'Messages',
        id: 'messages',
        icon: 'chat_bubble_outline',
        visibleTo: 'signedIn',
        route: '/messages',
      },
      {
        name: 'Invite',
        icon: 'co_present',
        visibleTo: 'invite',
        route: '/invite',
      },
      {
        name: 'Sign Out',
        visibleTo: 'signedIn',
        icon: 'logout',
      },
    ],
  },
];
export default staticMenu;

export const isVisible = ({
  item,
  isGuest,
  isAuthenticated,
  isSignedIn,
  isAdmin,
  trustedActions,
}) => {
  if (!item.visibleTo) return true;

  const boolMap = {
    guest: isGuest,
    authenticated: isAuthenticated, // remember that isSignedIn implies isAuthenticated
    signedIn: isSignedIn,
    admin: isAdmin,
    ...trustedActions.reduce((map, action) => ({ ...map, [action]: true }), {}),
  };
  return Object.keys(boolMap).some(
    setting =>
      (item.visibleTo === setting && boolMap[setting]) ||
      (item.visibleTo === '!' + setting && !boolMap[setting]),
  );
};

export const getRoutes = () => {
  const routes = [
    { path: '/', element: <Home />, exact: true },
    { path: '/admin/tags', element: <AdminTags /> },
    { path: '/admin/proposals', element: <AdminProposedTheories /> },
    { path: '/admin/newsletter', element: <AdminNewsletter /> },
    { path: '/admin/users', element: <Users /> },
    { path: '/admin/activity', element: <AdminActivity /> },
    { path: '/comments/', element: <Comments /> },
    { path: '/evidence/:slug', element: <Evidence /> },
    { path: '/rebuttal/:slug', element: <Evidence /> },
    { path: '/help', element: <Help /> },
    { path: '/proposals/new', element: <ProposeTheory /> },
    { path: '/proposals', element: <ProposedTheories /> },
    { path: '/proposal/:slug', element: <ProposedTheory /> },
    { path: '/messages', element: <Messages /> },
    { path: '/messages/:username', element: <Messages /> },
    { path: '/subscription-settings', element: <SubscriptionSettings /> },
    { path: '/debates', element: <Theories /> },
    { path: '/debates/:slug', element: <Theories /> },
    { path: '/debate/:slug', element: <Theory /> },
    { path: '/user', element: <UserProfile /> },
    { path: '/user/:username', element: <UserProfile /> },
    { path: '/style-guide', element: <StyleGuide /> },
    { path: '/sign-in/:token', element: <SignInWithLink /> },
    { path: '/sign-in', element: <SignIn /> },
    { path: '/sign-up', element: <SignUp /> },
    { path: '/search', element: <Search /> },
    { path: '/trust-levels', element: <TrustLevels /> },
    { path: '/invite', element: <Invite /> },

    {
      path: '*',
      exact: true,
      element: <Container>There's nothing here!</Container>,
    },
  ];
  return routes.map(r => (
    <Route exact={!!r.exact} path={r.path} element={r.element} key={r.path} />
  ));
};
