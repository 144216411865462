import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CreatedAt from './elements/CreatedAt';

const Edited = ({ subject, onClick }) => {
  if (!subject?.editedAt) return null;
  return (
    <div
      className='pr-6 flex flex-row !cursor-pointer justify-end date text-sm space-x-1'
      onClick={onClick}
    >
      <p>Edited by</p>
      <p>@{subject.editedByUsername}</p>
      <AccessTimeOutlinedIcon />
      <CreatedAt createdAt={subject.editedAt} />
    </div>
  );
};

export default Edited;
