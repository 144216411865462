import { createContext, useContext, useEffect, useState } from 'react';
import { useMe } from '../services/users';
import { useSignOut, useOnSignIn } from '../services/auth';
import UiContext from './UiContext';
import useDetectSignIn from '../hooks/use-detect-sign-in';

const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {
  const { toastInfo } = useContext(UiContext);
  const [signingIn, setSigningIn] = useState(false);
  const [tokenSentTo, setTokenSentTo] = useState(false);
  const [naming, setNaming] = useState(false);
  const meQuery = useMe();
  const meData = meQuery.data?.data;
  const signOut = useSignOut();
  const [me, setMe] = useState();
  const [isGuest, setIsGuest] = useState(true); // no valid jwt (a temporary condition unless signed out)
  const [isAuthenticated, setIsAuthenticated] = useState(false); // do we have a valid jwt?
  const [isSignedIn, setIsSignedIn] = useState(false); // do we have a valid jwt and verified email?
  const [isNamed, setIsNamed] = useState(false); // do we have a valid jwt, email, and a username?
  const [isAdmin, setIsAdmin] = useState(false); // are we an admin?
  const onSignIn = useOnSignIn();

  useEffect(() => {
    if (meQuery.isLoading) {
      return;
    }

    setMe(meData);
    setIsGuest(!meData?.id);
    setIsNamed(!!meData?.username);
    setIsAuthenticated(!!meData?.id);
    setIsSignedIn(!!meData?.completedSignup);
    setIsAdmin(meData?.roles?.includes('admin'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meQuery.isLoading, meData]);

  useDetectSignIn({
    onSignIn: () => {
      if (!meData?.id) {
        onSignIn();
        meQuery.refetch();
        toastInfo('Completed sign-in from another tab');
      }
    },
    onSignOut: () => {
      if (meData?.id) {
        signOut.mutate();
        toastInfo('Signed out from another tab');
      }
    },
  });

  return (
    // prettier-ignore
    <AuthContext.Provider
      value={{
        signingIn, setSigningIn,
        tokenSentTo, setTokenSentTo,
        naming, setNaming,
        me, isGuest, isAuthenticated, isSignedIn, isNamed, isAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
