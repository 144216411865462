import colors from '../../assets/theme/base/colors';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import { useContext, useState } from 'react';
import UiContext from '../../state/UiContext';
import { useGetToken } from '../../services/auth';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../../state/AuthContext';

const useStyles = makeStyles({
  subscribeSection: {
    backgroundColor: 'rgba(255,255,255,0.12)',
  },
});

const Subscribe = () => {
  const classes = useStyles();
  const { isSignedIn, setTokenSentTo } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const { toastError } = useContext(UiContext);
  const navigate = useNavigate();
  const getToken = useGetToken();
  const location = useLocation();

  function submit() {
    getToken.mutate(
      { email },
      {
        onSuccess: () => {
          setTokenSentTo(email);
          navigate('/sign-up');
        },
        onError: res => toastError(res.message),
      },
    );
  }

  function onChangeEmail(e) {
    setEmail(e.target.value.trim());
  }

  if (isSignedIn) return null;
  if (location.pathname.startsWith('/sign-up') || location.pathname.startsWith('/sign-in'))
    return null;
  else
    return (
      <Stack
        direction='row'
        flexWrap='wrap'
        justifyContent='center'
        py={4}
        className={classes.subscribeSection}
      >
        <span style={{ color: colors.white.main, alignSelf: 'center' }}>See Evidence</span>
        <Paper
          sx={{
            p: '10px 15px',
            borderRadius: 100,
            display: 'flex',
            marginLeft: 3,
            alignItems: 'center',
            width: 400,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={'Your email'}
            inputProps={{ 'aria-label': 'Your email' }}
            value={email}
            onChange={onChangeEmail}
            onKeyUp={e => e.key === 'Enter' && submit()}
          />
          <IconButton
            type='button'
            style={{ background: '#0081FE' }}
            sx={{ p: '10px' }}
            aria-label='send'
            onClick={submit}
          >
            <SendIcon color='white' />
          </IconButton>
        </Paper>
      </Stack>
    );
};

export default Subscribe;
