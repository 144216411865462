import DefaultBackground from 'assets/images/background2.png';
import classnames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import { useContext } from 'react';
import AuthContext from '../../state/AuthContext';

export default function Explainer({ onClose, title, texts, listItems, backgroundImg }) {
  const { isSignedIn } = useContext(AuthContext);

  return (
    <div
      className={classnames(
        'relative w-full bg-cover bg-center p-10 flex flex-wrap justify-around',
      )}
      style={{ backgroundImage: `url(${backgroundImg || DefaultBackground})` }}
    >
      {isSignedIn && (
        <div className='absolute top-3 right-3 cursor-pointer'>
          <CloseIcon size='large' onClick={onClose} />
        </div>
      )}
      {texts.map((text, i) => (
        <div
          key={i}
          className='inline-block m-2 bg-black backdrop-blur bg-opacity-50 border-opacity-40 border-2 border-white rounded-lg p-3 text-center'
          style={{ maxWidth: 530, minWidth: 300 }}
        >
          <span
            className={classnames('text-white self-center justify-center text-center', {
              'text-xl': title,
              'font-semibold': title,
            })}
          >
            {text}
          </span>
        </div>
      ))}
      {listItems?.length && (
        <ol className='mt-8 list-decimal text-left text-white p-3 bg-white backdrop-blur bg-opacity-10 rounded'>
          {listItems.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ol>
      )}
    </div>
  );
}
