import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@mui/material/Link';

const TheoryTitle = ({ theory, isProposedTheory }) => (
  <MuiLink
    variant='h4'
    component={RouterLink}
    to={(isProposedTheory ? '/proposal/' : '/debate/') + theory?.slug}
  >
    {theory?.title || 'NULL'}
  </MuiLink>
);

export const ProposedTheoryTitle = ({ proposedTheory }) => (
  <TheoryTitle theory={proposedTheory} isProposedTheory={true} />
);

export default TheoryTitle;
