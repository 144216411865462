import React, { useCallback, useContext, useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import UiContext from 'state/UiContext';
import { useVerifyToken } from 'services/auth';
import AuthContext from '../state/AuthContext';

const SignInWithLink = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { toastError } = useContext(UiContext);
  const { setSigningIn } = useContext(AuthContext);
  const signIn = useVerifyToken();

  const handleError = useCallback(
    res => {
      toastError(res.message);
      setSigningIn('Invalid sign-in link, please try again');
    },
    [setSigningIn, toastError],
  );

  useEffect(
    () => {
      if (!token) {
        return;
      }

      signIn.mutate(
        { token: token },
        {
          onSuccess: () => navigate('/'),
          onError: handleError,
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, handleError],
  );

  if (!token) {
    toastError('Invalid sign-in link');
    return <Navigate to='/' />;
  }

  return <div></div>;
};

export default SignInWithLink;
