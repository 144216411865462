import EvidenceNode from './EvidenceNode';
import Stack from '@mui/material/Stack';
import { useEvidenceChildren, useEvidenceGrandchildren } from '../../services/evidence';
import { useState } from 'react';
import { Skeleton } from '@mui/material';
import { useIsTrusted } from '../../services/config';

const Evidences = ({ isFor, theory, parentEvidence, onExpand, level, collapseEvents }) => {
  const key = {
    theoryId: theory.id,
    parentId: parentEvidence?.id || null,
  };
  const evidencesQuery = useEvidenceChildren(key);
  const [showArchived, setShowArchived] = useState(false);
  const [isTrustedToShowArchived] = useIsTrusted('showArchived');
  const archivedQuery = useEvidenceChildren(
    { ...key, status: 'archived' },
    { enabled: isTrustedToShowArchived && evidencesQuery.isSuccess },
  );

  const filter = e => isFor === undefined || isFor === e.for;
  const evidences = evidencesQuery.data?.data
    ?.concat((showArchived && archivedQuery.data?.data) || [])
    .filter(filter);

  useEvidenceGrandchildren(
    { theoryId: theory.id, grandparentId: parentEvidence?.id || null },
    { enabled: evidencesQuery.isSuccess },
  ); // prefetch

  return (
    <Stack spacing={1} justifyContent='center'>
      {evidences?.map(evidence => (
        <EvidenceNode
          key={evidence.id}
          theory={theory}
          evidence={evidence}
          parentEvidence={parentEvidence}
          onExpand={onExpand}
          level={level}
          collapseEvents={collapseEvents}
        />
      ))}
      {(evidencesQuery.isLoading || archivedQuery.isLoading) &&
        [1, 2, 3, 4, 5].map(i => <Skeleton key={i} height={200} />)}
      {isTrustedToShowArchived &&
        !showArchived &&
        !!archivedQuery.data?.data?.filter(filter).length && (
          <button className='text-info text-sm' onClick={() => setShowArchived(true)}>
            show archived
          </button>
        )}
    </Stack>
  );
};

export default Evidences;
