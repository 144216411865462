/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 PRO React Base Styles
import colors from 'assets/theme/base/colors';

const { transparent } = colors;

const textField = {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,
      '& .MuiFormHelperText-root': {
        margin: 0,
        marginTop: 8,
        color: colors.titleText.placeHolder,
      },
      '& .Mui-error': {
        color: colors.tertiary.focus,
      },

      '& .MuiOutlinedInput-root': {
        '&.Mui-error fieldset': {
          borderColor: colors.tertiary.focus,
          borderWidth: 2,
        },
        '& fieldset': {
          borderColor: '#D7E0E2',
        },
        // '&:hover fieldset': {
        //   borderColor: colors.green.main,
        //   borderWidth: 2,
        //   color: colors.green.main,
        // },
        '&.Mui-focused fieldset': {
          borderColor: colors.green.main,
          borderWidth: 2,
        },
        '& input': {
          color: colors.titleText.main,
          fontWeight: 600,
          fontSize: 14,
          padding: 15,
        },
        '&.Mui-focused input': {
          '&::placeholder': {
            textOverflow: 'ellipsis !important',
            color: colors.success.main,
          },
        },
      },
    },
  },
};

export default textField;
