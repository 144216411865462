import React, { useContext, useEffect, useState } from 'react';
import { Icon, Tooltip } from '@mui/material';

import { subscribe, unsubscribe } from 'services/subscription';
import UiContext from 'state/UiContext';

import MKButton from 'mkcomponents/MKButton';
import MKBox from 'mkcomponents/MKBox';
import AuthContext from '../state/AuthContext';

const SubscriptionButton = ({ subscription, dataKey, dataId, path, disabled, tooltip }) => {
  const { toastSuccess, toastError } = useContext(UiContext);
  const [subscriptionData, setSubscriptionData] = useState(subscription);
  const { isSignedIn } = useContext(AuthContext);
  const isActive = subscriptionData?.isActive;

  useEffect(() => {
    setSubscriptionData(subscription);
  }, [subscription]);

  const handleSubscribe = async () => {
    try {
      const res = await subscribe({ path, dataKey, id: dataId });
      toastSuccess(res.message);
      setSubscriptionData(res.data);
    } catch (e) {
      toastError(e.message);
    }
  };

  const handleUnsubscribe = async () => {
    try {
      await unsubscribe({ path, id: subscriptionData.id });
      toastSuccess('Unsubscribed successfully');
      setSubscriptionData('');
    } catch (e) {
      toastError(e.message);
    }
  };

  return !isSignedIn ? null : (
    <Tooltip title={tooltip || ''}>
      <MKBox position='fixed' bottom='20px' right='20px' zIndex='200'>
        <MKButton
          disabled={disabled}
          type='submit'
          variant='gradient'
          color={isActive ? 'dark' : 'light'}
          onClick={isActive ? handleUnsubscribe : handleSubscribe}
        >
          <Icon>notifications</Icon>&nbsp; {isActive ? 'Subscribed' : 'Subscribe'}
        </MKButton>
      </MKBox>
    </Tooltip>
  );
};

export default SubscriptionButton;
