import { fetchClient } from 'services/api';
import { staleTimes } from './query-settings';
import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from 'react-query';

import { keys as evidenceKeys } from './evidence';
import { useTags } from './tags';

const apiClient = fetchClient();

const getTheories = async (tagIdOrSlug, page = 0) => {
  return apiClient
    .get(
      '/theory' +
        (tagIdOrSlug ? '?tag=' + tagIdOrSlug + '&' : '?') +
        'page=' +
        page +
        '&perPage=12',
    )
    .then(res => res.data);
};

export const useTheories = tagIdOrSlug =>
  useInfiniteQuery(
    tagIdOrSlug ? ['theories', { tagIdOrSlug: tagIdOrSlug }] : 'theories',
    ({ pageParam }) => getTheories(tagIdOrSlug, pageParam),
    {
      getNextPageParam: lastPage => {
        if (lastPage.meta.pageCount <= lastPage.meta.page) {
          return null;
        }
        return lastPage.meta.page + 1;
      },
    },
    { staleTime: staleTimes.long },
  );

const getAdminTheories = () => apiClient.get('/admin/theory').then(res => res.data);

export const useAdminTheories = () => useQuery(['theories', { admin: true }], getAdminTheories);

const getTheory = async idOrSlug => apiClient.get('/theory/' + idOrSlug).then(res => res.data);

export const useGetTheory = slug => {
  return useQuery(['theory', { slug: slug }], () => getTheory(slug), {
    staleTime: staleTimes.medium,
  });
};

const updateLikelihood = async (id, likelihood) =>
  await apiClient.post('/theory/' + id + '/likelihood', likelihood);

export const useUpdateLikelihood = function () {
  const queryClient = useQueryClient();
  return useMutation(
    ({ theory, likelihood }) => updateLikelihood(theory.id, { likelihood }).then(res => res.data),
    {
      onSuccess: (data, variables) => {
        const key = ['theory', { slug: variables.theory.slug }];
        if (queryClient.getQueryData(key))
          queryClient.setQueryData(key, oldTheory => {
            return {
              ...oldTheory,
              data: { ...oldTheory.data, userLikelihood: variables.likelihood },
            };
          });

        queryClient.setQueriesData(evidenceKeys.allOnes(), oldEvidence =>
          oldEvidence
            ? {
                ...oldEvidence,
                data: {
                  ...oldEvidence?.data,
                  theory: { ...oldEvidence.data?.theory, userLikelihood: variables.likelihood },
                },
              }
            : undefined,
        );
      },
    },
  );
};

const updateTheory = async theory => {
  const formData = new FormData();
  ['title', 'body', 'tags', 'image'].forEach(
    field => theory[field] && formData.append(field, theory[field]),
  );

  return await apiClient
    .patch('/theory/' + theory.id, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(res => res.data);
};

export const useUpdateTheory = () => {
  const queryClient = useQueryClient();
  return useMutation(theory => updateTheory(theory), {
    onSuccess: () => {
      queryClient.invalidateQueries('theories');
      queryClient.invalidateQueries('theory');
    },
  });
};

const updateTags = async (id, tags) =>
  await apiClient.patch('/theory/' + id + '/tags/', { tags }).then(res => res.data);

export const useUpdateTags = () => {
  const queryClient = useQueryClient();
  return useMutation(({ theory, tags }) => updateTags(theory.id, tags), {
    onSuccess: () => {
      queryClient.invalidateQueries('theories');
      queryClient.invalidateQueries('theory');
    },
  });
};

const unpublishTheory = async id => await apiClient.delete('/theory/' + id + '/publish');

export const useUnpublishTheory = function () {
  const queryClient = useQueryClient();
  return useMutation(id => unpublishTheory(id).then(res => res.data), {
    onSuccess: () => queryClient.invalidateQueries('theories'),
  });
};

const republishTheory = async id => await apiClient.post('/theory/' + id + '/publish');

export const useRepublishTheory = function () {
  const queryClient = useQueryClient();
  return useMutation(id => republishTheory(id).then(res => res.data), {
    onSuccess: () => queryClient.invalidateQueries('theories'),
  });
};

export const useGetTheoryImage = () => {
  // use first canonical tag.image if no theory.image
  const tags = useTags().data?.data;
  return theory =>
    theory?.image?.url ||
    theory?.tags?.reduce(
      (image, tTag) => image || tags?.find(tag => tag.id === tTag.tag)?.image?.url,
      undefined,
    );
};
