import React, { useRef, useState, useContext, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import UiContext from 'state/UiContext';
import usePushNotifications from 'hooks/use-push-notifications';
import { getOrCreateConversation, useConversations } from 'services/conversation';

import Messages from './Messages';
import List from './List';
import Recipient from './Recipient';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
import colors from 'assets/theme/base/colors';
import { SearchIcon, ThreeDotIcon } from '../icons';
import { useMe } from '../../services/users';
import CredentialModals from 'components/CredentialModals';

const useStyles = makeStyles({
  root: {
    display: 'block',
    '& .container ul': {
      '& ul': {
        left: 0,
      },
      '& li': {
        left: 0,
      },
    },

    '& .messagesWithConvo': {
      display: 'block',
      width: '100%',
    },

    '& .wrapper': {
      display: 'flex',
      '@media (max-width:767px)': {
        display: 'block',
      },
    },

    '& .conversations': {
      float: 'left',
      width: '40%',
      '@media (max-width:767px)': {
        width: '100%',
      },
      background: colors.white.main,
      border: '1px solid rgba(0, 0, 0, 0.05)',
      borderRadius: '5px 0px 0px 5px',
    },
    '& .searchBar': {
      backgroundColor: '#FBFBFB',
      padding: '30px 20px',
      '& .MuiTextField-root': {
        width: '100%',
      },
    },

    '& .list': {
      minHeight: 400,
      overflowY: 'auto',
    },

    '& .header': {
      height: 85,
      padding: '15px 20px',
      fontSize: '1.1rem',
      alignItems: 'center',
      display: 'flex',
      position: 'relative',
      '& a': {
        marginTop: 10,
        textDecoration: 'none',
      },
    },

    '& .headerThemed': {
      backgroundColor: '#dc4d2b',
      color: '#fff',
    },

    '& .user': {
      display: 'flex',
    },

    '& .close': {
      position: 'absolute',
      right: 15,
      top: 30,
      textDecoration: 'none',
      cursor: 'pointer',
      display: 'inline-block',
      paddingLeft: 10,
    },

    '& .messages': {
      width: '60%',
      '@media (max-width:767px)': {
        width: '100%',
      },
      border: '1px solid rgba(0, 0, 0, 0.05)',
    },

    '& .messagesList': {
      minHeight: 'calc(100vh - 200px)',
      overflowY: 'auto',
    },

    '& .emptyState': {
      fontSize: '1.2rem',
      textAlign: 'center',
      padding: '5%',
    },

    '& .clear-fix': {
      clear: 'both',
    },
  },
});
const Conversations = () => {
  const classes = useStyles();
  const { username } = useParams();
  const containerEl = useRef(null);
  const meQuery = useMe();
  const me = meQuery.data?.data;
  const { toastError } = useContext(UiContext);
  const conversationsQuery = useConversations();
  const { subscribe } = usePushNotifications();

  const [credentialsOpen, setCredentialsOpen] = useState(false);
  const [requiresUsername, setRequiresUsername] = useState(false);
  const [conversationPages, setConversationPages] = useState(conversationsQuery.data?.pages || []);
  const [conversation, setConversation] = useState(null);
  const [fetchNewConversation, setFetchNewConversation] = useState(false);

  useEffect(() => {
    if (!me?.id) {
      return;
    }

    const channel = subscribe(`private-self-${me.id}`);
    if (!channel) {
      return;
    }

    channel.bind('update-conversation-dms', ({ dms, conversation }) => {
      setConversationPages(pages =>
        pages?.map(page => ({
          ...page,
          data: page.data?.map(existingConversation => ({
            ...existingConversation,
            ...(conversation === existingConversation.id ? { dms } : null),
          })),
        })),
      );
    });
  }, [me, subscribe]);

  useEffect(() => {
    if (conversationsQuery.isLoading) return;

    const pages = conversationsQuery.data?.pages || [];
    setConversationPages(pages);

    if (!username) {
      return;
    }

    const existingConversation = pages.reduce(
      (conv, page) =>
        conv ||
        page.data.find(
          conversation =>
            conversation.recipient &&
            (conversation.recipient.username === username ||
              conversation.recipient.id === username),
        ),
      undefined,
    );

    if (existingConversation) {
      setConversation(existingConversation);
    } else {
      setFetchNewConversation(true);
    }
  }, [username, conversationsQuery.data, conversationsQuery.isLoading, requiresUsername]);

  useEffect(() => {
    if (!fetchNewConversation || requiresUsername) {
      return;
    }

    const fetchConversation = async () => {
      try {
        const conversation = await getOrCreateConversation(username);
        setConversation(conversation.data);

        setConversationPages(existingConversations =>
          existingConversations.length
            ? existingConversations.map((page, i) => ({
                data: i === 0 ? [conversation.data, ...page.data] : page,
              }))
            : [{ data: [conversation.data] }],
        );
        setFetchNewConversation(false);
      } catch (e) {
        toastError(e.message);
      }
    };

    fetchConversation();
  }, [fetchNewConversation, toastError, username, requiresUsername]);

  useEffect(() => {
    if (meQuery.isLoading) {
      return;
    }

    setRequiresUsername(username && !me?.username);
  }, [me, meQuery, username]);

  useEffect(() => {
    setCredentialsOpen(requiresUsername);
  }, [requiresUsername]);

  const onConversationSelect = conversation => {
    setConversation(conversation);
  };

  const onMessageClose = () => {
    setConversation(null);
  };

  return (
    <Box className={classes.root}>
      <Box className='wrapper'>
        <Box className='conversations'>
          <Box className='searchBar'>
            <TextField
              id='input-with-icon-textfield'
              placeholder='Search in conversation'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <img src={SearchIcon} alt='Search Icon' />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {!conversationPages.length && (
            <Box className='emptyState'>You don't have any messages</Box>
          )}
          <Box className='list'>
            <List
              conversationPages={conversationPages}
              onConversationSelect={onConversationSelect}
              hasNextPage={conversationsQuery.hasNextPage}
              fetchNextPage={conversationsQuery.fetchNextPage}
              isFetchingNextPage={conversationsQuery.isFetchingNextPage}
            />
          </Box>
        </Box>
        <Box className='messages'>
          {conversation ? (
            <>
              <Box className='header'>
                <RouterLink to={`/user/${conversation.recipient.username}`} className='user'>
                  <Recipient
                    user={conversation.recipient}
                    date={conversation.lastUpdated}
                    header={true}
                  />
                </RouterLink>
                <Box className='close' onClick={onMessageClose}>
                  <img src={ThreeDotIcon} alt='Icon' style={{ width: 2 }} />
                </Box>
              </Box>
              <Box className='messagesList' ref={containerEl}>
                <Messages conversation={conversation} containerEl={containerEl} />
              </Box>
            </>
          ) : (
            <Box className='emptyState'>You've not selected a message</Box>
          )}
        </Box>
      </Box>
      {credentialsOpen && (
        <CredentialModals
          open={requiresUsername}
          requireUsername={true}
          signinPrompt={'Please enter your email before sending a message'}
          usernamePrompt={'Please choose a username before sending a message'}
          onSuccess={() => setRequiresUsername(false)}
          onClose={() => setCredentialsOpen(false)}
        />
      )}
    </Box>
  );
};

export default Conversations;
