import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useMyProposedTheories, useRankProposedTheory } from '../services/proposed-theories';

import Heading from '../components/Banners/Heading';
import Breadcrumbs from '../components/Breadcrumbs';
import { Typography } from '@mui/material';
import colors from 'assets/theme/base/colors';
import TheoryBackground from 'assets/images/background2.png';
import ProposedTheoryEditor from '../components/Theory/ProposedTheoryEditor';
import { useSearchParams } from 'react-router-dom';
import TheoryCard from '../components/Cards/TheoryCard';
import HeadTags from '../components/HeadTags';

function ProposeTheory() {
  const [searchParams] = useSearchParams();
  const myProposedTheoriesQuery = useMyProposedTheories();
  const myProposedTheories = myProposedTheoriesQuery.data?.data;

  const rankTheory = useRankProposedTheory();

  const breadcrumbItems = [
    {
      link: '/',
      text: 'Home',
    },
    {
      link: '/proposals',
      text: 'Proposals',
    },
    {
      text: 'New',
    },
  ];

  return (
    <Box>
      <HeadTags title='Propose a Theory' />

      <Heading
        text={searchParams.get('editId') ? 'Edit Proposal' : 'Propose a Debate'}
        backgroundImg={TheoryBackground}
      />
      <Breadcrumbs items={breadcrumbItems} />

      <ProposedTheoryEditor />

      {myProposedTheories?.length > 0 && (
        <>
          <Typography
            style={{ opacity: 0.9 }}
            marginTop={5}
            fontWeight={600}
            fontSize={40}
            color={colors.text.regular}
            textAlign='center'
            mb={1}
          >
            My Proposals
          </Typography>
          <Grid container spacing={1}>
            {myProposedTheories?.map(pt => (
              <Grid item xs={12} md={6} lg={4} xl={3} key={pt.id}>
                <TheoryCard theory={pt} user={pt.user} date={pt.createdAt} mutator={rankTheory} />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );
}

export default ProposeTheory;
