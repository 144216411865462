import {
  useHasChildren,
  useMarkEvidenceRead,
  useRankEvidence,
  useUnpublishEvidence,
} from '../../services/evidence';
import Evidences from './Evidences';
import { Accordion, AccordionDetails, AccordionSummary, Tooltip } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import RankPanel from '../RankPanel';
import Body from '../Body';
import EvidenceEditor from './EvidenceEditor';
import UiContext from '../../state/UiContext';
import IconButton from '@mui/material/IconButton';
import MKTypography from '../../mkcomponents/MKTypography';
import colors from '../../assets/theme/base/colors';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Status from './Status';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ChatIcon, ThumbDownIcon, ThumbUpIcon, TrashIcon } from '../icons';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CreatedAt from '../elements/CreatedAt';
import DefaultProfileImage from 'assets/images/UserProfile/defaultProfileImage.png';
import Username from '../elements/Username';
import AuthContext from '../../state/AuthContext';
import Stack from '@mui/material/Stack';
import EvidenceToolbar from './EvidenceToolbar';
import SignInForm from 'components/SignIn/SignInForm';
import { useGetComments } from '../../services/comments';
import CommentList from '../Comments/CommentList';
import flattenPages from '../../util/flattenPages';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMe } from '../../services/users';
import Edited from '../Edited';

const useStyles = makeStyles({
  root: {
    background: '#FBFBFB',
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderRadius: 8,
    boxShadow: 'none !important',
    '&.MuiAccordion-root.Mui-expanded': {
      marginBottom: 32,
    },
    '& .MuiAccordionSummary-content': {
      '& div': {
        cursor: 'default',
      },
      '& .avatar-image': {
        width: 50,
        height: 50,
        borderRadius: 4,
        objectFit: 'cover',
      },
      '& .avatar-image.mobile': {
        width: 30,
        height: 30,
        borderRadius: 100,
      },
      '& .title': {
        opacity: 0.9,
        marginTop: 20,
        cursor: 'pointer',
        fontWeight: 600,
      },
      '& .title.mobile': {
        marginTop: 0,
      },
      '& .title.title-unread ': {
        color: colors.dark.focus,
      },
      '& .title.title-read ': {
        color: colors.dark.main,
      },
      '& .username': {
        '&.fontSize-10': {
          fontSize: '10px !important',
          '& a': {
            fontSize: '10px !important',
          },
        },
        '&.fontSize-16': {
          fontSize: '16px !important',
          '& a': {
            fontSize: '16px !important',
          },
        },
        padding: 10,
        borderRadius: 8,
        fontWeight: 400,
        color: colors.secondary.text,
        '& .MuiLink-root': {
          color: colors.secondary.text,
        },
        opacity: 0.9,
      },
      '& .date': {
        marginLeft: 20,
        '& svg': {
          width: 13,
          color: colors.text.unSelected,
          alignSelf: 'center',
          marginRight: '3px',
        },
        '& span': {
          fontWeight: 400,
          color: colors.text.unSelected,
          alignSelf: 'center',
        },
      },
      '& .body': {
        marginTop: 10,
        minHeight: 45,
        color: colors.text.unSelected + ' !important',
        opacity: 0.9,
      },
      '& .stats': {
        marginTop: '20px',
        padding: 5,
        '& img,& svg': {
          width: 17,
          color: colors.text.unSelected,
          alignSelf: 'center',
          marginRight: '5px',
        },
        '& span': {
          fontSize: 16,
          fontWeight: 400,
          color: colors.text.unSelected,
          alignSelf: 'center',
        },
      },
    },
  },
});

const EvidenceNode = ({
  startExpanded,
  theory,
  evidence,
  parentEvidence,
  onExpand,
  level,
  collapseEvents,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(startExpanded || false);
  const [collapsible, setCollapsible] = useState(true);
  const [suggestedRebuttalTitles, setSuggestedRebuttalTitles] = useState([]);
  const [promptForRebuttalDeletes, setPromptForRebuttalDeletes] = useState(false);
  const rankEvidence = useRankEvidence();
  const markEvidenceRead = useMarkEvidenceRead();
  const unpublishEvidence = useUnpublishEvidence();
  const [editing, setEditing] = useState(false);
  const [rebutting, setRebutting] = useState(false);
  const [searchParams] = useSearchParams();
  const [showComments, setShowComments] = useState(!!searchParams.get('showComment'));
  const { toastSuccess, toastError, isXs, copy, paste, clipboardType } = useContext(UiContext);
  const { isSignedIn } = useContext(AuthContext);
  const [nodeCollapseEvents, setNodeCollapseEvents] = useState(0);
  const [pastedEvidence, setPastedEvidence] = useState();
  const hasChildren = useHasChildren({ theoryId: theory?.id, parentId: evidence?.id });
  const navigate = useNavigate();

  const commentsQuery = useGetComments(
    { evidenceId: evidence?.id },
    { enabled: evidence && expanded },
  );
  const comments = flattenPages(commentsQuery.data?.pages);
  const me = useMe().data?.data;

  const clampBody = !evidence.read && me?.trustLevel >= 1 && !isSignedIn;

  const hasAccordionDetails = rebutting || hasChildren || showComments;

  const loaded = useRef(false); // is this already loaded into the DOM?
  useEffect(() => {
    if (expanded && loaded.current && !startExpanded) toggleAccordion(); // we got a 'collapse' event
    loaded.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapseEvents]);

  function onExpandChild(tree) {
    const hasEditor = node =>
      node.editor?.level || Object.keys(node).some(key => hasEditor(node[key]));

    setCollapsible(!hasEditor(tree));

    onExpand && onExpand({ [evidence.slug]: { ...tree, level: expanded ? level : 0 } });
  }

  function toggleAccordion() {
    if (expanded && !collapsible) return;

    if (expanded) setNodeCollapseEvents(old => old + 1);

    onExpand && onExpand({ [evidence.slug]: { level: expanded ? 0 : level } });

    setExpanded(!expanded);
  }

  useEffect(() => {
    if (expanded && !clampBody && !evidence.read && !markEvidenceRead.isLoading)
      markEvidenceRead.mutate({ evidence });
  }, [expanded, clampBody, isSignedIn, evidence, markEvidenceRead]);

  function onClickRebuttal() {
    setRebutting(true);
    setSuggestedRebuttalTitles([]);
  }

  function onCancelRebuttalEdit() {
    setRebutting(false);
    setSuggestedRebuttalTitles([]);
  }

  function onClickComments(e) {
    e.stopPropagation();
    setShowComments(!showComments);
    setRebutting(false);
  }

  function onClickEdit(e) {
    e.stopPropagation();
    setEditing(true);
  }

  function onEditingSuccess() {
    setEditing(false);
    setPromptForRebuttalDeletes(true);
  }

  function onCancelEdit() {
    setEditing(false);
  }

  function onConfirmUnpublish(reason) {
    unpublishEvidence.mutate(
      { ...evidence, archiveReason: reason },
      { onSuccess: res => toastSuccess(res.message) },
      { onError: res => toastError(res.message) },
    );
  }

  function onClickCopy() {
    copy({ title: evidence.title, body: evidence.body }, 'evidence');
    toastSuccess('Ready to paste');
  }

  function onClickPaste(e) {
    e.stopPropagation();
    if (clipboardType === 'evidence') {
      setRebutting(true);
      setPastedEvidence(paste());
    }
  }

  if (editing)
    return (
      <EvidenceEditor
        initialTitle={evidence.title}
        initialBody={evidence.body}
        isFor={evidence.for}
        updatingEvidence={evidence}
        parentEvidence={parentEvidence}
        level={level}
        theory={theory}
        onExpand={onExpandChild}
        collapseEvents={collapseEvents}
        onSuccess={onEditingSuccess}
        onCancel={onCancelEdit}
      />
    );

  const answer =
    (evidence.theoryLikelihood === 10 && '"No"') ||
    (evidence.theoryLikelihood === 30 && '"Unlikely"') ||
    (evidence.theoryLikelihood === 50 && '"Maybe"') ||
    (evidence.theoryLikelihood === 70 && '"Likely"') ||
    (evidence.theoryLikelihood === 90 && '"Yes"');

  return (
    <Accordion
      className={classes.root}
      expanded={expanded}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        sx={{
          px: 1,
          backgroundColor: evidence.for ? colors.evidence.for : colors.evidence.against,
          paddingBottom: expanded && '40px',
        }}
      >
        <Box className='w-full' spacing={1} justifyContent='space-between'>
          <Box display='flex'>
            <Stack spacing={1} alignItems='center' className='flex-shrink-0'>
              <img
                src={evidence.user?.profileImage?.thumbnail?.url || DefaultProfileImage}
                className={'avatar-image ' + (isXs || level > 1 ? 'mobile' : '')}
                alt='User Avatar'
              />
              {(!isXs || expanded) && (
                <RankPanel
                  disabled={!evidence.read}
                  direction='vertical'
                  expanded={expanded}
                  subject={evidence}
                  subjectKey='evidence'
                  mutator={rankEvidence}
                />
              )}
            </Stack>

            <Stack justifyContent='space-between' width='100%' marginLeft={2}>
              <Stack>
                <Status evidence={evidence} />
                <Stack direction='row' justifyContent='space-between'>
                  <Stack direction='row' justifyContent='space-between' className='w-full'>
                    {(expanded || !isXs) && (
                      <Box className={`${level > 1 ? 'fontSize-10' : 'fontSize-16'} username`}>
                        <Username username={evidence.user?.username} tooltip={answer} />
                      </Box>
                    )}
                    {!isXs && (
                      <Box display='flex' className='date' fontSize={level > 1 ? 10 : 16}>
                        <AccessTimeOutlinedIcon />
                        <CreatedAt createdAt={evidence.createdAt} />
                      </Box>
                    )}
                  </Stack>
                  {(expanded || !isXs) && (
                    <IconButton onClick={toggleAccordion}>
                      {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                  )}
                </Stack>
                <MKTypography
                  variant='h5'
                  className={
                    'title' +
                    (evidence.read ? ' title-read' : ' title-unread') +
                    (isXs ? ' mobile' : '')
                  }
                  onClick={toggleAccordion}
                  sx={{ fontSize: isXs ? 14 : level > 1 ? 16 : 20 }}
                >
                  {evidence.title}
                </MKTypography>
                {expanded && (
                  <Box className='body'>
                    <Body clamped={clampBody} stayClamped={clampBody} body={evidence.body} />
                    {clampBody && (
                      <SignInForm
                        newVisitor
                        compact
                        sx={{ opacity: 0.7, mr: isXs ? 0 : '24px', mt: '16px' }}
                        prompt='Please enter your email to read the rest'
                      />
                    )}

                    <Edited
                      subject={evidence}
                      onClick={() =>
                        navigate(
                          (evidence.parent ? '/rebuttal/' : '/evidence/') +
                            evidence.slug +
                            '?showRevisionHistory=true',
                        )
                      }
                    />
                  </Box>
                )}
              </Stack>

              {!expanded && !isXs && (
                <Box className='stats'>
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <Tooltip title='Rebuttals'>
                        <Stack direction='row' justifyContent='center'>
                          <img src={ChatIcon} alt='Rebuttal Icon' />
                          <span>{evidence?.rebuttalCount}</span>
                        </Stack>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                      <Tooltip title='Promotions'>
                        <Stack direction='row' justifyContent='center'>
                          <img src={ThumbUpIcon} alt='Promote Icon' />
                          <span>{evidence?.promotes}</span>
                        </Stack>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                      <Tooltip title='Demotions'>
                        <Stack direction='row' justifyContent='center'>
                          <img src={ThumbDownIcon} alt='Demote Icon' />
                          <span>{evidence?.demotes}</span>
                        </Stack>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                      <Tooltip title='Sways'>
                        <Stack direction='row' justifyContent='center'>
                          <PsychologyIcon />
                          <span>{evidence?.swayCount || 0}</span>
                        </Stack>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Stack>
          </Box>

          {expanded && (
            <EvidenceToolbar
              evidence={evidence}
              comments={comments}
              level={level}
              rebutting={rebutting}
              onClickEdit={onClickEdit}
              onClickCopy={onClickCopy}
              onClickPaste={onClickPaste}
              onClickRebuttal={onClickRebuttal}
              onClickComments={onClickComments}
              onConfirmUnpublish={onConfirmUnpublish}
            />
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: hasAccordionDetails ? '40px 20px 20px 40px' : '0px',
        }}
      >
        <Box spacing={2}>
          <Box>
            {rebutting && (
              <EvidenceEditor
                suggestedTitles={suggestedRebuttalTitles}
                parentEvidence={evidence}
                initialTitle={pastedEvidence?.title}
                initialBody={pastedEvidence?.body}
                isFor={!evidence.for}
                theory={theory}
                level={level + 1}
                onExpand={onExpandChild}
                collapseEvents={collapseEvents}
                onSuccess={() => setRebutting(false)}
                onCancel={onCancelRebuttalEdit}
              />
            )}
          </Box>

          {promptForRebuttalDeletes && hasChildren && (
            <MKTypography variant='h4' color='info'>
              Please <img src={TrashIcon} alt='Trash Icon' /> any rebuttals addressed
            </MKTypography>
          )}
          {hasChildren && !showComments && (
            <Evidences
              parentEvidence={evidence}
              theory={theory}
              onExpand={onExpandChild}
              level={level + 1}
              collapseEvents={collapseEvents + nodeCollapseEvents}
            />
          )}

          {showComments && <CommentList subject={evidence} idField='evidenceId' />}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default EvidenceNode;
