import { useContext, useEffect } from 'react';
import PushNotificationContext from 'contexts/PushNotificationContext';
import AuthContext from '../state/AuthContext';

const usePushNotifications = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const { init, subscribe, unsubscribe } = useContext(PushNotificationContext);

  useEffect(() => {
    if (isAuthenticated) {
      init();
    }
  }, [isAuthenticated, init]);

  return {
    subscribe,
    unsubscribe,
  };
};

export default usePushNotifications;
