import DOMPurify from 'dompurify';
import { marked } from 'marked';
import Box from '@mui/material/Box';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const renderer = {
  link: (href, title, text) =>
    `<a href="${href}" ${
      href[0] === '/' || ~href.indexOf('evincer.org') ? '' : 'target="_blank"'
    } >${text}</a>`,
};
marked.use({ renderer });

const Body = ({ body, clamped, stayClamped, onExpand }) => {
  const [expanded, setExpanded] = useState(!clamped);

  if (!body) return null;
  return (
    <Stack>
      <Box
        className={'markup-body break-word ' + (expanded ? '' : 'clamped')}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(marked.parse(body), { ADD_ATTR: ['target'] }),
        }}
      />
      {!expanded && !stayClamped && (
        <Button
          onClick={() => {
            onExpand && onExpand();
            setExpanded(true);
          }}
        >
          show
        </Button>
      )}
    </Stack>
  );
};

export default Body;
