import { fetchClient } from 'services/api';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { staleTimes } from 'services/query-settings';

const apiClient = fetchClient();

export const readNotification = async id => {
  const url = `push-notification${id ? '/' + id : ''}/read`;
  const res = await apiClient.patch(url);

  return res.data;
};

const getNotifications = async ({ pageParam }) => {
  const res = await apiClient.get('push-notification?page=' + (pageParam || 1));
  return res.data;
};

export const useNotifications = ({ enabled }) =>
  useInfiniteQuery('notifications', ({ pageParam }) => getNotifications({ pageParam }), {
    getNextPageParam: lastPage => (lastPage.meta.nextPage ? lastPage.meta.page + 1 : null),
    staleTime: staleTimes.blip,
    enabled,
  });

export const useInvalidateNotifications = () => {
  const queryClient = useQueryClient();

  return () => {
    // clear all but 1st page (avoids dot flickering)
    queryClient.setQueryData('notifications', oldNotifications => ({
      ...oldNotifications,
      pages: oldNotifications?.pages
        ? [oldNotifications.pages[0]]
        : [{ meta: { page: 1, nextPage: false }, data: [] }],
    }));
    // invalidate to force refetch
    queryClient.invalidateQueries({ queryKey: 'notifications' });
  };
};

export const parseNotification = notification => {
  const commentSubject = notification.evidence || notification.proposedTheory;

  const commentRoute =
    (notification.evidence
      ? notification.evidence.parent
        ? '/rebuttal/'
        : '/evidence/'
      : '/proposal/') +
    commentSubject?.['slug'] +
    '?showComment=' +
    notification.comment?.id;

  switch (notification.type) {
    case 'theory':
      return (
        notification.theory && {
          route: `/debate/${notification.theory.slug}`,
          text: `**${notification.theory.title.trim()}** was published`,
          ...notification,
        }
      );

    case 'evidence':
      return (
        notification.evidence && {
          route:
            (notification.evidence.parent ? '/rebuttal/' : '/evidence/') +
            notification.evidence.slug,
          text: `New ${
            notification.evidence.parent ? 'rebuttal' : 'evidence'
          } **${notification.evidence.title.trim()}** submitted ${
            notification.evidence.for ? 'for' : 'against'
          } **${notification.theory.title.trim()}**`,
          ...notification,
        }
      );

    case 'comment':
      return (
        commentSubject && {
          route: commentRoute,
          text: `New comment *${
            notification.comment.title
          }* on **${commentSubject?.title.trim()}** `,
          ...notification,
        }
      );

    case 'comment-respond':
      return (
        commentSubject && {
          route: commentRoute,
          text: `New response to *${
            notification.comment.title
          }* on **${commentSubject?.title.trim()}**`,
          ...notification,
        }
      );

    case 'comment-resolve':
      return (
        commentSubject && {
          route: commentRoute,
          text: `Comment resolved: *${
            notification.comment.title
          }* on **${commentSubject?.title.trim()}**`,
          ...notification,
        }
      );

    case 'evidence-rank':
      return (
        notification.evidence && {
          route: (notification.parent ? '/rebuttal/' : '/evidence/') + notification.evidence.slug,
          text:
            `**${notification.evidence.title.trim()}** was promoted ` +
            (notification.evidence.promotes > 2 ? `${notification.evidence.promotes} times` : ''),
          ...notification,
        }
      );

    case 'proposed-theory-rank':
      return (
        notification.proposedTheory && {
          route: `/proposal/${notification.proposedTheory.slug}`,
          text:
            `**${notification.proposedTheory.title.trim()}** was promoted ` +
            (notification.proposedTheory.promotes > 2
              ? `${notification.proposedTheory.promotes} times`
              : ''),
          ...notification,
        }
      );

    case 'trust-level':
      return (
        notification.actionUser && {
          route: '/trust-levels/',
          text: `You've reached **Trust Level ${notification.actionUser.trustLevel}!**`,
          ...notification,
        }
      );

    case 'trust-adjust':
      return (
        notification.data && {
          route: '/trust-levels',
          text:
            notification.data > 0
              ? 'An admin granted you **' + notification.data + ' Trust**'
              : 'An admin adjusted your Trust by ' + notification.data,
          ...notification,
        }
      );

    case 'proposed-theory-edit':
      const proposedTheory = notification.proposedTheory;
      return (
        proposedTheory && {
          route: `/proposal/${proposedTheory?.slug}`,
          text: `Proposal **${proposedTheory?.title.trim()}** was edited`,
          ...notification,
        }
      );

    case 'sway':
      const evidence = notification.evidence;
      return (
        evidence && {
          route: (notification.parent ? '/rebuttal/' : '/evidence/') + evidence.slug,
          text: `**${evidence.title.trim()}** earned a sway!`,
          ...notification,
        }
      );

    default:
      break;
  }
  return null;
};
