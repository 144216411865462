import { useContext } from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import TheoryCard from '../components/Cards/TheoryCard';
import MKTypography from '../mkcomponents/MKTypography';

import { useTagSubscription } from 'services/tag-subscription';
import { useTheories } from 'services/theories';
import { useTags } from 'services/tags';
import AuthContext from 'state/AuthContext';

import SubscriptionButton from 'components/SubscriptionButton';
import Breadcrumbs from '../components/Breadcrumbs';
import SearchForm from '../components/Search/SearchForm';
import colors from 'assets/theme/base/colors';
import flattenPages from '../util/flattenPages';
import HeadTags from '../components/HeadTags';

const Theories = () => {
  const params = useParams();
  const { isSignedIn } = useContext(AuthContext);

  const tagSlug = params.slug;
  const enableSubscription = !!(tagSlug && isSignedIn);
  // const theoriesHook = useTheories({ tagSlug });
  const { isLoading, isFetchingNextPage, data, fetchNextPage, hasNextPage } = useTheories(tagSlug);
  const tagSubscriptionHook = useTagSubscription({
    tagIdOrSlug: tagSlug,
    enabled: enableSubscription,
  });
  const tags = useTags().data?.data;
  const tagSubscription = tagSubscriptionHook.data?.data;
  const tagTitle =
    tags?.reduce((title, tag) => title || (tag.slug === tagSlug && tag.title), null) || tagSlug;
  const headline = tagTitle ? _.capitalize(tagTitle) + ' Debates' : 'All Debates';

  const breadcrumbItems = [
    {
      link: '/',
      text: 'Home',
    },
    {
      link: '/debates',
      text: 'Debates',
    },
  ];

  if (tagTitle) breadcrumbItems.push({ text: '#' + tagTitle });

  const theories = isLoading ? [null, null, null, null, null, null] : flattenPages(data.pages);

  return (
    <div>
      <HeadTags title='Debates' />

      <Stack spacing={2}>
        <SearchForm />

        <Breadcrumbs items={breadcrumbItems} />

        <Box
          fontSize={30}
          fontWeight={600}
          lineHeight={'48.41px'}
          color={colors.text.regular}
          sx={{ opacity: 0.9 }}
        >
          {headline}
        </Box>

        <Grid container spacing={1}>
          {theories.map((theory, i) => (
            <Grid item xs={12} md={6} lg={4} xxl={3} key={i}>
              <TheoryCard theory={theory} user={theory?.user} date={theory?.createdAt} />
            </Grid>
          ))}
        </Grid>
        {!isLoading && !theories?.length && (
          <Stack direction='row' justifyContent='space-between'>
            <MKTypography variant='h3'>There are no debates here yet.</MKTypography>
            <Button component={RouterLink} to={'/proposals/new'}>
              Why not propose one?
            </Button>
          </Stack>
        )}
      </Stack>
      {hasNextPage && (
        <Box textAlign='center' marginBottom={4}>
          <Button onClick={() => fetchNextPage()} variant='contained' margin='auto'>
            {isFetchingNextPage ? 'Loading more...' : 'Load More'}
          </Button>
        </Box>
      )}
      {enableSubscription && tagSubscriptionHook.status === 'success' && (
        <SubscriptionButton
          subscription={tagSubscription}
          dataId={tagSlug}
          dataKey='tag'
          path='/tag-subscription'
          tooltip={'Get notified when a new #' + tagSlug + ' debate is published'}
        />
      )}
    </div>
  );
};

export default Theories;
