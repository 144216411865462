import SlideDownModal from './wrappers/SlideDownModal';
import Divider from '@mui/material/Divider';
import MKBox from '../mkcomponents/MKBox';
import MKInput from '../mkcomponents/MKInput';
import Stack from '@mui/material/Stack';
import MKButton from '../mkcomponents/MKButton';
import { useContext, useState } from 'react';
import UiContext from '../state/UiContext';
import { useUpdateMe } from '../services/users';
import { validateUsername } from '../services/validators';
import ValidationError from './ValidationError';

const UsernameModal = ({ open, title, onClose, onSuccess }) => {
  const { toastSuccess, toastError } = useContext(UiContext);
  const [username, setUsername] = useState('');
  const [validationError, setValidationError] = useState('');
  const updateMe = useUpdateMe();

  function onSubmit() {
    if (validationError) {
      toastError(validationError);
      return;
    }

    updateMe.mutate(
      { username: username },
      {
        onSuccess: res => {
          toastSuccess(res.message);
          onSuccess && onSuccess(res.data);
        },
        onError: res => {
          toastError(res.message);
        },
      },
    );
  }

  function onChange(e) {
    const name = e.target.value;
    setValidationError(validateUsername(name));

    setUsername(name);
  }

  return (
    <SlideDownModal open={open} title={title} onClose={onClose}>
      <Divider sx={{ my: 0 }} />

      <MKBox p={2}>
        <MKInput
          variant='standard'
          label='Username'
          fullWidth
          value={username}
          onKeyUp={e => e.key === 'Enter' && onSubmit()}
          onChange={onChange}
        />

        <ValidationError error={validationError} />
      </MKBox>

      <Divider sx={{ my: 0 }} />

      <Stack direction='row' m={2} justifyContent='center' px={10}>
        <MKButton variant='gradient' color='info' onClick={onSubmit}>
          Submit
        </MKButton>
      </Stack>
    </SlideDownModal>
  );
};

export default UsernameModal;
