const CommentBodyTextarea = ({ placeholder, body, expanded, onChange, onClick }) => {
  return (
    <textarea
      value={body}
      rows={expanded ? 3 : 1}
      className={'p-1 w-full'}
      placeholder={placeholder}
      onChange={e => {
        onChange(e.target.value);
      }}
      onClick={onClick}
    />
  );
};

export default CommentBodyTextarea;
