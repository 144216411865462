import UnpublishedIcon from '@mui/icons-material/Unpublished';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import NotesIcon from '@mui/icons-material/Notes';
import ShareIcon from '@mui/icons-material/Share';
import { EditIcon, PaperPlusIcon, TrashIcon } from '../icons';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { useIsTrusted } from '../../services/config';
import { sameId } from '../../util/id';
import { useContext, useState } from 'react';
import AuthContext from '../../state/AuthContext';
import UiContext from '../../state/UiContext';
import Confirmation, { ConfirmationWithInput } from '../Confirmation';
import { useDeleteEvidence } from '../../services/evidence';
import styles from './EvidenceToolbar.module.scss';

const UnpublishButton = ({ onConfirm, evidence, children }) => {
  const { isAdmin } = useContext(AuthContext);
  const [openDlg, setOpenDlg] = useState(false);

  const canUnpublish = isAdmin || !evidence.rebuttalCount;

  function onClick(e) {
    e.stopPropagation();
    if (canUnpublish) setOpenDlg(true);
  }

  function onClose(reason) {
    setOpenDlg(false);
    if (reason) onConfirm(reason);
  }

  return (
    <>
      <ConfirmationWithInput
        prompt={'Unpublish Evidence "' + evidence.title + '?"'}
        open={openDlg}
        onClose={onClose}
        label='Reason'
        inputRequired
      />

      <Tooltip title={canUnpublish ? 'Unpublish' : 'Rebuttals prevent unpublishing'}>
        <IconButton onClick={onClick}>{children}</IconButton>
      </Tooltip>
    </>
  );
};

const DeleteButton = ({ evidence }) => {
  const [openDlg, setOpenDlg] = useState(false);
  const { toastError } = useContext(UiContext);
  const deleteEvidence = useDeleteEvidence();

  function onClick(e) {
    e.stopPropagation();
    setOpenDlg(true);
  }

  function onClose(response) {
    setOpenDlg(false);
    if (response)
      deleteEvidence.mutate(evidence, {
        onError: res => toastError(res.message),
      });
  }

  return (
    <>
      <Confirmation
        prompt={'Delete Evidence "' + evidence.title + '?"'}
        open={openDlg}
        onClose={onClose}
      />

      <Tooltip title='Delete'>
        <IconButton onClick={onClick}>
          <img src={TrashIcon} alt='Trash Icon' />
        </IconButton>
      </Tooltip>
    </>
  );
};

const EvidenceToolbar = ({
  evidence,
  comments,
  rebutting,
  level,
  onClickEdit,
  onConfirmUnpublish,
  onClickCopy,
  onClickPaste,
  onClickRebuttal,
  onClickComments,
}) => {
  const { me, isAdmin } = useContext(AuthContext);
  const { clipboardType, toastSuccess } = useContext(UiContext);
  const rebuttalAction = 'postEvidence' + (level === 1 ? 2 : 3);
  const [isTrustedToAddRebuttal, trustLevelRequiredToAddRebuttal] = useIsTrusted(rebuttalAction);
  const [isTrustedToComment] = useIsTrusted('evidenceComment');
  const [isTrustedToCopy] = useIsTrusted('copyPaste');
  const isAuthor = sameId(evidence?.user, me);
  const unresolvedComments = comments?.some(
    r => (isAuthor && !r.authorResolved) || (sameId(r.user, me) && !r.commenterResolved),
  );

  function onClickShare() {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(
        window.location.origin + (evidence.parent ? '/rebuttal/' : '/evidence/') + evidence.slug,
      );
      toastSuccess('URL copied to clipboard');
    }
  }

  return (
    <Box className={styles.root}>
      <span className={styles.toolbar}>
        {evidence.status !== 'archived' && (
          <Tooltip
            title={
              isTrustedToAddRebuttal
                ? 'Add Rebuttal'
                : 'Adding a rebuttal requires Trust Level ' + trustLevelRequiredToAddRebuttal
            }
          >
            <span>
              <IconButton
                onClick={onClickRebuttal}
                disabled={!isTrustedToAddRebuttal}
                className={evidence.for ? styles.rebuttalAgainst : styles.rebuttalFor}
              >
                <img
                  className={isTrustedToAddRebuttal ? '' : 'filter brightness-200'}
                  src={PaperPlusIcon}
                  alt='Plus Icon'
                />
              </IconButton>
            </span>
          </Tooltip>
        )}

        {isTrustedToComment && (
          <Tooltip title='View Comments'>
            <IconButton
              onClick={onClickComments}
              color={unresolvedComments ? 'secondary' : undefined}
            >
              <NotesIcon />
            </IconButton>
          </Tooltip>
        )}

        {evidence.status !== 'archived' && isAuthor && (
          <Tooltip title='Edit'>
            <IconButton onClick={onClickEdit}>
              <img src={EditIcon} alt='Edit Icon' />
            </IconButton>
          </Tooltip>
        )}

        {(isAdmin || isAuthor) && evidence.status === 'published' && (
          <UnpublishButton onConfirm={onConfirmUnpublish} evidence={evidence}>
            {isAdmin && !isAuthor ? <UnpublishedIcon /> : <img src={TrashIcon} alt='Trash Icon' />}
          </UnpublishButton>
        )}

        {isAuthor && ['editing', 'pending'].includes(evidence.status) && (
          <DeleteButton evidence={evidence} />
        )}

        {isAuthor && isTrustedToCopy && (
          <Tooltip title='Copy'>
            <IconButton onClick={onClickCopy}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title='Share'>
          <IconButton onClick={onClickShare}>
            <ShareIcon />
          </IconButton>
        </Tooltip>

        {clipboardType === 'evidence' && !rebutting && evidence.status !== 'archived' && (
          <Tooltip title='Paste'>
            <IconButton onClick={onClickPaste}>
              <ContentPasteIcon />
            </IconButton>
          </Tooltip>
        )}
      </span>
    </Box>
  );
};

export default EvidenceToolbar;
