import { createContext, useCallback, useState } from 'react';
import Pusher from 'pusher-js';

import config from 'config/pusher';
import api from 'config/api';
import { useJwt } from '../services/auth';

const PushNotificationContext = createContext({});

export const PushNotificationProvider = ({ children }) => {
  const [pusher, setPusher] = useState();
  const [jwt] = useJwt();

  const init = useCallback(() => {
    if (pusher) {
      return;
    }

    const auth = {
      endpoint: `${api.baseUrl}/auth/pusher`,
      headers: {
        authorization: `jwt ${jwt}`,
      },
    };

    const _pusher = new Pusher(config.key, {
      cluster: config.cluster,
      forceTLS: true,
      userAuthentication: auth,
      channelAuthorization: auth,
    });

    setPusher(_pusher);
  }, [jwt, pusher]);

  const subscribe = useCallback(
    name => {
      if (!pusher) {
        return;
      }

      return pusher.subscribe(name);
    },
    [pusher],
  );

  const unsubscribe = useCallback(name => pusher?.unsubscribe(name), [pusher]);

  return (
    <PushNotificationContext.Provider value={{ subscribe, unsubscribe, init }}>
      {children}
    </PushNotificationContext.Provider>
  );
};

export default PushNotificationContext;
