import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetchClient } from './api';

const apiClient = fetchClient();

export const getNewsletters = () => apiClient.get('/newsletter').then(res => res.data);

export const useNewsletters = () => useQuery('newsletters', getNewsletters);

const createNewsletter = async ({ body }) =>
  await apiClient.post('/newsletter', { body }).then(res => res.data);

export const useCreateNewsletter = function () {
  const queryClient = useQueryClient();
  return useMutation(createNewsletter, {
    onSuccess: () => queryClient.invalidateQueries('newsletters'),
  });
};

const updateNewsletter = async ({ body }) =>
  await apiClient.patch('/newsletter', { body }).then(res => res.data);

export const useUpdateNewsletter = function () {
  const queryClient = useQueryClient();
  return useMutation(updateNewsletter, {
    onSuccess: () => queryClient.invalidateQueries('newsletters'),
  });
};

const sendSummary = ({ next }) =>
  apiClient.post('/scheduled-notification/summary', { next }).then(res => res.data);

export const useSendSummary = () => useMutation(sendSummary);
