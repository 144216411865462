import React from 'react';
import Box from '@mui/material/Box';

import Conversations from 'components/Conversations';
import Heading from '../components/Banners/Heading';
import BannerBackground from 'assets/images/background2.png';
import Breadcrumbs from '../components/Breadcrumbs';
import HeadTags from '../components/HeadTags';

const Messages = () => {
  const breadcrumbItems = [
    {
      link: '/',
      text: 'Home',
    },
    {
      text: 'Messages',
    },
  ];
  return (
    <Box>
      <HeadTags title='Messages' />
      <Heading text='Messages' backgroundImg={BannerBackground} />
      <Breadcrumbs items={breadcrumbItems} />
      <Box marginTop={2} padding={2}>
        <Conversations />
      </Box>
    </Box>
  );
};

export default Messages;
