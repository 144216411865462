// @mui icons
import TwitterIcon from '@mui/icons-material/Twitter';

// Material Kit 2 PRO React examples
import CenteredFooter from './CenteredFooter';
import { Icon } from '@mui/material';

function Footer() {
  const routes = [
    { href: '/debates', name: 'Debates' },
    { href: '/proposals', name: 'Proposals' },
    { href: '/help', name: 'Help' },
  ];
  const socials = [
    // {
    //   icon: <LinkedInIcon fontSize="small" />,
    //   link: "",
    // },
    // {
    //   icon: <FacebookIcon fontSize="small" />,
    //   link: "",
    // },
    {
      icon: <TwitterIcon fontSize='small' />,
      link: 'https://twitter.com/evincer_org',
    },
    // {
    //   icon: <InstagramIcon fontSize="small" />,
    //   link: "",
    // },
    // {
    //   icon: <YouTubeIcon fontSize="small" />,
    //   link: "",
    // },
    {
      icon: <Icon>discord</Icon>,
      link: 'https://discord.gg/9kR9h4cmGV',
    },
  ];

  return <CenteredFooter routes={routes} socials={socials} />;
}

export default Footer;
